import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './Header.module.css'
import { BurgerIcon, CreateOrderIcon, DashBoard, Dealers, Logout, Notification, Order, ReciptsIcon, Search, WhatsAppHeader } from './icons/Icon'
import { Offcanvas } from 'react-bootstrap'
import { PackersIcon } from './icons/Icon'
import { InventoryIcon } from './icons/Icon'
import { ProductIcon } from './icons/Icon'
import { MarketingIcon } from './icons/Icon'
import { useAuth } from './context/Auth'

const PackingHeader = () => {
    const { designation, setDesignation, totalUnreadChat } = useAuth()
    const location = useLocation().pathname
    const [show, setShow] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            return setWidth(window.innerWidth);
        })
        window.addEventListener('resize', () => {
            return setWidth(window.innerWidth);
        })
    }, [])

    const navigate = useNavigate()
    const handleSignOut = () => {
        sessionStorage.clear()
        window.location.href = '/'
    }

    const dAarray = []

    var designations = sessionStorage.getItem("designation");
    designations.split(',').forEach(element => {
        dAarray.push({ designation: element })
    });

    var multipleManager = designations ? sessionStorage.getItem("designations").split(',') : [];


    const admin_menu = [
        {
            name: 'DASHBOARD',
            link: '/dashboard',
            icon: <DashBoard />
        },
        {
            name: 'DEALERS',
            link: '/dealers',
            icon: <Dealers />
        },
        {
            name: 'ORDERS',
            link: '/orders',
            icon: <Order />
        },
        {
            name: 'RECIPTS',
            link: '/recipts',
            icon: <ReciptsIcon />
        },
        {
            name: 'DISPATCH',
            link: '/dispatchdashboard',
            icon: <PackersIcon />
        },
        {
            name: 'PACKING ORDERS',
            link: '/packingorder',
            icon: <PackersIcon />
        },
        {
            name: 'INVENTORY',
            link: '/inventory',
            icon: <InventoryIcon />
        },
        {
            name: 'OUTSIDE INVENTORY',
            link: '/outsideinventory',
            icon: <InventoryIcon />
        },
        {
            name: 'PRODUCTS',
            link: '/products',
            icon: <ProductIcon />
        },
        {
            name: 'MARKETING',
            link: '/marketing',
            icon: <MarketingIcon />
        },
        {
            name: 'MANAGERS',
            link: '/managers',
            icon: <DashBoard />
        },
    ]

    const orderManager_menu = [
        {
            name: 'ORDERS',
            link: '/orders',
            icon: <Order />
        },
    ]

    const inventoryManager_menu = [
        {
            name: 'DASHBOARD',
            link: '/dashboard',
            icon: <DashBoard />
        },
        {
            name: 'INVENTORY',
            link: '/inventory',
            icon: <InventoryIcon />
        },
        {
            name: 'OUTSIDE INVENTORY',
            link: '/outsideinventory',
            icon: <InventoryIcon />
        },
        {
            name: 'ORDERS',
            link: '/orders',
            icon: <Order />
        },
        {
            name: 'PURCHASE ORDER',
            link: '/purchaseorders',
            icon: <Order />
        },
        {
            name: 'ADJUSTMENT',
            link: '/adjustmentorders',
            icon: <Order />
        },

    ]

    const dispatchManager_menu = [
        {
            name: 'DASHBOARD',
            link: '/dispatchdashboard',
            icon: <DashBoard />
        },
    ]

    const navigateToPage = (url) => {
        window.location.href = url;
    };

    return (
        <div className={styles.header_main_div}>
            <div className={styles.header_div}>
                <div className={styles.header_left_div}>
                    <img
                        src="/walliconblack192.png"
                        className={styles.wallicon_image}
                        alt="wallicon"
                        onClick={() => navigate('/dashboard')}
                    />
                </div>

                {/* <span className={styles.dashboard_search_div} onClick={() => navigate('/searchitem')}>
                    <Search color="#000" css={styles.dashboard_search_icon} />
                    <div className={styles.dashboard_search}>Search</div>
                </span> */}
                <div className={styles.header_item_whatsapp} onClick={() => navigate("/whatsappchats")}>
                    {totalUnreadChat !== 0 && <div className={styles.totalUnreadChat}>{totalUnreadChat}</div>}
                    <WhatsAppHeader color="#ffffff" />
                </div>
                <>
                    <div className={styles.header_item_txt} onClick={() => navigate('/searchitem')}>
                        <div className={styles.notifications_div}>
                            <Search color="#000" css={styles.dashboard_search_icon} />
                        </div>
                        <div className={styles.icon_name}>Search</div>
                    </div>
                </>

                {multipleManager && multipleManager.length > 1 &&
                    <select className={styles.multipleManager}
                        value={designation}
                        onChange={(e) => {
                            setDesignation(e.target.value);
                        }}>
                        {multipleManager && multipleManager.map((item, index) =>
                            <option key={index} value={item}>{item === 'dispatchmanager' ? 'DM' : item === 'ordermanager' ? 'OM' : item === 'inventorymanager' ? 'IM' : ''}</option>
                        )}
                    </select>
                }
                {/* 
                //    {dAarray.length > 1 && <div className={styles.multiDesignaton}>
                //         <select>
                //             {dAarray.map((item, index) =>
                //                 <option key={index} value={item}></option>
                //             )}
                //         </select>
                //     </div>
                } */}

                <div className={styles.header_right_div}>
                    {
                        ((designation === 'superadmin' || designation === 'ordermanager') && (location.includes('/orders') || location.includes('/dashboard') || location.includes('/purchaseorders') || location.includes('/searchitem'))) &&
                        <div className={styles.header_item_txt} onClick={() => {
                            location.includes('/orders') ? navigateToPage('/orders/saleorder') :
                                (location.includes('/searchitem') && (designation === 'superadmin' || designation === 'ordermanager')) ? navigateToPage('/orders/saleorder') :
                                    location.includes('/dashboard') ? navigateToPage('/orders/saleorder') :
                                        location.includes('/cartorders') ? navigateToPage('/orders/saleorder') :
                                            location.includes('/partnerorders') ? navigateToPage('/orders/saleorder') :
                                                location.includes('/purchaseorders') ? navigate("/orders/purchase") :
                                                    location.includes('/adjustmentorders') ? navigate("/orders/adjustment") :
                                                        location.includes('/estimateorders') ? navigate("/orders/estimate") :
                                                            location.includes('/transferorders') ? navigate("/orders/transfer") :
                                                                location.includes('/allorders') ? navigateToPage('/orders/saleorder') : <></>
                        }}>
                            <div className={styles.notifications_div}>
                                <label className={styles.notifications}></label>
                                <CreateOrderIcon />
                            </div>
                            <div className={styles.icon_name}>Order</div>
                        </div>
                    }
                    <div className={styles.header_item_txt}>
                        <div className={styles.notifications_div}>
                            <label className={styles.notifications}></label>
                            <Notification />
                        </div>
                        <div className={styles.icon_name}>Notification</div>
                    </div>
                    <div className={styles.header_item_txt} onClick={() => handleSignOut()}>
                        <Logout />
                        <div className={styles.icon_name}>Logout</div>
                    </div>
                    <div className={styles.header_item_txt} onClick={() => setShow(true)}>
                        <BurgerIcon />
                    </div>
                </div>
                <Offcanvas className={width < 500 ? 'w-75' : 'w-50'} show={show} onHide={() => setShow(false)}>
                    <Offcanvas.Header closeButton className='py-0 mb-3 border-bottom'>
                        <Offcanvas.Title>
                            <img
                                src="/walliconblack192.png"
                                className={styles.wallicon_image}
                                alt="wallicon"
                                onClick={() => navigate('/dashboard')}
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-0'>
                        <div className={styles.menu_items_main}>
                            {(designation === "superadmin" ? admin_menu : designation === "ordermanager" ? orderManager_menu : designation === "inventorymanager" ? inventoryManager_menu : dispatchManager_menu).map((item, index) => {
                                return (
                                    // designation === "superadmin" &&
                                    <div key={index} className={styles.menu_item}>
                                        <Link to={item.link} onClick={() => setShow(false)}>
                                            {item.icon}{item.name}
                                        </Link>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    )
}

export default PackingHeader