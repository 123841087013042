import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import styles from '../pages/InventoryMob.module.css'
import { EyeTwo } from '../../components/icons/Icon'
const OutSideIvtCollMob = () => {
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const params = useParams();
    const [searchparams] = useSearchParams()
    // const [pages, setPages] = useState("");
    // const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
    const product_id = searchparams.get("product_id")
    const product_name = params.product;

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_id": product_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

        fetch(process.env.REACT_APP_URL + "inventory/outsideCollections", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setData(result.data)
                    }
                    // if (result.pages) {
                    //     setPages(result.pages ? result.pages : "")
                    //   }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, product_id])

    return (
        <React.Fragment>
            <div className={styles.mob_flex}>
                <h1 className={styles.main_heading}>{product_name.toUpperCase()}</h1>
                {/* <div className={styles.text}>1/2</div> */}
            </div>
            {
                loading ? <div className={'loadingMain'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    data && data.map((item, index) =>
                        <div className={styles.box} key={index}  onClick={() => navigate('/outsideinventory/' + product_name.replace(/\s/g, "-").toLowerCase() + "/" + item.collection_name.replace(/\s/g, "-").toLowerCase() + "?product_id=" + product_id + "&collection_id=" + item._id)}>
                            <div className={styles.mob_flex}>
                                <h6 className={styles.product}>
                                    {item.collection_name}
                                </h6>
                                <div
                                    className={styles.status_div2}>
                                    <input type="radio" defaultChecked={item.status === "Active" ? true : false} className={styles.status_active} />
                                    &nbsp;
                                    <span className={styles.status}>Active</span>
                                </div>
                            </div>
                            <div className={styles.flex_coll}>
                                <div className={styles.productdiv}>
                                    <img src='/assets/noimage.jpg' alt='' className={styles.product_img} />
                                </div>
                                <div>
                                    <div className={styles.box_div}>
                                        <div className={styles.outSide_boxcoll}>Items</div>
                                    </div>
                                    <div className={styles.box_div}>
                                        <div className={styles.outSide_boxcoll}>Qty</div>
                                    </div>
                                </div>
                                <div>
                                    < EyeTwo />
                                </div>
                            </div>
                        </div>
                    )}
        </React.Fragment>
    )
}

export default OutSideIvtCollMob