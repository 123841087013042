import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { Edit, Search } from '../../components/icons/Icon';
import Modal from '../../components/Modal';
import styles from '../inventorymanager/Inventories.module.css'

const CollectionInfo = () => {

    const { managerId } = useAuth()
    const navigate = useNavigate();
    const params = useParams()
    const [searchparams] = useSearchParams()
    const product_id = searchparams.get("product_id")
    const collection_id = searchparams.get("collection_id")
    const collection_name = params.collection;
    const [inputSearch, setInputSearch] = useState('')
    const [items, setItems] = useState([])
    const [collectionInfoModal, setCollectionInfoModal] = useState(false)
    const [msgShow, setMsgShow] = useState("");
    const [loading, setLoading] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [totalItems, setTotalItems] = useState({})
    const [reload, setReload] = useState(false)
    const [collectionInfo, setCollectionInfo] = useState({
        warehouse: '',
        description: '',
        hscode: '',
        unit: '',
        odp: '',
        dp: '',
        dmrp: '',
        cu: '',
        cp: '',
        cmrp: '',
        mcp: '',
        vm: '',
        accessories: [],
    })

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            setItems([])
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_id": product_id,
                "collection_id": collection_id,
                "warehousename": collectionInfo.warehouse,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/collection-info", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        if (result.totalItems) {
                            setTotalItems(prevState => ({
                                ...prevState,
                                weight: result.totalItems.weight ? result.totalItems.weight : 0,
                                vm: result.totalItems.vm ? result.totalItems.vm : 0,
                                openingstock: result.totalItems.openingstock ? result.totalItems.openingstock : 0,
                                purchase: result.totalItems.purchase ? result.totalItems.purchase : 0,
                                sold: result.totalItems.sold ? result.totalItems.sold : 0,
                                adjust: result.totalItems.adjust ? result.totalItems.adjust : 0,
                                closing: result.totalItems.closing ? result.totalItems.closing : 0,
                            }))
                        }
                        if (result.warehouses) {
                            setWarehouses(result.warehouses)
                        }
                        if (result.collectionInfo) {
                            setCollectionInfo(prevState => ({
                                ...prevState,
                                description: result.collectionInfo.inventdesc ? result.collectionInfo.inventdesc : "",
                                hscode: result.collectionInfo.hscode ? result.collectionInfo.hscode : "",
                                unit: result.collectionInfo.unit ? result.collectionInfo.unit : "",
                                odp: result.collectionInfo.odp ? result.collectionInfo.odp : "",
                                dp: result.collectionInfo.dp ? result.collectionInfo.dp : "",
                                dmrp: result.collectionInfo.dmrp ? result.collectionInfo.dmrp : "",
                                cu: result.collectionInfo.cu ? result.collectionInfo.cu : "",
                                cp: result.collectionInfo.cp ? result.collectionInfo.cp : "",
                                cmrp: result.collectionInfo.cmrp ? result.collectionInfo.cmrp : "",
                                mcp: result.collectionInfo.mcp ? result.collectionInfo.mcp : "",
                                vm: result.collectionInfo.vm ? result.collectionInfo.vm : "",
                                accessories: result.collectionInfo.accessories ? result.collectionInfo.accessories : "",
                            }))
                        }
                        if (result.items) {
                            setItems(result.items)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, product_id, collection_id, collectionInfo.warehouse, reload])

    const handleSubmitCollectionInfo = () => {
        setReload(false)
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "collection_id": collection_id,
            "inventdesc": collectionInfo.description,
            "hscode": collectionInfo.hscode,
            "unit": collectionInfo.unit,
            "odp": collectionInfo.odp,
            "dp": collectionInfo.dp,
            "cu": collectionInfo.cu,
            "cp": collectionInfo.cp,
            "mcp": collectionInfo.mcp,
            // "vm": collectionInfo.vm,
            "dmrp": collectionInfo.dmrp,
            "cmrp": collectionInfo.cmrp,
            "accessories": collectionInfo.accessories,
            "warehousename": collectionInfo.warehouse,
            "itemsdetails": items
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/update-collection", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.message)
                    setCollectionInfoModal(true)
                    setReload(true)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>

            <Modal
                show={collectionInfoModal}
                close={setCollectionInfoModal}
                closeBtn={true}
                heading="COLLECTION STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setCollectionInfoModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => setCollectionInfoModal(false)} />
                        </div>
                    </div>
                }
            />

            <div className={styles.main_Container}>
                <span onClick={() => navigate(-1)}>
                    &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
                </span>
                <h1>{collection_name.toUpperCase()}</h1> {
                    loading ? <div className={'loadingMain'}>
                        <img src='/wallicon.gif' alt='walliconGIF' />
                    </div> :
                        <div className={styles.inventory_scroll_div}>
                            <div className={styles.collectionInfoCard}>
                                <GreenOutButton btnType="button" title="SAVE" css={styles.updateCollectionBtn} handleSubmit={() => { handleSubmitCollectionInfo() }} />
                                <h2 className={styles.collection_heading}>Collection Info</h2>
                                <div className={styles.collectionInfo_div}>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Warehouse</span>
                                        <select
                                            className={styles.selectDropdown}
                                            value={collectionInfo.warehouse}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, warehouse: e.target.value })}
                                        >
                                            <option value="">All Warehouse</option>
                                            {warehouses && warehouses.map((item, index) =>
                                                <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Description</span>
                                        <input
                                            type='text'
                                            className={styles.inputField}
                                            value={collectionInfo.description}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, description: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>HS Code</span>
                                        <input
                                            type='text'
                                            className={styles.inputField}
                                            value={collectionInfo.hscode}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, hscode: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Unit</span>
                                        <select
                                            className={styles.selectDropdown}
                                            value={collectionInfo.unit}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, unit: e.target.value })}
                                        >
                                            <option value="" hidden>Select</option>
                                            <option value="Roll">Roll</option>
                                            <option value="Pcs">Pcs</option>
                                            <option value="Box">Box</option>
                                            <option value="Sq Meter">Sq Meter</option>
                                            <option value="Meter">Meter</option>
                                            <option value="Sq Feet">Sq Feet</option>
                                            <option value="Feet">Feet</option>
                                            <option value="Kg">Kg</option>
                                        </select>
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Dealer MRP</span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.dmrp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, dmrp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Offline Price</span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.odp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, odp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Dealer Price</span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.dp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, dp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>

                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Cust Unit</span>
                                        <select
                                            className={styles.selectDropdown}
                                            value={collectionInfo.cu}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, cu: e.target.value })}
                                        >
                                            <option value="" hidden>Select</option>
                                            <option value="Roll">Roll</option>
                                            <option value="Pcs">Pcs</option>
                                            <option value="Box">Box</option>
                                            <option value="Sq Meter">Sq Meter</option>
                                            <option value="Meter">Meter</option>
                                            <option value="Sq Feet">Sq Feet</option>
                                            <option value="Feet">Feet</option>
                                            <option value="Kg">Kg</option>
                                        </select>
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Cust MRP</span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.cmrp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, cmrp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Cust Price </span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.cp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, cp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    <div className={styles.details_div}>
                                        <span className={styles.details_txt}>&#x3e;Cust Price</span>
                                        <input
                                            type='number'
                                            className={styles.inputField}
                                            value={collectionInfo.mcp}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, mcp: e.target.value })}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    {/* <div className={styles.details_div}>
                                        <span className={styles.details_txt}>Volume (CBM)</span>
                                        <input
                                            type='text'
                                            className={styles.inputField}
                                            value={collectionInfo.vm}
                                            onChange={(e) => setCollectionInfo({ ...collectionInfo, vm: e.target.value })}
                                        />
                                    </div> */}

                                    {
                                        collectionInfo.accessories && collectionInfo.accessories.map((item, index) =>
                                            <div key={index} className={styles.details_div}>
                                                <span className={styles.details_txt}>{item.accessoriesName}</span>
                                                <input
                                                    type='text'
                                                    className={styles.inputField}
                                                    value={item.accessoriesPrice}
                                                    onChange={(e) => setCollectionInfo({
                                                        ...collectionInfo, accessories: [...collectionInfo.accessories.map((obj1, i1) => {
                                                            if (i1 === index) {
                                                                obj1.accessoriesPrice = e.target.value;
                                                            }
                                                            return obj1;
                                                        })
                                                        ]
                                                    })
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                            <h2 className={styles.collection_heading}>Items in Collection</h2>
                            <div className={styles.ItemsCollection_div}>
                                <div className={styles.seacrh_div}>
                                    <label className={styles.search_Icon}>{inputSearch.length === 0 && <Search color="#FFFFFF" />}</label>
                                    <input
                                        type="search"
                                        placeholder='Search item'
                                        className={styles.seacrh_inputField}
                                        value={inputSearch}
                                        onChange={(e) => { setInputSearch(e.target.value) }}
                                    />
                                </div>
                                <div className={styles.editAll}>
                                    <span><Edit color="#FFFFFF" /></span>
                                    <span>Edit All</span>
                                </div>
                            </div>

                            <div className={styles.collectionCard} >
                                <div className={styles.totalItem_div}>
                                    Total <span className={styles.qty}>{items?.length ? items.length:''}&nbsp;Items</span>
                                </div>
                                <div className={styles.weight_div}>
                                    <div className={styles.view_div}>
                                        <span className={styles.qty}>Weight</span>
                                        <span className={styles.qty_txt}>KG</span>
                                    </div>
                                    <div className={styles.view_div}>
                                        <span className={styles.qty}>Volume</span>
                                        <span className={styles.qty_txt}>CBM</span>
                                    </div>
                                </div>
                                <div className={styles.view_div}>
                                    <span className={styles.qty}>{totalItems.openingstock}</span>
                                    <span className={styles.qty_txt}>Opening</span>
                                </div>
                                <div className={styles.view_div}>
                                    <span className={styles.qty}>{totalItems.purchase}</span>
                                    <span className={styles.qty_txt}>Purchase</span>
                                </div>
                                <div className={styles.view_div}>
                                    <span className={styles.qty}>{totalItems.sold}</span>
                                    <span className={styles.qty_txt}>Sold</span>
                                </div>
                                <div className={styles.view_div}>
                                    <span className={styles.qty}>{totalItems.adjust}</span>
                                    <span className={styles.qty_txt}>Adjust</span>
                                </div>
                                <div className={styles.view_div}>
                                    <span className={styles.qty}>{totalItems.closing}</span>
                                    <span className={styles.qty_txt}>Closing</span>
                                </div>
                            </div>


                            {
                                items && items.map((item, index) =>
                                    <div key={index} className={styles.collectionCard_div} >
                                        <div className={styles.totalItem_details_div}>
                                            <div className={styles.activeRadioBtn}>
                                                <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
                                                <span className={styles.status_active}>Active</span>
                                            </div>
                                            <h2 className={styles.itemName}>{item.itemNo}</h2>
                                            <div className={styles.outsourcedProduct}>
                                                <div>Outsourced Product</div>
                                                <div className={styles.outsourcedStatus}>
                                                    <input type="radio" disabled={item.outsourcedProduct === "Yes" ? false : true} defaultChecked={item.outsourcedProduct === "Yes" ? true : false} />Yes
                                                    <input type="radio" disabled={item.outsourcedProduct === "No" ? false : true} defaultChecked={item.outsourcedProduct === "No" ? true : false} />No
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.weight_div}>
                                            <div className={styles.details_div}>
                                                <span className={styles.details_txt}>Weight (in kg)</span>
                                                <input
                                                    type='number'
                                                    className={styles.inputField}
                                                    value={item.weight}
                                                    onChange={(e) => {
                                                        const list = [...items];
                                                        list[index]["weight"] = e.target.value;
                                                        setItems(list);
                                                    }}
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <div className={`${styles.details_div} ml-2`}>
                                                <span className={styles.details_txt}>Volume (CBM)</span>
                                                <input
                                                    type='number'
                                                    className={styles.inputField}
                                                    value={item.vm}
                                                    onChange={(e) => {
                                                        const list = [...items];
                                                        list[index]["vm"] = e.target.value;
                                                        setItems(list);
                                                    }}
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.details_div}>
                                            <span className={styles.details_txt}>Opening Stock</span>
                                            <input
                                                type='number'
                                                className={styles.inputField}
                                                value={item.openingstock}
                                                onChange={(e) => {
                                                    const list = [...items];
                                                    list[index]["openingstock"] = e.target.value;
                                                    setItems(list);
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </div>
                                        <div className={styles.details_div}>
                                            <span className={styles.details_txt}>Purchase</span>
                                            <input
                                                type='number'
                                                className={styles.inputField}
                                                value={item.purchase}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                        <div className={styles.details_div}>
                                            <span className={styles.details_txt}>Sold</span>
                                            <input
                                                type='number'
                                                className={styles.inputField}
                                                value={item.sold}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                        <div className={styles.details_div}>
                                            <span className={styles.details_txt}>Adjust</span>
                                            <input
                                                type='number'
                                                className={styles.inputField}
                                                value={item.adjust}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                        <div className={styles.details_div}>
                                            <span className={styles.details_txt}>Closing</span>
                                            <input
                                                type='number'
                                                className={styles.inputField}
                                                value={item.closing}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}
                        </div>
                }
            </div>
        </React.Fragment >
    )
}

export default CollectionInfo