import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap'

const VoucherEditDelete = () => {
    const [orderfilter, setOrderFilter] = useState(0);
    const [voucherfilter, setVoucherFilter] = useState(0);
    const [show, setShow] = useState(false);
    const [showPur, setShowPur] = useState(false);
    const [itemData, setItemData] = useState("")
    const [purData, setPurData] = useState("")

    const [editData, setEditData] = useState([]);
    const [deleteData, setDeleteData] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);

    const getContent = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/editDeleteOrders", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data) {
                    setEditData(result.data.editData);
                    setDeleteData(result.data.deleteData);
                }
            })
            .catch(error => console.log('error', error));
    }


    const requestApproval = (orderId, status) => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "orderId": orderId,
            "orderStatus": status,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/orderReqApproved", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShow(false);
                    alert(result.msg)
                    getContent();
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }
    const getPurchase = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "designation": sessionStorage.getItem("designation"),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/viewOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setPurchaseData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        getContent();
        getPurchase();
    }, [])

    const purchaseReqRevert = (orderId, status) => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "voucherNo": orderId,
            "status": status,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/reqRevert", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowPur(false);
                    getPurchase();
                    alert(result.msg);
                    window.location.reload();
                } else {
                    alert(result.msg)
                }

            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>
            <div className="w-100 bgSale bgHeight p-3 cp">
                <Row className="mx-0">
                    <Col md="4  pe-0" >
                        <div className="bg-white rounded-pill p-2 text-center w_fit">
                            <button className={`${voucherfilter === 0 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setVoucherFilter(0)}>Sale</button>
                            <button className={`${voucherfilter === 1 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setVoucherFilter(1)}>Purchase {purchaseData.length}</button>
                        </div>
                    </Col>
                    <Col md="4 text-center">
                        <div className="fs_30 pf">Delete &amp; Update</div>
                    </Col>
                    <Col md="4 tw_right pe-0" >
                        <div className="bg-white rounded-pill p-2 text-center w_fit">
                            <button className={`${orderfilter === 0 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setOrderFilter(0)}>Edited</button>
                            <button className={`${orderfilter === 1 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setOrderFilter(1)}>Deleted</button>
                        </div>
                    </Col>
                </Row>

                <Table striped hover variant="dark" className="gfg text-center mt-4" >
                    <thead >
                        <tr className="fs_20">
                            <th>#</th>
                            <th>Voucher No</th>
                            <th>Voucher Date</th>
                            <th>Voucher Type</th>
                            <th>Voucher Amount</th>
                            <th>Voucher Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {voucherfilter === 0 ?
                        <tbody>
                            {orderfilter === 0 ? editData && editData.map((item, index) => (
                                <tr key={index} onClick={() => { setShow(true); setItemData(item) }}>
                                    <td>{index + 1}</td>
                                    <td>{item.orderData.orderId}</td>
                                    <td>{item.orderData.orderDate}</td>
                                    <td>{item.cartType}</td>
                                    <td>{item.orderData.paybaleAmmout}</td>
                                    <td>{item.orderData.orderStatus}</td>
                                    <td>
                                        <button className="btn btn-sm btn-outline-success">View</button>
                                    </td>
                                </tr>
                            )) : deleteData && deleteData.map((item, index) => (
                                <tr key={index} onClick={() => { setShow(true); setItemData(item) }}>
                                    <td>{index + 1}</td>
                                    <td>{item.orderData.orderId}</td>
                                    <td>{item.orderData.orderDate}</td>
                                    <td>{item.cartType}</td>
                                    <td>{item.orderData.paybaleAmmout}</td>
                                    <td>{item.orderData.orderStatus}</td>
                                    <td>
                                        <button className="btn btn-sm btn-outline-success">View</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        :
                        <tbody>
                            {purchaseData && purchaseData.map((item, index) => (
                                <tr key={index} onClick={() => { setShowPur(true); setPurData(item) }}>
                                    <td>{index + 1}</td>
                                    <td>{item.voucherNo}</td>
                                    <td>{item.voucherDate}</td>
                                    <td>{item.itemsdetails}</td>
                                    <td>{item.totalAmount}</td>
                                    <td>{item.status}</td>
                                    <td>
                                        <button className="btn btn-sm btn-outline-success">View</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }

                </Table>

                <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Header closeButton className='bgSale border-dark'>
                        <Modal.Title>{itemData && itemData.orderData.orderStatus === "Edit" ? "Edit Request" : "Delete Request"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='cpm text-center bgSale'>
                        <Row className='mx-5 px-4 my-4 text-start'>
                            <Col xs="5"> Order Id </Col> <Col xs="1">:</Col>
                            <Col xs="6">{itemData && itemData.orderData.orderId}</Col>
                            <Col xs="5"> Date </Col><Col xs="1">:</Col>
                            <Col xs="6 pe-0">{itemData && itemData.orderData.orderDate}</Col>
                            <Col xs="5"> Amount </Col><Col xs="1">:</Col>
                            <Col xs="6">{itemData && itemData.orderData.paybaleAmmout}</Col>
                            <Col xs="5"> Type </Col><Col xs="1">:</Col>
                            <Col xs="6">{itemData && itemData.cartType}</Col>
                        </Row>
                        <Row className='mx-5 mt-4'>
                            <Col xs="4"><Button variant="outline-danger px-4" onClick={() => setShow(false)}>Cancel</Button></Col>
                            <Col xs="4"><Button variant="outline-warning px-4" onClick={() => requestApproval(itemData.orderData.orderId, "Declined")}>Decline</Button></Col>
                            <Col xs="4"><Button variant="outline-success px-3" onClick={() => requestApproval(itemData.orderData.orderId, itemData.orderData.orderStatus === "Edit" ? "Approved" : "Delete")}>Approve</Button></Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={showPur} onHide={() => setShowPur(false)} centered>
                    <Modal.Header closeButton className='bgSale border-dark'>
                        <Modal.Title>{purData && purData.status === "Edit" ? "Edit Request" : "Delete Request"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='cpm text-center bgSale'>
                        <Row className='mx-5  my-4 text-start'>
                            <Col xs="5"> Order Id </Col> <Col xs="1">:</Col>
                            <Col xs="6">{purData && purData.voucherNo}</Col>
                            <Col xs="5"> Date </Col><Col xs="1">:</Col>
                            <Col xs="6 pe-0">{purData && purData.voucherDate}</Col>
                            <Col xs="5"> Amount </Col><Col xs="1">:</Col>
                            <Col xs="6">{purData && purData.totalAmount}</Col>
                            <Col xs="5"> Items </Col><Col xs="1">:</Col>
                            <Col xs="6">{purData && purData.itemsdetails}</Col>
                        </Row>
                        <Row className='mx-5 mt-4'>
                            <Col xs="4"><Button variant="outline-danger px-4" onClick={() => setShow(false)}>Cancel</Button></Col>
                            <Col xs="4"><Button variant="outline-warning px-4" onClick={() => purchaseReqRevert(purData.voucherNo, "Declined")}>Decline</Button></Col>
                            <Col xs="4"><Button variant="outline-success px-3" onClick={() => purchaseReqRevert(purData.voucherNo, purData.status === "Edit" ? "Approved" : "Delete")}>Approve</Button></Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default VoucherEditDelete