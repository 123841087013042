import React, { useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import { GreenOutButton, RedOutButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import styles from './Marketing.module.css'


const LeadLostModal = ({ displayModal, closeModal, leadId, setSuccess }) => {
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();

    const [leadLostNote, setLeadLostNote] = useState("")
    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };
    function handleCloseModal(e) {
        e.stopPropagation()
        closeModal()
    }


    const handleSubmit = async (e) => {

        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "leadId": leadId,
            "managerId": managerId,
            "reason": leadLostNote,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(process.env.REACT_APP_URL + "marketing/leadLost", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    closeModal(false);
                    navigate("/marketing")
                    setSuccess(Math.random())
                }
            })
            .catch(error => console.log('error', error));
    }
    return (
        <React.Fragment>
            <div
                className={styles.custom_modal}
                onClick={handleCloseModal}
                style={divStyle}>
                <div className={`${switchDisplay ? styles.bg_white_gray_Light +" "+ styles.leadLostModal : styles.leadLostModal +" "+ styles.bg_dark} ${styles.center}`} onClick={e => e.stopPropagation()} >
                    <div className={styles.modal_body}>
                        <div className={`${styles.fs_24} ${styles.text_danger} ${styles.text_center}`}>Are You Sure Your want to Mark <br /> This Lead as Lost?</div>
                        <div className={`${styles.fs_20} ${styles.text_start} ${styles.mt_20} ${styles.ms_10}`}>Add Reason (mandotory)</div>
                        <form onSubmit={handleSubmit}>

                            <textarea
                                placeholder="Enter Reason..."
                                className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box} ${styles.h_65} ${styles.mt_5}`}
                                value={leadLostNote}
                                onChange={(e) => setLeadLostNote(e.target.value)}
                                required
                            />

                            <div className={styles.text_center +" "+ styles.m_20}>
                                <RedOutButton btnType="submit" title="Yes" css={styles.dangerBtn +" "+ styles.mouse +" "+ styles.br_30} />
                                <GreenOutButton btnType="button" title="No" css={styles.mouse +" "+ styles.text_success +" "+ styles.br_30 +" "+ styles.ms_15p} handleSubmit={handleCloseModal} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LeadLostModal