import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { DashBoard, Dealers, Order } from '../components/icons/Icon'
import styles from './Menus.module.css'

const OrderMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <React.Fragment>
            <div className={styles.menuItem_div} onClick={() => navigate("/dashboard")}>
                <DashBoard css={styles.iconImage} color={pathname === "/dashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dashboard" ? styles.active_leftMenu : ""}>DASHBOARD</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/orders")}>
                <Order css={styles.iconImage} color={pathname === "/orders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/orders" ? styles.active_leftMenu : ""}>ORDERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/orders/dealers")}>
                <Dealers css={styles.iconImage} color={pathname === "/orders/dealers" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/orders/dealers" ? styles.active_leftMenu : ""}>DEALERS</span>
            </div>
        </React.Fragment>
    )
}

export default OrderMenu