import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth'
import { CheckGreenIcon, DownloadIcon, ReloadIcon, Search, UpArrowIcon } from '../../components/icons/Icon'
import styles from "./Invoices.module.css"
import Paginantion from '../../components/Paginantion'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Invoices = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  const { managerId, designation } = useAuth();
  const [data, setData] = useState([]);
  const [daysTab, setDaysTab] = useState('today')
  //   const [shipingTab, setShipingTab] = useState('all')
  const [orderType, setOrderType] = useState('')
  const [cartTab, setCartTab] = useState('all')
  const [orders, setOrders] = useState([])
  const [keyword, setKeyword] = useState('')
  const [sortKey, setSortKey] = useState('')
  const [loading, setLoading] = useState(false)
  const [orderStatus, setOrderStatus] = useState('')
  //   const [warehouses, setWarehouses] = useState([])
  //   const [warehouseName, setWarehouseName] = useState('')
  // const [managers, setManagers] = useState([])
  // const [filterManagers, setFilterManagers] = useState([])
  const [ordermanagerId, setOrdermanagerId] = useState([])
  const [dispatchManagerId, setDispatchManagerId] = useState([])
  const [managerName, setManagerName] = useState('')
  const [dispatchManagerName, setDispatchManagerName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1
  });

  // useEffect(() => {
  //   setFilterManagers([...ordermanagerId, ...dispatchManagerId])

  // }, [ordermanagerId, dispatchManagerId])

  // useEffect(() => {
  //   // Create a Set to store unique IDs
  //   const uniqueIds = new Set();

  //   // Use filter to create a new array with only unique objects based on 'id'
  //   const uniqueData = filterManagers.filter(item => {
  //     if (!uniqueIds.has(item.managerId)) {
  //       uniqueIds.add(item.managerId);
  //       return true;
  //     }
  //     return false;
  //   });

  //   setManagers(uniqueData);
  // }, [filterManagers]);

  const { totalPages, } = state;

  useEffect(() => {
    if (managerId) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": parseInt(managerId),
        "keyword": keyword,
        "invoiceStatus": orderStatus,
        "sortKey": sortKey,
        // "orderType": shipingTab,
        "cartType": cartTab,
        "ordermanagerId": managerName,
        "dispatchManagerId": dispatchManagerName,
        "day": daysTab,
        "startDate": startDate,
        "endDate": endDate
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_URL}invoice/allInvoices?page=${page}&limit=8`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setLoading(false)
            setData(result.data)
            setOrders(result.orders)
            if (result.warehouses) {
              //   setWarehouses(result.warehouses)
            }
            if (result.managers) {
              setOrdermanagerId(result.managers.ordermanagers)
              setDispatchManagerId(result.managers.dispatchmanagers)
            }
          }
          if (result.pages) {
            setState(prevState => ({
              ...prevState,
              totalPages: result.pages ? result.pages : "",
            }))
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, sortKey, keyword, page, orderStatus, cartTab, managerName, daysTab, startDate, endDate, dispatchManagerName])

  return (
    <React.Fragment>

      <div className={styles.right_main}>
        <div className={styles.dispatchDashboard_header}>
          <h1 className={styles.dispatchHeading}>Invoices</h1>

          <div className={styles.celender_div}>

            <div className={styles.reload} onClick={() => window.location.reload()}>
              <span><ReloadIcon /></span>
              <span className={styles.reload_txt}>Reload</span>
            </div>

            <div className={styles.dashboard_header_select_week}>
              <div className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => setDaysTab('today')}>Today</div>
              <div className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => setDaysTab('yesterday')}>Yesterday</div>
              <div className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => setDaysTab('month')}>This Month</div>
            </div>

            <div className={styles.startEndDate_div}>
              <span>Start Date&nbsp;&nbsp;</span>
              <input
                className={styles.startEndDate}
                type="date"
                value={startDate}
                onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
              />
            </div>
            <div className={styles.startEndDate_div}>
              <span>End Date&nbsp;&nbsp;</span>
              <input
                className={styles.startEndDate}
                type="date"
                value={endDate}
                onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
              />
            </div>
          </div>

        </div>

        <div className={styles.dashboard_main_container}>
          <div className={styles.dashboard_main_div}>

            <div className={styles.Invices_statusCard_div}>
              {orders && orders.map((item, index) =>
                <div key={index} className={`${styles.statusCard} ${orderStatus === item.invoiceStatus && item.invoiceStatus === 'Reupload' ? styles.packed_active :
                  orderStatus === item.invoiceStatus && item.invoiceStatus === 'Uploaded' ? styles.Dispatches_active :
                    orderStatus === item.invoiceStatus && item.invoiceStatus === 'Pending' ? styles.inProgress_active :
                      orderStatus === item.invoiceStatus && item.invoiceStatus === 'All' ? styles.orange_active :
                        orderStatus === item.invoiceStatus && item.invoiceStatus === 'Cancelled' ? styles.cancel_status :
                          orderStatus === item.invoiceStatus && item.invoiceStatus === 'OrderReturn' ? styles.Pending_active :
                            ''}`}
                  // onClick={() => {setOrderStatus(item.status);}}
                  onClick={() => {
                    setOrderStatus(item.invoiceStatus);
                    if (searchParams.has("page")) {
                      const token = searchParams.get("page");
                      if (token) {
                        searchParams.delete("page");
                        setSearchParams(searchParams);
                      }
                    }
                  }}>
                  <span className={
                    item.invoiceStatus === 'OrderReturn' ? styles.statusRed :
                      // item.status === 'In Progress' ? styles.statusYellow :
                      item.invoiceStatus === 'Reupload' ? styles.statusReupload :
                        item.invoiceStatus === 'All' ? styles.statusAll :
                          item.invoiceStatus === 'Pending' ? styles.statusYellow :
                            item.invoiceStatus === 'Uploaded' ? styles.statusGreen :
                              item.invoiceStatus === 'Cancelled' ? styles.statusWhite :
                                ''}>
                    {item.count}
                  </span>
                  <span className={styles.statusTxt}>{item.invoiceStatus}</span>
                </div>
              )}
            </div>

            <div className={styles.dispatchDashboard_header}>
              <div className={styles.dashboard_search_div}>
                <input type="search"
                  placeholder='Search'
                  className={styles.dashboard_search}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <Search color="#ffffff" css={styles.dashboard_search_icon} />
              </div>

              <div className={styles.source_header_select_week}>
                <div className={`${styles.source_days_select} ${orderType === '' ? styles.days_active : ''}`} onClick={() => setOrderType('')}>All</div>
                <div className={`${styles.source_days_select} ${orderType === 'online' ? styles.days_active : ''}`}
                // onClick={() => {
                //     setOrderType('online');
                //     if (searchParams.has("page")) {
                //         const token = searchParams.get("page");
                //         if (token) {
                //             searchParams.delete("page");
                //             setSearchParams(searchParams);
                //         }
                //     }
                // }}
                >Online</div>
                <div className={`${styles.source_days_select} ${orderType === 'offline' ? styles.days_active : ''}`}
                //  onClick={() => {
                //     setOrderType('offline');
                //     if (searchParams.has("page")) {
                //         const token = searchParams.get("page");
                //         if (token) {
                //             searchParams.delete("page");
                //             setSearchParams(searchParams);
                //         }
                //     }
                // }}
                >Offline</div>
              </div>

              <div className={styles.dashboard_header_select_week}>
                <div className={`${styles.dashboard_days_select} ${cartTab === 'all' ? styles.days_active : ''}`} onClick={() => setCartTab('all')}>All</div>
                <div className={`${styles.dashboard_days_select} ${cartTab === 'Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Cart')}>Cart</div>
                <div className={`${styles.dashboard_days_select} ${cartTab === 'Partner Cart' ? styles.days_active : ''}`} onClick={() => setCartTab('Partner Cart')}>Partner Cart</div>
              </div>

              {/* {designation === 'superadmin' &&
                <div className={styles.dashboard_Sort_By}>
                  <label className={styles.dashboard_sortBy_txt}>Warehouse</label>
                  <select
                    className={styles.dashboard_sortBy_select}
                    value={warehouseName}
                    onChange={(e) => setWarehouseName(e.target.value)}
                  >
                    <option value='All'>View All</option>
                    {
                      warehouses && warehouses.map((item, index) =>
                        <option key={index} value={item.warehousename}>{item.warehousename}</option>
                      )
                    }
                  </select>
                </div>
              } */}


              {designation === 'superadmin' &&
                <div className={styles.dashboard_Sort_By}>
                  <label className={styles.dashboard_sortBy_txt}>OM</label>
                  <select
                    className={styles.dashboard_sortBy_select}
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                  >
                    <option value='All'>View All</option>
                    {
                      ordermanagerId && ordermanagerId.map((item, index) =>
                        <option key={index} value={item.managerId}>{item.name}</option>
                      )
                    }
                  </select>
                </div>
              }

              {designation === 'superadmin' &&
                <div className={styles.dashboard_Sort_By}>
                  <label className={styles.dashboard_sortBy_txt}>DM</label>
                  <select
                    className={styles.dashboard_sortBy_select}
                    value={dispatchManagerName}
                    onChange={(e) => setDispatchManagerName(e.target.value)}
                  >
                    <option value='All'>View All</option>
                    {
                      dispatchManagerId && dispatchManagerId.map((item, index) =>
                        <option key={index} value={item.managerId}>{item.name}</option>
                      )
                    }
                  </select>
                </div>
              }


              <div className={styles.dashboard_Sort_By}>
                <label className={styles.dashboard_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dashboard_sortBy_select}
                  value={sortKey || orderStatus}
                  onChange={(e) => {
                    if (e.target.value === 'Ascending' || e.target.value === 'Descending' || e.target.value === 'lastAction') {
                      setSortKey(e.target.value)
                    } else {
                      setOrderStatus(e.target.value)
                      setSortKey("")
                    }
                  }}
                >
                  <option value="All">View All</option>
                  {/* <option value="All">All</option> */}
                  <option value="New Order">New Order</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Packed">Packed</option>
                  <option value="Pending">Pending</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Ascending">Ascending</option>
                  <option value="Descending">Descending</option>
                  <option value="lastAction">Last Action</option>
                </select>
              </div>

              <div className={styles.dashboard_Sort_By} >
                <div role='button' className={styles.dashboard_sortBy_txt}>Send Data</div>
                <div role='button'><UpArrowIcon /></div>
              </div>
              <div className={styles.dashboard_Sort_By} >
                <div role='button' className={styles.dashboard_sortBy_txt}>Download</div>
                <div role='button'><DownloadIcon /></div>
              </div>

            </div>


            {loading ? <div className={'order_loadingMain'}>
              <img src='/wallicon.gif' alt='walliconGIF' />
            </div> :
              <>

                {data.length !== 0 ?
                  <div className={styles.dispatchOrders_height}>
                    <table className={styles.itemTable}>
                      <thead>
                        <tr className={styles.item}>
                          <th>
                            {true ? <span className={styles.checkbox_empty}></span> : <span className={styles.checkbox}><CheckGreenIcon /></span>}
                          </th>
                          <th>Name & City</th>
                          <th>Cart Type</th>
                          <th>Date</th>
                          <th>Order Id</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Last Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.map((val, index) =>
                          <tr key={index}
                            className={`${styles.item} ${val.reqStatus === "Pending" ? 'text-danger' : ""}`}
                            onClick={() => { navigate('/invoices/invoice?orderId=' + val.orderId)}}
                            title={val.reqStatus === "Pending" ? val.reqIssue : ""}
                          >
                            <th> {true ? <span className={styles.checkbox_empty}></span> : <span className={styles.checkbox}><CheckGreenIcon /></span>}</th>
                            <td className={styles.ordersName_truncate}
                              title={val.storeName + ", " + val.city}
                            >
                              <span className={styles.hide}> {val.storeName + ", " + val.city}</span></td>
                            <td>{val.cartType} </td>
                            <td>{val.orderDate} </td>
                            <td>{val.orderId} </td>
                            <td>{val.totalAmount}</td>
                            <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
                              styles.table_txt_green : val.status === 'Payment Pending' ?
                                styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending') ?
                                  styles.table_txt_yellow : val.status === 'New Order' ?
                                    styles.table_txt_blue : ''}`}>
                              {val.status}
                            </td>
                            <td>{val.lastAction} </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  :
                  <div className={styles.noData}>
                    No Data Found !
                  </div>}
              </>
            }

            {data.length !== 0 ?
              <Paginantion
                total={totalPages}
                current={page}
              />
              :
              ''
            }
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Invoices