import React, { useEffect, useRef, useState } from 'react'
import styles from './Templates.module.css'
import { GreenButton } from '../../components/Buttons'
import { BoldIcon, CheckGreenIcon, DocumentIcon, EmojisIcon, GotoWebsiteIcon, ImageIcon, ItalicIcon, LiveLocationIcon, Phone, Spinner, UpLoadFile, VideoIcon } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CreateTemplate = () => {
    const { managerId } = useAuth()
    const navigate = useNavigate();
    const [searchparams] = useSearchParams()
    const getTemplatName = searchparams.get("templateName")
    const [loading, setLoading] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [category, setCategory] = useState()
    const [language, setLanguage] = useState('')
    const [header, setHeader] = useState()
    const [limitedTime, setLimitedTime] = useState()
    const [footer, setFooter] = useState()
    const [buttons, setButtons] = useState([])
    const [type, setType] = useState()
    const [media, setMedia] = useState('')
    const [text, setText] = useState('')
    const [bodyText, setBodyText] = useState('')
    const [footerText, setFooterText] = useState()
    const [imageData, setImageData] = useState([])
    const [fileData, setFileData] = useState([])
    const [showEmojis, setShowEmojis] = useState(false);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    const [headerData, setHeaderData] = useState();
    const [documentName, setDocumentName] = useState('');
    const [errors, setErrors] = useState({});
    // const [templateData, setTemplateData] = useState()
    const [fileDetails, setFileDetails] = useState({
        file_name: '',
        file_type: '',
        file_length: '',
    })
    const handleSelectionChange = event => {
        setSelectionStart(event.target.selectionStart);
        setSelectionEnd(event.target.selectionEnd);
    };

    const applyBold = () => {
        const newText = bodyText && `${bodyText.slice(0, selectionStart)}**${bodyText.slice(selectionStart, selectionEnd)}**${bodyText.slice(selectionEnd)}`;
        setBodyText(newText);
    };

    const applyItalic = () => {
        const newText = bodyText && `${bodyText.slice(0, selectionStart)}_${bodyText.slice(selectionStart, selectionEnd)}_${bodyText.slice(selectionEnd)}`;
        setBodyText(newText);
    };

    const applyLineBreak = () => {
        const newText = bodyText &&
            bodyText.slice(0, selectionStart) +
            '<br />' +
            bodyText.slice(selectionEnd);
        setBodyText(newText);
    };

    const validateURL = (index, url) => {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})' + // domain name or IP
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        );

        const isValid = urlPattern.test(url);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: isValid ? '' : 'Invalid URL',
        }));
    };

    const formattedText = bodyText && bodyText
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        .replace(/\n/g, '<br />');


    function handleChangeImage(e) {
        setImageData(e.target.files[0]);
        setFileDetails(prevState => ({
            ...prevState,
            file_name: e.target.files[0].name ? e.target.files[0].name : '',
            file_type: e.target.files[0].name ? e.target.files[0].name.split('.')[1] : '',
            file_length: e.target.files[0].size ? e.target.files[0].size : '',
        }))
        // setImageData(e.target.files[0]);
    }

    const handleAddButton = () => {
        // Create a new button object with default values
        const newButton = {
            type: 'URL',
            text: '',
            // urlType: '',
            url: ''
        };

        // Update the buttons state by adding the new button
        setButtons([...buttons, newButton]);
    };

    const handleChange = (index, key, value) => {
        // Update the corresponding button's property
        const updatedButtons = [...buttons];
        if (key === 'phone_number' && !value.startsWith('91')) {
            value = '91' + value;
        }
        updatedButtons[index][key] = value;

        // Remove urlType and websiteURL if typeOfAction is changed to "Call Phone Number"
        if (key === 'type' && value === 'PHONE_NUMBER') {
            // delete updatedButtons[index]['urlType'];
            delete updatedButtons[index]['url'];
        }

        setButtons(updatedButtons);
        if (key === 'url') {
            validateURL(index, value);
        }
    };

    const handleRemoveButton = (index) => {
        // Remove the button at the specified index
        const updatedButtons = [...buttons];
        updatedButtons.splice(index, 1);
        setButtons(updatedButtons);
    };
    // console.log(fileDetails)
    // console.log(fileDetails.file_name, fileDetails.file_length, fileDetails.file_type);
    const ensureProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'https://' + url;
        }
        return url;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleCreateTemplate()
        }
    };

    const handleCreateTemplate = () => {
        if (type === 'media') {
            if (fileDetails.file_name && fileDetails.file_length && fileDetails.file_type) {
                setLoading(true)
                const myHeaders = new Headers();
                myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

                const formdata = new FormData();
                formdata.append("file", imageData);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow"
                };

                fetch(`${process.env.REACT_APP_URL}wallikonChat/templateCreation?fileLength=${fileDetails.file_length}&fileType=${(media === 'document' ? 'application' : media) + "/" + fileDetails.file_type}&fileName=${fileDetails.file_name}`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === 'success') {
                            handleSubmitTemplate(result.data.h)
                        }
                    })
                    .catch((error) => console.error(error));
            }
        }
        else {
            setLoading(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer EABljmRig1VcBO8EokWI5QUZBu8tsUfEsZADtERQitatMhA3WOn7ajgrcRuQ0fQbZA5uAiP5RXamzt1BZCaWJkGGBZBobhZAa91WB8BCORGvJwvZBuDSBccs3ZAWSDPH5STTYyThu0OYbGKE6979SuzCreXjRpZBR5pFZBT6sFeLzUE4yKQZA8ZC5jLay14ZBkH9PGSkA80HJSZC4bVJTS3OsgB");
            myHeaders.append("Cookie", "ps_l=0; ps_n=0");

            let components = [
                // {
                //     "type": '',
                //     "format": "TEXT",
                //     "text": text,
                // },
                {
                    "type": "BODY",
                    "text": bodyText
                }
            ];

            // Conditionally add the FOOTER component
            if (header && text !== '') {
                components.push({
                    "type": '',
                    "format": "TEXT",
                    "text": text,
                });
            }

            if (footer && (footerText !== '' && footerText !== undefined && footerText !== null)) {
                components.push({
                    "type": "FOOTER",
                    "text": footerText
                });
            }

            // Conditionally add the BUTTONS component
            if (buttons.length > 0) {
                components.push({
                    "type": "BUTTONS",
                    "buttons": buttons
                });
            }
            const raw = JSON.stringify({
                "name": templateName && templateName.replace(/ /g, '_').toLowerCase(),
                "category": category,
                "language": language,
                "components": components
                // "components": [
                //     {
                //         "type": '',
                //         "format": "TEXT",
                //         "text": text,
                //     },
                //     {
                //         "type": "BODY",
                //         "text": bodyText
                //     },
                //     {
                //         "type": "FOOTER",
                //         "text": footerText
                //     },
                //     {
                //         "type": "BUTTONS",
                //         "buttons": buttons
                //     }
                // ]
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://graph.facebook.com/v18.0/186433717894900/message_templates", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'APPROVED' || result.status === 'PENDING') {
                        handleAddTemplate()
                    } else {
                        alert(result.status)
                        setLoading(false)
                    }
                })
                .catch((error) => console.error(error));
        }
    }

    const handleSubmitTemplate = (header_handle) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz");
        myHeaders.append("Cookie", "ps_l=1; ps_n=1");


        let components = [
            {
                "type": "HEADER",
                "format": media === 'image' ? "IMAGE" : media === 'video' ? "VIDEO" : media === 'document' ? "DOCUMENT" : '',
                "example": {
                    "header_handle": [
                        header_handle
                    ]
                }
            },
            {
                "type": "BODY",
                "text": bodyText
            }
        ];

        // Conditionally add the FOOTER component
        if (footerText !== '' && footerText !== undefined && footerText !== null) {
            components.push({
                "type": "FOOTER",
                "text": footerText
            });
        }

        // Conditionally add the BUTTONS component
        if (buttons.length > 0) {
            components.push({
                "type": "BUTTONS",
                "buttons": buttons
            });
        }

        const raw = JSON.stringify({
            "name": templateName && templateName.replace(/ /g, '_').toLowerCase(),
            "category": category,
            "language": language,
            "components": components
        });
        // const raw = JSON.stringify({
        //     "name": templateName,
        //     "category": category,
        //     "language": language,
        //     "components": [
        //         {
        //             "type": "HEADER",
        //             "format": media === 'image' ? "IMAGE" : media === 'video' ? "VIDEO" : media === 'document' ? "DOCUMENT" : '',
        //             "example": {
        //                 "header_handle": [
        //                     header_handle
        //                 ]
        //             }
        //         },
        //         {
        //             "type": "BODY",
        //             "text": bodyText
        //         },
        //         {
        //             "type": "FOOTER",
        //             "text": footerText
        //         },
        //         {
        //             "type": "BUTTONS",
        //             "buttons": buttons
        //         }
        //         // {
        //         //     "type": "BUTTONS",
        //         //     "buttons": [
        //         //         {
        //         //             "type": "PHONE_NUMBER",
        //         //             "text": "Call",
        //         //             "phone_number": "919140664720"
        //         //         },
        //         //         {
        //         //             "type": "URL",
        //         //             "text": "Contact Support",
        //         //             "url": "https://dealer.wallicon.in"
        //         //         },
        //         //         {
        //         //             "type": "URL",
        //         //             "text": "Visit Website",
        //         //             "url": "https://dealer.wallicon.in/payment-order/shop?promo={{1}}",
        //         //             "example": [
        //         //                 "payment-order"
        //         //             ]
        //         //         }
        //         //     ]
        //         // }
        //     ]
        // });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://graph.facebook.com/v19.0/186433717894900/message_templates", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'APPROVED' || result.status === 'PENDING') {
                    handleAddTemplate()
                }
                else if (result.status === 'REJECTED') {
                    alert(result.status)
                    setLoading(false)
                }
                alert(result.status)
                setLoading(false)
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }

    const handleAddTemplate = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        const formdata = new FormData();
        formdata.append("managerId", managerId);
        formdata.append("templateName", templateName && templateName.replace(/ /g, '_').toLowerCase());
        formdata.append("category", category);
        formdata.append("language", language);
        if (media) { formdata.append("headerType", media); }
        if (media && documentName) { formdata.append("documentName", media === 'document' ? documentName : ''); }
        formdata.append("body", bodyText);
        if (footerText) { formdata.append("footer", footerText); }
        // formdata.append("linkText", "Payment Link");
        // formdata.append("linkUrl", "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14");
        if (media) {
            formdata.append("ContentType", (media === 'document' ? "application" : media) + '/' + fileDetails.file_name.split('.')[1]);
        }
        if (imageData.name) { formdata.append("file", imageData); }
        if (buttons.length > 0) { formdata.append("buttons", JSON.stringify(buttons, null, 2)) }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/addTemplate", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    alert(result.msg)
                    setLoading(false)
                    navigate('/templates')
                } else {
                    alert(result.msg)
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }

    const handleEmojiSelect = emoji => {
        setBodyText(prevContent => prevContent + emoji);
    };

    // const divEmojis = useRef(null);
    // const handleClickOutsideEmojis = (event) => {
    //     if (divEmojis.current && !divEmojis.current.contains(event.target)) {
    //         setShowEmojis(false)
    //     }
    // };
    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutsideEmojis);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutsideEmojis);
    //     };
    // }, []);

    const emojisRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (emojisRef.current && !emojisRef.current.contains(event.target)) {
                setShowEmojis(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (getTemplatName && managerId) {
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "templateName": getTemplatName
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "wallikonChat/singleTemp", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'success') {
                        setTemplateName(result.template.templateName ? result.template.templateName.split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ') : '')
                        setCategory(result.template.category ? result.template.category : '')
                        setLanguage(result.template.language ? result.template.language : '')
                        setHeader(result.template.header ? result.template.header : '')
                        setBodyText(result.template.body ? result.template.body : '')
                        setFooter(result.template.footer ? true : '')
                        setFooterText(result.template.footer ? result.template.footer : '')
                        if (result.template.headerType !== 'text') {
                            setType('media')
                        }
                        setMedia(result.template.headerType ? result.template.headerType : '')
                        setHeaderData(result.template.header ? result.template.header : '')
                        setDocumentName(result.template.documentName ? result.template.documentName : '')
                        setButtons(result.template.buttons ? result.template.buttons : [])
                        // setTemplateData(prevState=>({
                        //     ...prevState,
                        //     templateName: result.template.templateName?result.template.templateName:'',
                        //     category: result.template.category?result.template.category:'',
                        //     language: result.template.language?result.template.language:'',
                        //     header: result.template.header?result.template.header:'',
                        //     body: result.template.body?result.template.body:'',
                        //     buttons: result.template.buttons?result.template.buttons: [],
                        //     templateName: result.template.templateName?result.template.templateName:'',
                        //     templateName: result.template.templateName?result.template.templateName:'',
                        // }))
                    }
                })
                .catch((error) => console.error(error));
        }
    }, [getTemplatName, managerId])


    // console.log('imageData', imageData);

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} className='px-3 py-2 w-100 text-light'>
                <h3>{getTemplatName ? getTemplatName.split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ') : 'Create Template'}</h3>
                {getTemplatName ? '' :
                    <div className={styles.templateName_div}>
                        {templateName &&
                            <div className={styles.templateName_alert}>
                                Only letters (a-z) and numbers (0-9) are allowed.
                            </div>
                        }
                        <div className='d-flex justify-content-between align-items-center'>
                            <label>Template Name</label>
                            <input
                                placeholder='Enter Template Name'
                                type="text"
                                className={styles.templteName}
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className={styles.template_guidelines}>Template Guidelines</div>
                            <GreenButton btnType="submit" css={styles.doneButton} disabled={loading ? true : false} title={loading ? <Spinner size="15" /> : "Done"}
                            //  handleSubmit={() => handleCreateTemplate()} 
                            />
                        </div>
                    </div>
                }

                <div className='d-flex'>
                    <div className={styles.templateLeft}>
                        <div className='d-flex justify-content-between align-items-center pt-4 border-secondary'>
                            <div> MARKETING </div>
                            <div className={styles.template_change}>Change</div>
                        </div>

                        <div className='d-flex flex-column border-bottom my-2 pb-2'>
                            <div className='d-flex justify-content-left flex-column my-1'>
                                <div className='d-flex text-left'>
                                    <input className={styles.header_radio_btn} type='radio' name='marketing' value='MARKETING' required={category ? false : true} checked={category === 'MARKETING' ? true : false} onChange={(e) => setCategory(e.target.value)} />
                                    <div className={styles.mediaOptions}>MARKETING</div>
                                </div>
                                <p className='ps-4'>Promotions or information about your business, products or services. Or any message that isn't utility or authentication.</p>
                            </div>
                            <div className='d-flex flex-column justify-content-left my-1'>
                                <div className='d-flex text-left'>
                                    <input className={styles.header_radio_btn} type='radio' name='marketing' value='UTILITY' required={category ? false : true} checked={category === 'UTILITY' ? true : false} onChange={(e) => setCategory(e.target.value)} />
                                    <div className={styles.mediaOptions}>UTILITY</div>
                                </div>
                                <p className='ps-4'>Messages about a specific transaction. account, order or customer request.</p>
                            </div>
                            <div className='d-flex justify-content-left align-items-center my-1'>
                                <input disabled className={styles.header_radio_btn} type='radio' name='marketing' value='AUTHENTICATION' required={category ? false : true} checked={category === 'AUTHENTICATION' ? true : false} onChange={(e) => setCategory(e.target.value)} />
                                <div className={styles.mediaOptions}>AUTHENTICATION</div>
                            </div>
                        </div>

                        <label className='mt-2'>Language</label>
                        <select className={styles.language_select} value={language} required onChange={(e) => setLanguage(e.target.value)}>
                            <option value=''>Select</option>
                            <option value='en'>English</option>
                            <option value='en_US'>English (US)</option>
                            <option value='en_UK'>English (UK)</option>
                        </select>
                    </div>

                    <div className={styles.templateCenter}>

                        <div className="d-flex">
                            <div
                                role='button'
                                className='border rounded p-1 d-flex justify-content-center align-items-center'
                                onClick={() => { setHeader(!header) }}
                            >
                                {(header
                                ) ? (
                                    <CheckGreenIcon />
                                ) : (
                                    <span className='p-2'></span>
                                )}
                            </div>
                            <label className='ms-3 mb-0'>Header</label>
                        </div>

                        {header &&
                            <>
                                <div className='mt-3 d-flex flex-column'>
                                    <label>Type <span className='text-danger'>*</span></label>
                                    <select className={styles.headerType_select} value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value='' hidden>Select</option>
                                        <option value='text'>Text</option>
                                        <option value='media'>Media</option>
                                    </select>
                                </div>
                                {type === 'media' && <>
                                    <div className='mt-3 d-flex flex-rows '>
                                        <div className='px-2 d-flex justify-content-center align-items-center'>
                                            <input className={styles.header_radio_btn} type='radio' name='media' value='image' checked={media === 'image' ? true : false} onChange={(e) => setMedia(e.target.value)} onClick={() => setImageData([])} />
                                            <div className={styles.mediaOptions}>Image</div>
                                        </div>
                                        <div className='px-2 d-flex justify-content-center align-items-center'>
                                            <input className={styles.header_radio_btn} type='radio' name='media' value='video' checked={media === 'video' ? true : false} onChange={(e) => setMedia(e.target.value)} onClick={() => setImageData([])} />
                                            <div className={styles.mediaOptions}>video</div>
                                        </div>
                                        <div className='px-2 d-flex justify-content-center align-items-center'>
                                            <input className={styles.header_radio_btn} type='radio' name='media' value='document' checked={media === 'document' ? true : false} onChange={(e) => setMedia(e.target.value)} onClick={() => setImageData([])} />
                                            <div className={styles.mediaOptions}>document</div>
                                        </div>
                                        <div className='px-2 d-flex justify-content-center align-items-center'>
                                            <input className={styles.header_radio_btn} type='radio' name='media' value='location' checked={media === 'location' ? true : false} onChange={(e) => setMedia(e.target.value)} onClick={() => setImageData([])} />
                                            <div className={styles.mediaOptions}>Location</div>
                                        </div>
                                    </div>

                                    <p className='ps-2 mt-4'>Add Sample Media File</p>
                                    <p className='ps-2 mt-4'>Provide examples of the media in the header to help Meta review your templates. Do not include any customer information.</p>
                                    {media !== '' &&
                                        <>
                                            <p className='ps-2 mt-4'>

                                                <label onChange={handleChangeImage} className={styles.darkBtn}>
                                                    <input name="" type="file" id="formId" hidden />
                                                    <UpLoadFile />&nbsp;Upload Media&nbsp;<span className='text-danger'>*</span>
                                                </label>

                                                {/* <button className={styles.darkBtn} onChange={handleChange} ></button> */}
                                            </p>
                                            <div className='ps-2 text-secondary'>Add Sample Media File</div>
                                            <div className='ps-2 text-secondary'>Supported file formats - {media === 'document' ? 'PDF' : media === 'video' ? 'MP4, WEBM, MKV, MPEG-4, AVI, WMV' : media === 'audio' ? 'MP3' : media === 'image' ? 'png, jpeg, jpg' : ''}</div>
                                        </>
                                    }
                                </>}
                                {type === 'text' &&
                                    <>
                                        <div>
                                            <p className='mt-4 mb-1'>Text&nbsp;<span className='text-danger'>*</span></p>
                                            <input type='text' placeholder='Enter Text Here' className={styles.templateFooter} value={text} onChange={(e) => setText(e.target.value)} />
                                            <div className={styles.templateBody_count}>0 / 60</div>
                                        </div>
                                    </>
                                }
                                {
                                    media === 'document' &&
                                    <>
                                        <div>
                                            <p className='mt-4 mb-1'>Document Name&nbsp;<span className='text-danger'>*</span></p>
                                            <input required type='text' placeholder='Enter Document Name Here' className={styles.templateFooter} maxLength="60" value={documentName} onChange={(e) => setDocumentName(e.target.value)} onInput={(e) => e.target.value = e.target.value.slice(0, 60)} />
                                            <div className={styles.templateBody_count}>{(documentName && documentName.length) ? (documentName && documentName.length) : 0} / 60</div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <div className="d-flex my-4">
                            <div
                                role='button'
                                className='border rounded p-1 d-flex justify-content-center align-items-center'
                                onClick={() => { setLimitedTime(!limitedTime) }}
                            >
                                {(limitedTime
                                ) ? (
                                    <CheckGreenIcon />
                                ) : (
                                    <span className='p-2'></span>
                                )}
                            </div>
                            <label className='ms-3'>Limited-Time Offer</label>
                        </div>
                        <div>
                            <p className='mt-4 mb-1'>Body&nbsp;<span className='text-danger'>*</span></p>
                            <textarea
                                placeholder='Enter Message Here'
                                rows={4}
                                className={styles.templateBody}
                                value={bodyText}
                                onChange={(e) => setBodyText(e.target.value)}
                                onSelect={handleSelectionChange}
                                required
                            />
                        </div>

                        <div className='d-flex justify-content-between'>
                            {/* <EmojiPicker onSelect={handleEmojiSelect} /> */}
                            <div className={styles.templateBody_count}>Characters: {bodyText?.length} / 1024</div>
                            <div className='d-flex justify-content-left align-items-center'>
                                <div className={styles.txtEditor_btn}>
                                    <div className={styles.icon_css} onClick={() => setShowEmojis(true)}><EmojisIcon /></div>
                                    {showEmojis &&
                                        // ref={divEmojis}
                                        <div className={styles.emojis_list} ref={emojisRef}>
                                            <div className='bg-light'> <EmojiPicker onSelect={handleEmojiSelect} /></div>
                                        </div>
                                    }
                                    <div className={styles.icon_css} onClick={applyBold}><BoldIcon /></div>
                                    <div className={styles.icon_css} onClick={applyItalic}><ItalicIcon /></div>
                                    <div className={styles.icon_css} onClick={applyLineBreak}>&#129031;</div>
                                </div>
                            </div>
                            {/* <div className='bg-light'> <EmojiPicker onSelect={handleEmojiSelect} /></div> */}
                        </div>

                        {/* <EmojiPicker onSelect={handleEmojiSelect} />
                        <div className="format-buttons">
                            <button onClick={applyBold}>Bold</button>
                            <button onClick={applyItalic}>Italic</button>
                            <button onClick={applyLineBreak}>&#129031;</button>
                        </div> */}

                        <div className="d-flex my-4">
                            <div
                                role='button'
                                className='border rounded p-1 d-flex justify-content-center align-items-center'
                                onClick={() => { setFooter(!footer) }}
                            >
                                {(footer
                                ) ? (
                                    <CheckGreenIcon />
                                ) : (
                                    <span className='p-2'></span>
                                )}
                            </div>
                            <label className='ms-3'>Footer</label>
                        </div>
                        {footer &&
                            <div>
                                <p className='mt-4 mb-1'>Footer Text&nbsp;<span className='text-danger'>*</span></p>
                                <input type='text' placeholder='Enter Text Here' className={styles.templateFooter} value={footerText} onChange={(e) => setFooterText(e.target.value)} maxLength="60" onInput={(e) => e.target.value = e.target.value.slice(0, 60)} />
                                <div className={styles.templateBody_count}>{footerText && footerText.length} / 60</div>
                            </div>
                        }

                        <div className="d-flex my-4">
                            <div
                                role='button'
                                className='border rounded p-1 d-flex justify-content-center align-items-center'
                                onClick={() => { if (buttons?.length !== 0) { setButtons([]) } else { handleAddButton() } }}
                            >
                                {(buttons?.length !== 0
                                ) ? (
                                    <CheckGreenIcon />
                                ) : (
                                    <span className='p-2'></span>
                                )}
                            </div>
                            <label className='ms-3'>Button</label>
                        </div>
                        {buttons?.length !== 0 &&
                            <>
                                {buttons?.map((button, index) => (
                                    <div key={index} className={styles.templateButton}>
                                        <div className={styles.typeofAction}>
                                            <div>Type of action</div>
                                            <select
                                                className={styles.CallToActionBtn}
                                                value={button.type}
                                                onChange={(e) => handleChange(index, 'type', e.target.value)}
                                            >
                                                <option value='URL'>Visit website</option>
                                                <option value='PHONE_NUMBER'>Call Phone Number</option>
                                                <option value='Copy offer code'>Copy offer code</option>
                                            </select>
                                        </div>
                                        <div className={styles.btnTxt}>
                                            <div>Button text</div>
                                            <input type='text' placeholder='Enter text' className={styles.CallToActionBtn}
                                                value={button.text}
                                                onChange={(e) => handleChange(index, 'text', e.target.value)}
                                            />
                                        </div>
                                        {button.type !== 'PHONE_NUMBER' ?
                                            <>
                                                <div className={styles.urltype}>
                                                    <div>URL type</div>
                                                    <select className={styles.CallToActionBtn}
                                                    // value={button.urlType}
                                                    // onChange={(e) => handleChange(index, 'urlType', e.target.value)}
                                                    >
                                                        <option value='Static'>Static</option>
                                                        <option value='Dynamic'>Dynamic</option>
                                                    </select>
                                                </div>
                                                <div className={styles.websiteURL}>
                                                    <div>Website URL</div>
                                                    <input type='text' placeholder='Enter Website URL' className={styles.CallToActionBtn}
                                                        value={button.url}
                                                        onChange={(e) => handleChange(index, 'url', e.target.value)}
                                                    />
                                                    {errors[index] && <div className={styles.error}>{errors[index]}</div>}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={styles.urltype}>
                                                    <div>Country</div>
                                                    <select className={styles.CallToActionBtn}
                                                    // value={button.country}
                                                    // onChange={(e) => handleChange(index, 'country', e.target.value)}
                                                    >
                                                        <option value='' hidden>Select</option>
                                                        <option value='+91'>+91</option>
                                                    </select>
                                                </div>
                                                <div className={styles.websiteURL}>
                                                    <div>Phone number</div>
                                                    <input type='number' placeholder='Enter phone number' className={styles.CallToActionBtn}
                                                        value={button.phone_number}
                                                        onChange={(e) => handleChange(index, 'phone_number', e.target.value)}
                                                        maxLength="14"
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 14)}
                                                    />
                                                </div>
                                            </>
                                        }
                                        <div className={styles.removeBtn} onClick={() => handleRemoveButton(index)}>&#10060;</div>
                                    </div>
                                ))}

                                <button type='button' className={styles.darkBtn} onClick={(buttons?.length < 4) && handleAddButton}>&#10133;&nbsp;Add Button</button>
                            </>}
                    </div>

                    <div className={styles.templateRight}>
                        <h4 className='bg-dark text-light mt-3 py-3 ps-3'>Message Preview</h4>
                        <div className={styles.templateContent}>
                            {((headerData || imageData?.length !== 0) && (media === 'document' || media === 'video')) && <div className={styles.pdf_vertical}></div>}
                            {header && type?.length === 0 ? '' :
                                header && type === 'text' ?
                                    <h4 className='text-dark text-capitalize'>{text}</h4> :
                                    header && type === 'media' ?
                                        type === 'media' && media === 'image' && (imageData?.length !== 0 || headerData) ?
                                            <>
                                                {!headerData ?
                                                    <img src={URL.createObjectURL(imageData)} alt={'template_image'} width='288' height='100%' />
                                                    :
                                                    <img src={headerData} alt={'template_image'} width='288' height='100%' />
                                                }
                                            </>
                                            :

                                            <div className={styles.mediadiv}>
                                                <span className={styles.mediaIcon}>
                                                    {media === 'image' ? <ImageIcon /> : media === 'video' ? (headerData ? <VideoThumbnail videoUrl={headerData} /> : imageData?.length !== 0 ? <VideoThumbnail videoUrl={URL.createObjectURL(imageData)} /> : <VideoIcon />) : media === 'document' ? (headerData ? <PDFViewer url={headerData} /> : imageData?.length !== 0 ? <PDFViewer url={URL.createObjectURL(imageData)} /> : <DocumentIcon />) : media === 'location' ? <LiveLocationIcon /> : ''}
                                                </span>
                                            </div>
                                        : ''
                            }
                            {bodyText && <div className={`text-dark ${header ? 'mt-2' : ''} mb-0`} dangerouslySetInnerHTML={{ __html: formattedText }} ></div>}
                            {footer && <div className='text-secondary small mt-1 text-capitalize'>{footerText}</div>}
                            {buttons?.map((button, index) =>
                                <div key={index} className='text-capitalize text-primary text-center border-top mt-2 pt-1'><span className='me-2'>{button.type === "PHONE_NUMBER" ? <Phone color='#fff' /> : <GotoWebsiteIcon />}</span><a className={styles.websiteLink} href={ensureProtocol(button.url)} target='_blank' rel="noopener noreferrer"><b>{button.text}</b></a></div>
                            )}
                        </div>
                    </div>

                </div>

            </form>

            {/* 
                <div className='text-center mt-4'>
                    <GreenButton btnType="submit" css={styles.doneButton} title={loading ? <Spinner size="15" /> : "Submit"} />
                </div> 
            */}
            {/* <p className='ps-2 mt-4'>Create buttons that let customers respond to your message or take action.</p> */}
        </React.Fragment>
    )
}

export default CreateTemplate

const EmojiPicker = ({ onSelect }) => {
    const smileys = [
        "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
        "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
        "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
        "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
        "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
        "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
        "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
        "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
        "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
        "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
        "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
        "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
        "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
        "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
        "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
        "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
        "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
        "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
        "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
        "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
        "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
        "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
        "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
        "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
    ];

    const travelAndPlaces = [
        "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
        "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
        "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
        "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
        "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
        "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
        "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
        "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
    ];

    const object = [
        "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
        "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
        "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
        "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
        "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
        "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
        "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
        "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
        "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
        "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
    ];


    return (
        <div className={styles.emojis_div}>
            <div>
                <b className='d-block'>Smileys</b>
                {smileys?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
            </div>
            <div className='mt-2'>
                <b className='d-block'>Travel And Places</b>
                {travelAndPlaces?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
            </div>
            <div className='mt-2'>
                <b className='d-block'>Object</b>
                {object?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
            </div>
        </div>
    );
};

const PDFViewer = ({ url }) => {
    return (
        <>
            <div className={styles.pdfView} >
                <object
                    className={styles.pdf_container}
                    data={`${url}#page=1`}
                    type="application/pdf"
                    width="50%"
                    height="100%"
                >
                    <p>This browser does not support PDFs. Please download the PDF to view it: <a href={url}>Download PDF</a></p>
                </object>
            </div>
        </>
    );
}


const VideoThumbnail = ({ videoUrl }) => {
    const videoRef = useRef(null);
    // const [thumbnailUrl, setThumbnailUrl] = useState(null);

    // const generateThumbnail = () => {
    //     const canvas = document.createElement('canvas');
    //     canvas.width = videoRef.current.videoWidth;
    //     canvas.height = videoRef.current.videoHeight;
    //     canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    //     const url = canvas.toDataURL();
    //     setThumbnailUrl(url);
    // };

    return (
        <div className={styles.pdfView}>
            <video ref={videoRef} controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <button onClick={generateThumbnail}>Generate Thumbnail</button> */}
            {/* {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" />} */}
        </div>
    );
};