import React from "react";
import { useRef, useEffect, useState } from "react";
import { Search } from "../../components/icons/Icon";
import styles from '../kyc/Kyc.module.css'

const apiKey = process.env.REACT_APP_MAP_KEY;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

// load google map api js

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}


const SearchPlace = ({ address, setAddress, city }) => {

    const searchInput = useRef(null);
    const [inputSearch, setInputSearch] = useState("");


    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(prevState => ({
            ...prevState,
            place_id: place.place_id,
            formatted_address: place.formatted_address
        }));
    }

    // init autocomplete
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initAutocomplete = () => {
        if (!searchInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current, { componentRestrictions: { country: "in" } });
        autocomplete.setFields(["address_components", "formatted_address", "place_id", "geometry", "name"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

    }

    // load map script after mounted
    useEffect(() => {
        if (inputSearch) {
            initMapScript().then(() => initAutocomplete())
        }
    }, [inputSearch, initAutocomplete]);

    useEffect(() => {
        if (address.formatted_address) {
            setInputSearch(address.formatted_address)
        }
    }, [address.formatted_address])

    // useEffect(() => {
    //     if (city) {
    //         setInputSearch(city)
    //     }
    // }, [city])
    // console.log(address.place_id)
    return (
        <React.Fragment>
            <div className={styles.map_div}>
                <div className={styles.map_heading_div}>
                    <label className={styles.mapHeading_txt}>Google Map (Optional)</label>
                    <div className={styles.map_seacrh_div}>
                        <label className={styles.search_Icon}>{inputSearch.length === 0 && <Search color="#FFFFFF" />}</label>
                        <input
                            ref={searchInput}
                            type="search"
                            placeholder='Search place'
                            className={styles.seacrh_inputField}
                            value={inputSearch}
                            onChange={(e) => { setInputSearch(e.target.value) }}
                        />
                    </div>
                </div>
                {address.place_id &&
                    <iframe
                        className={styles.google_map}
                        loading="lazy"
                        allowFullScreen
                        title="Wallicon Private Limited"
                        src={`https://www.google.com/maps/embed/v1/place?q=place_id:${address.place_id}&key=${process.env.REACT_APP_MAP_KEY}`}
                    />
                }
            </div>
        </React.Fragment>
    )
};
export default SearchPlace;
