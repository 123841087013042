import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Complaints, DashBoard, Dealers, InventoryIcon, MarketingIcon, Order, PackersIcon, ProductIcon, ReciptsIcon, WarehouseIcon } from '../components/icons/Icon'
import styles from './Menus.module.css'

const AdminMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <React.Fragment>
            <div className={styles.menuItem_div} onClick={() => navigate("/dashboard")}>
                <DashBoard css={styles.iconImage} color={pathname === "/dashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dashboard" ? styles.active_leftMenu : ""}>DASHBOARD</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/dealers")}>
                <Dealers css={styles.iconImage} color={pathname === "/dealers" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dealers" ? styles.active_leftMenu : ""}>DEALERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/orders")}>
                <Order css={styles.iconImage} color={pathname === "/orders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/orders" ? styles.active_leftMenu : ""}>ORDERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/recipts")}>
                <ReciptsIcon css={styles.iconImage} color={pathname === "/recipts" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/recipts" ? styles.active_leftMenu : ""}>RECIPTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/dispatchdashboard")}>
                <PackersIcon css={styles.iconImage} color={pathname === "/dispatchdashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dispatchdashboard" ? styles.active_leftMenu : ""}>DISPATCH</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/packingorder")}>
                <PackersIcon css={styles.iconImage} color={pathname === "/packingorder" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/packingorder" ? styles.active_leftMenu : ""}>PACKING ORDERS</span>
            </div>


            {/* <div className={styles.menuItem_div} onClick={() => navigate("/packers")}>
                <DispatchIcon css={styles.iconImage} color={pathname === "/packers" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/packers" ? styles.active_leftMenu : ""}>PACKERS</span>
            </div> */}

            <div className={styles.menuItem_div} onClick={() => navigate("/inventory")} >
                <InventoryIcon css={styles.iconImage} color={pathname === "/inventory" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/inventory" ? styles.active_leftMenu : ""}>INVENTORY</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/outsideinventory")}>
                <InventoryIcon css={styles.iconImage} color={pathname === "/outsideinventory" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/outsideinventory" ? styles.active_leftMenu : ""}>OUTSIDE INVENTORY</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/invoices")}>
                <InventoryIcon css={styles.iconImage} color={pathname === "/invoices" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/invoices" ? styles.active_leftMenu : ""}>INVOICES</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/templates")}>
                <InventoryIcon css={styles.iconImage} color={pathname === "/templates" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/templates" ? styles.active_leftMenu : ""}>TEMPLATES</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/complaints")} >
                <Complaints css={styles.iconImage} color={pathname === "/complaints" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/complaints" ? styles.active_leftMenu : ""}>COMPLAINTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/products")} >
                <ProductIcon css={styles.iconImage} color={pathname === "/products" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/products" ? styles.active_leftMenu : ""}>PRODUCTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/marketing")} >
                <MarketingIcon css={styles.iconImage} color={pathname === "/marketing" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/marketing" ? styles.active_leftMenu : ""}>MARKETING</span>
            </div>




            <div className={styles.menuItem_div} onClick={() => navigate("/managers")}>
                <DashBoard css={styles.iconImage} color={pathname === "/managers" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/managers" ? styles.active_leftMenu : ""}>MANAGERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/warehouses")}>
                <WarehouseIcon css={styles.iconImage} color={pathname === "/warehouses" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/warehouses" ? styles.active_leftMenu : ""}>WAREHOUSE</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/jobs")}>
                <DashBoard css={styles.iconImage} color={pathname === "/jobs" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/jobs" ? styles.active_leftMenu : ""}>JOBS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/applicants")}>
                <DashBoard css={styles.iconImage} color={pathname === "/applicants" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/applicants" ? styles.active_leftMenu : ""}>APPLICANTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/delivery")}>
                <DashBoard css={styles.iconImage} color={pathname === "/delivery" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/delivery" ? styles.active_leftMenu : ""}>DELIVERY</span>
            </div>
            <div className={styles.menuItem_div} onClick={() => navigate("/greetings")}>
                <DashBoard css={styles.iconImage} color={pathname === "/greetings" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/greetings" ? styles.active_leftMenu : ""}>GREETINGS</span>
            </div>
            <div className={styles.menuItem_div} onClick={() => navigate("/createnotification")}>
                <DashBoard css={styles.iconImage} color={pathname === "/createnotification" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/createnotification" ? styles.active_leftMenu : ""}>CREATE NOTIFICATION</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/deleteoredit")}>
                <WarehouseIcon css={styles.iconImage} color={pathname === "/deleteoredit" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/deleteoredit" ? styles.active_leftMenu : ""}>DELETE / EDIT</span>
            </div>
            <div className={styles.menuItem_div} onClick={() => navigate("/accounts")}>
                <DashBoard css={styles.iconImage} color={pathname === "/accounts" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/accounts" ? styles.active_leftMenu : ""}>Accounts</span>
            </div>

        </React.Fragment>
    )
}

export default AdminMenu