import React, { useEffect, useRef, useState } from 'react'
import MobOrdersTab from '../components/MobOrdersTab'
import styles from '../pages/MobOrders.module.css'
import { EyeTwo, Loadmore, OrdersIcon } from '../../components/icons/Icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'

const MobPurchaseOrders = () => {

  const location = useLocation().pathname
  const isOrderType = location.split("/")[location.split("/").length - 1]
  const [activeTab, setActiveTab] = useState("pruchase")
  const navigate = useNavigate();
  const { managerId } = useAuth();
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(true)
  const [sortKey, setSortKey] = useState('')
  const [keyword, setKeyword] = useState('')
  const [orderType, setOrderType] = useState('')
  const [orderStatus, setOrderStatus] = useState('')
  const [warehouseName, setWarehouseName] = useState('')
  const [managerName, setManagerName] = useState('')
  const [daysTab, setDaysTab] = useState('today')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [allData, setAllData] = useState([])
  const [orders, setOrders] = useState("")
  const [warehouses, setWarehouses] = useState([])
  const [managers, setManagers] = useState([])
  const [showAcceptModal3, setShowAcceptModal3] = useState(false);


  useEffect(() => {
    if (isOrderType === "adujstmentorders") {
      setActiveTab("adjustment")
    }
  }, [isOrderType])

  const getCardData = async () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "managerId": parseInt(managerId),
      "keyword": keyword,
      "sortKey": sortKey,
      // "orderType": orderType,
      "orderStatus": orderStatus,
      // "cartType": "",
      "warehouse": warehouseName,
      "inventorymanagerId": managerName,
      "day": daysTab,
      "startDate": startDate,
      "endDate": endDate,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_URL}inventory/allPurchase?page=${page}&limit=8&skip=${true}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          setLoading(false)
          // setPages(result.pages)
          setAllData(result.data)
          setOrders(result)
          if (result.warehouses) {
            setWarehouses(result.warehouses)
          }
          if (result.managers) {
            setManagers(result.managers)
          }
        }
        if (result.pages === 1) {
          setPage(1)
        }
        setPages(result.pages)
        setLoading1(false);
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    /*eslint-disable*/
    getCardData();
  }, [managerId, sortKey, orderType, keyword, warehouseName, managerName, daysTab, endDate, startDate, orderStatus, activeTab]);


  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight

      ) {
        setLoading1(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pages > page) {
      window.addEventListener("scroll", handelInfiniteScroll);
      return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }
  }, [pages, page]);


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAcceptModal3(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <React.Fragment>
      <MobOrdersTab
        styles={styles}
        orders={orders}
        sortKey={sortKey}
        setSortKey={setSortKey}
        loading={loading}
        orderType={orderType}
        setOrderType={setOrderType}
        keyword={keyword}
        setKeyword={setKeyword}
        warehouses={warehouses}
        warehouseName={warehouseName}
        setWarehouseName={setWarehouseName}
        managers={managers}
        setManagers={setManagers}
        managerName={managerName}
        setManagerName={setManagerName}
        daysTab={daysTab}
        setDaysTab={setDaysTab}
        endDate={endDate}
        setEndDate={setEndDate}
        startDate={startDate}
        setStartDate={setStartDate}
        setOrderStatus={setOrderStatus}
        orderStatus={orderStatus}
        setShowAcceptModal3={setShowAcceptModal3}
        showAcceptModal3={showAcceptModal3}
      />
      {
        loading ? <div className={'Mob_loading'}>
          <img src='/wallicon.gif' alt='walliconGIF' />
        </div> :
          <>
            {allData.length !== 0 ?
              <div className={styles.orders + " pb-5"} >
                <h2 className={styles.orders_text}>Purchase Order's</h2>
                {!loading1 ?
                  <>
                    {allData && allData.map((item, index) =>
                      <div role='button' key={index} className={styles.orders_div}
                       onClick={()=> {navigate('/orders/purchase?orderId=' + item.voucherNo)}}
                      >
                        <OrdersIcon className={styles.ordes_img} />
                        <div className={styles.order_detail}>
                          <h2>{item.vendorName}</h2>
                          <span>Voucher No:<span>&nbsp;{item.voucherNo}</span></span>
                          <span>Date: <span>{item.voucherDate}</span></span>
                          <span className={`${styles.order_card} 
                            ${item.status === 'Cancelled' ? styles.cancelled_status :
                              item.status === 'Payment Under Verification' ? styles.payment_status :
                                item.status === 'Packing In Progress' ? styles.inProgress_status :
                                  item.status === 'New Order' ? styles.new_status :
                                    item.status === 'In Progress' ? styles.inProgress_status :
                                      item.status === 'Payment Pending' ? styles.payment_status :
                                        item.status === 'Dispatched' ? styles.Dispatched_status :
                                          item.status === 'success' ? styles.Dispatched_status :
                                            item.status === 'draft' ? styles.inProgress_status :
                                              item.status === 'All' ? styles.inProgress_status :
                                                ''}`}>
                            {item.status}
                          </span>
                        </div>
                        <div>
                          < EyeTwo />
                        </div>
                      </div>
                    )}
                  </>
                  :
                  <div className={styles.loadmore}>
                    <Loadmore className={styles.loadmoreIocon} />
                  </div>
                }
              </div>
              : <div className={styles.noData_all_order}>
                No Data Found !
              </div>
            }
          </>
      }
    </React.Fragment>
  )
}

export default MobPurchaseOrders