import React from "react";
import { Copy } from "../../components/icons/Icon";

export const FileHandlerMob = ({ isImage, setisImage, error, title }) => {
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            setisImage(e.dataTransfer.files)
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
            setisImage(e.target.files)
        }
    };

    // triggers the input when the button is clicked
    // const onButtonClick = () => {
    //     inputRef.current.click();
    // };
    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                {isImage ?
                    <img src={URL.createObjectURL(isImage)} alt="item" className="packingImage" />
                    :
                    <>
                        <div className="drag_div_mob">
                            <Copy />
                            <p className="uploadtext">{title}</p>
                        </div>
                    </>
                }
            </label>
            {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            {error && error.receiptImage && <div className="text-danger d-flex">{error && error.receiptImage}</div>}
        </form>
    )
}