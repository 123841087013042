import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import { LargeEyeIcon } from '../../components/icons/Icon'
import styles from '../inventorymanager/Inventories.module.css'

const InventoryCollections = () => {
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const params = useParams();
    const [searchparams] = useSearchParams()
    const product_id = searchparams.get("product_id")
    const product_name = params.product;

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_id": product_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/collections", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setData(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, product_id])

    return (
        <React.Fragment>
            <div className={styles.main_Container}>
                <div>
                    <span onClick={() => navigate(-1)}>
                        &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
                    </span>
                    <h1>{product_name.toUpperCase()}</h1>
                </div>
                <div className={styles.inventory_scroll_div}>
                    {
                        loading ? <div className={'loadingMain'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :
                            data && data.map((item, index) =>
                                <div
                                    key={index}
                                    className={styles.InventoryCard}
                                    onClick={() => navigate('/inventory/' + product_name.replace(/\s/g, "-").toLowerCase() + "/" + item.collection_name.replace(/\s/g, "-").toLowerCase() + "?product_id=" + product_id + "&collection_id=" + item._id)}>
                                    <div className={styles.productName_div}>
                                        <div className={styles.activeRadioBtn}>
                                            <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
                                            <span className={styles.status_active}>Active</span>
                                        </div>
                                        <h2 className={styles.productName}>{item.collection_name}</h2>
                                    </div>
                                    <div className={styles.collections_details_div}>
                                        <div className={styles.view_div}>
                                            <span className={styles.qty}>{item.itemCount}</span>
                                            <span className={styles.qty_txt}>Items</span>
                                        </div>
                                        <div className={styles.view_div}>
                                            <span className={styles.qty}>{item.qty}</span>
                                            <span className={styles.qty_txt}>Qty</span>
                                        </div>
                                    </div>
                                    <div className={styles.view_div}>
                                        <span><LargeEyeIcon color="#FFFFFF" /></span>
                                        <span className={styles.qty_txt}>View</span>
                                    </div>
                                </div>
                            )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default InventoryCollections
