import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DownArrowCircle, Search, ThreeDots, UpArrowCircle } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import LeadLostModal from './LeadLostModal';
import styles from './Marketing.module.css';
import { GreenButton } from '../../components/Buttons';
import CreateLeadModal from './CreateLeadModal';

const Leads = () => {
  const { switchDisplay, managerId } = useAuth();
  const navigate = useNavigate();
  const [success, setSuccess] = useState()
  const [value, setValue] = useState(false)
  const [leadId, setLeadId] = useState("")
  const [leadData, setLeadData] = useState([]);
  const [action, setAction] = useState({
    show: false,
    index: 0
  })

  const [showModal, setShowModal] = useState(false)
  const selectModal = () => {
    setShowModal(!showModal) // true/false toggle
  }

  const [showModal1, setShowModal1] = useState(false)

  const selectModal1 = () => {
    setShowModal1(!showModal1) // true/false toggle
  }

  useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var raw = JSON.stringify({
      "managerId": managerId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "marketing/leads", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          if (result.data) {
            setLeadData(result.data)
          }
        }
      })
      .catch(error => console.log('error', error));
  }, [leadId, success, managerId, showModal1])



  return (
    <React.Fragment>
      <CreateLeadModal
        displayModal={showModal1}
        closeModal={selectModal1}
        leadId={leadId}
        setLeadId={setLeadId}
        setSuccess={setSuccess}
      />
      <LeadLostModal
        displayModal={showModal}
        closeModal={selectModal}
        leadId={leadId}
        setSuccess={setSuccess}
      />
      <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.marketingMain}`}>
        <h2>Marketing &#62; Leads</h2>
        <div className={styles.marketing}>
          <div className={styles.marketingRow}>
            <div className={styles.marketingCol}>
              <div className={styles.search}>
                <input type="search" placeholder='Search' className={`${switchDisplay ? styles.bg_light : styles.bg_grey}  ${styles.search_input}`} />
                <Search color="#ffffff" css={styles.searchIcon} />
              </div>

              <span className={styles.ml30}>
                Sort By
                <select className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.sortSelect}`}>
                  <option>Newest First</option>
                  <option>Oldest First</option>
                  <option>Ascending</option>
                  <option>Decending</option>
                  <option>Payment Pending</option>
                  <option>Approved</option>
                  <option>Dispatched</option>
                  <option>Packing In Progress</option>
                  <option>Check Stock &amp; Approve</option>
                  <option>Waiting For Dealer Approval</option>
                  <option>COD (Payment Pending)</option>
                  <option>Completed</option>
                </select>
              </span>
            </div>
            <div >
              <GreenButton title="Create Lead" handleSubmit={selectModal1} />
            </div>
          </div>
          <div className={styles.marketingScroll}>
            <div className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.tableCss}`}>
              <div className={styles.tableRow}>
                <div className={styles.col1}>Date</div>
                <div className={styles.col1}>Name</div>
                <div className={styles.col1}>Company</div>
                <div className={styles.col1}>Address</div>
                <div className={styles.col1}>Contact No.</div>
                <div className={styles.col2}>Email</div>
                <div className={styles.col1}>Source</div>
                <div className={styles.col1}>Payment</div>
                <div className={styles.col1}>Priority</div>
                <div className={styles.col1}>Last Contact</div>
                {/* <div className={styles.col1}></div> */}
              </div>
            </div>


            {leadData && leadData.map((item, index) =>
              <div onMouseLeave={() => setAction({ show: false, index: 0 })}
                key={index}
                className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.tableCss}`}
              >
                <div className={styles.tableRow} onClick={() => { navigate("/marketing-lead/" + item.leadId) }}>
                  <div className={styles.col1}>
                    {item.createdAt && new Date(item.createdAt).toDateString().slice(4, 15)}
                  </div>
                  <div className={styles.col1}>{item.name}</div>
                  <div className={styles.col1}>{item.companyName}</div>
                  <div className={styles.col1}>{item.address}</div>
                  <div className={styles.col1}>{item.mobile}</div>
                  <div className={styles.col2}>{item.email}</div>
                  <div className={styles.col1}>{item.source}</div>
                  <div className={styles.col1}>{item.payment}</div>
                  <div className={styles.col1} ><div className={item.priority === 'High' ? styles.priority_1 : item.priority === 'Low' ? styles.priority_2 : styles.priority_3}>{item.priority}</div></div>
                  <div className={styles.col1}>{item.contact}</div>
                </div>
                <div className={styles.marketingOrder_lead_div} >
                  <span className={styles.marketingOrder_lead}>
                    <span>
                      {value === index ?
                        <span onClick={() => setValue(!index)} >
                          <UpArrowCircle color="#ffffff" size={20} />
                        </span>
                        :
                        <span onClick={() => setValue(index)}>
                          <DownArrowCircle color="#ffffff" size={20} />
                        </span>
                      }
                    </span>
                  </span>
                  <span className={styles.threeDot} onMouseOver={() => setAction({ show: true, index: index })}>
                    <ThreeDots color="#FFFFFF" />
                  </span>
                </div>
                {(action.show && action.index === index) &&
                  <div className={styles.threeDots_div_position} onMouseLeave={() => setAction({ show: false, index: 0 })} >
                    <div className={`${switchDisplay ? styles.bg_light : styles.bg_dark} ${styles.threeDotBP}`}>
                      <div className={styles.mb5} onClick={() => { selectModal1(); setLeadId(item.leadId) }}>
                        Edit Contact
                      </div>
                      <div className={switchDisplay ? styles.threeDotBL : styles.threeDotBB}>
                        <div className={styles.mt5} onClick={() => { selectModal(); setLeadId(item.leadId) }}>
                          Mark As Lead Lost
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {value === index ?
                  <div >
                    <strong className={styles.clr_Blue}>Last Note:</strong> {item.notes && item.notes.note}
                  </div>
                  :
                  ""
                }

              </div>
            )}
          </div>
        </div>
      </div >


    </React.Fragment >
  )
}

export default Leads