import React from 'react'
import { BellIcon, FullScreen, Invoice, User, WhatsAppHeader } from './icons/Icon'
import styles from './Header.module.css'
import { useAuth } from './context/Auth'
import { useNavigate } from 'react-router-dom'

const Headers = () => {
    const navigate = useNavigate()
    const { switchDisplay, setSwitchDisplay, designation, setDesignation, totalUnreadChat } = useAuth()
    const [signOut, setSignOut] = React.useState(false)

    const handleSignOut = () => {
        setSignOut(false)
        sessionStorage.clear()
        window.location.href = '/'
    }

    const name = sessionStorage.getItem('name')

    const dAarray = []

    var designations = sessionStorage.getItem("designations");
    designations.split(',').forEach(element => {
        dAarray.push({ designation: element })
    });

    function toggle_full_screen()
    {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen))
        {
            if (document.documentElement.requestFullScreen){
                document.documentElement.requestFullScreen();
            }
            else if (document.documentElement.mozRequestFullScreen){ /* Firefox */
                document.documentElement.mozRequestFullScreen();
            }
            else if (document.documentElement.webkitRequestFullScreen){   /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                // console.log("hi");
            }
            else if (document.msRequestFullscreen){ /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        }
        else
        {
            if (document.cancelFullScreen){
                document.cancelFullScreen();
            }
            else if (document.mozCancelFullScreen){ /* Firefox */
                document.mozCancelFullScreen();
            }
            else if (document.webkitCancelFullScreen){   /* Chrome, Safari and Opera */
                document.webkitCancelFullScreen();
            }
            else if (document.msExitFullscreen){ /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    }

    const navigateToPage = (url) => {
        window.location.href = url;
    };
    // console.log('dAarray', dAarray);

    return (
        <React.Fragment>
            <div className={styles.header}>
                <img src="/assets/wallicon.svg"
                    className={styles.wallIcon_image}
                    alt="wallIcon"
                    onClick={() => navigate('/dashboard')}
                />
                <div className={styles.headerItems_div}>
                    {dAarray.length > 1 &&
                        <div className={styles.header_item}>
                            <div className={styles.dealer_header_select_days}>
                                {dAarray.map((item, index) =>
                                    <div
                                        key={index}
                                        className={`${styles.dealer_days_select} ${designation === item.designation.trim() && styles.days_active}`}
                                        onClick={() => {
                                            sessionStorage.setItem("designation", item.designation.trim())
                                            setDesignation(item.designation.trim());
                                            navigate('/dashboard')
                                        }}
                                    >
                                        {item.designation}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    <div className={styles.header_item} onClick={() => navigate("/whatsappchats")}>
                        {totalUnreadChat !== 0 && <div className={styles.totalUnreadChat}>{totalUnreadChat}</div>}
                        <WhatsAppHeader color="#ffffff" />
                    </div>
                    <div className={styles.header_item}>
                        {/* <div className={styles.toggleBtn}>
                            <label className={styles.toggleBtn_active}>
                                <ThemeChange color="black" />
                                <input  type="switch" />                                
                            </label>
                        </div> */}
                        <label className={styles.switch}>
                            <input
                                type="checkbox"
                                checked={!switchDisplay && true}
                                onChange={(e) => {
                                    setSwitchDisplay(!switchDisplay)
                                }}
                            />
                            <span className={`${styles.slider} ${styles.round}`}></span>
                        </label>
                    </div>
                    <div className={styles.header_item} onClick={() => navigateToPage('/orders/saleorder')}>
                        <Invoice color="#ffffff" />
                    </div>
                    <div className={styles.header_item}>
                        <BellIcon color="#ffffff" />
                    </div>
                    <div className={styles.header_item} onClick={()=>{toggle_full_screen()}}>
                        <FullScreen color="#ffffff" />
                    </div>
                    <div className={styles.header_item} onMouseLeave={() => setSignOut(false)} onClick={() => setSignOut(true)}>
                        <User color="#ffffff" />
                        <br />
                        {name}
                        {signOut &&
                            <div className={styles.logout} onClick={() => handleSignOut()}>
                                Logout
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Headers