import { BusIcon, CourierAirIcon, CurrierSurfaceIcon, ErickshawIcon, PickUpIcon, RapidoIcon, TransportIcon } from '../../components/icons/Icon';
import styles from '../../pages/packingmanager/Dishpatchboy.module.css'
import React from 'react'

export class PackingSlip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            receipts: ''
        };
    }

    componentDidMount() {
        // console.log(this.props)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": this.props.managerId,
            "orderId": this.props.orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "packing/packingSlip", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    this.setState({ data: result.data })
                    this.setState({ receipts: result.receipts })
                }
            })
            .catch(error => console.log('error', error));
    }

    render() {
        const data = this.state.data;
        const receipts = this.state.receipts;
        const itemsLength = data.items && data.items.length / 14
        const isInt = Number(itemsLength) === itemsLength && itemsLength % 1 === 0
        const countLenth = isInt ? itemsLength : parseInt(itemsLength) + 1
        const noPrint = [];
        for (let i = 0; i < countLenth; i++) {
            noPrint.push(i + 1);
        }
        const itemQty = data.items && data.items.reduce((accumulator, object) => {
            return accumulator + parseFloat(object.qty);
        }, 0);

        const filterItem = data.items && data.items.filter(item => (item.unit || item.qty > 0 || item.desc));

        let paidAmountSum = receipts && receipts.reduce(function (prev, current) {
            return prev + (((current.status === 'Received') || (current.status === 'Pending For Verification')) ? +current.paymentAmount : 0)
        }, 0);

        let methodLength = receipts && receipts.map((val) => {
            return (val.status === 'Received' || val.status === 'Pending For Verification') ? val.paymentMethod : ""
        })

        // let cleanArray = methodLength && methodLength.filter((str) => str !== '');
        let cleanArray = methodLength && methodLength.filter((item, index) => (methodLength.indexOf(item) === index) && (item !== ''));

        return (
            <div>
                {noPrint && noPrint.map((obj, idx) =>
                    <div key={idx} style={{ background: "#fff", minWidth: "100%", position: "relative", padding: "25px 20px" }}>
                        <h1 style={{ fontSize: '16px', minWidth: "100%", textAlign: 'center', textDecoration: 'underline', marginBottom: '10px' }}>PACKING SLIP</h1>
                        <div style={{ fontSize: '14px' }}>
                            {noPrint.length > 1 && <div style={{ float: "right" }}>Page {idx + 1 + "/" + noPrint.length}</div>}
                        </div>
                        <div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{
                                width: '430px', marginRight: '108px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '110px', color: "#00000045", fontWeight: 'bold', fontSize: "32px", height: 'calc(100vh - 272px)',
                                // rotate: '-42deg' 
                            }}>
                                {data.toPay ? <span style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>{
                                    data.methodName && (data.methodName.toLowerCase().includes('bus')) ? <BusIcon /> :
                                        data.methodName && (data.methodName.toLowerCase().includes('air')) ? <CourierAirIcon /> :
                                            data.methodName && (data.methodName.toLowerCase().includes('surface')) ? <CurrierSurfaceIcon /> :
                                                data.methodName && (data.methodName.toLowerCase().includes('tata ace')) ? <TransportIcon /> :
                                                    data.methodName && (data.methodName.toLowerCase().includes('rapido')) ? <RapidoIcon /> :
                                                        data.methodName && (data.methodName.toLowerCase().includes('ricksha')) ? <ErickshawIcon /> :
                                                            <PickUpIcon />

                                }<span style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', lineHeight: '36px' }}><span>Freight To Pay</span><span>&nbsp;{data.paymentStatus}</span></span> </span> :
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>
                                            {
                                                data.methodName && (data.methodName.toLowerCase().includes('bus')) ? <BusIcon /> :
                                                    data.methodName && (data.methodName.toLowerCase().includes('air')) ? <CourierAirIcon /> :
                                                        data.methodName && (data.methodName.toLowerCase().includes('surface')) ? <CurrierSurfaceIcon /> :
                                                            data.methodName && (data.methodName.toLowerCase().includes('tata ace')) ? <TransportIcon /> :
                                                                data.methodName && (data.methodName.toLowerCase().includes('rapido')) ? <RapidoIcon /> :
                                                                    data.methodName && (data.methodName.toLowerCase().includes('ricksha')) ? <ErickshawIcon /> :
                                                                        <PickUpIcon />

                                            }
                                        </span>
                                        <span style={{ textAlign: 'center' }}>{data.paymentStatus && data.paymentStatus}</span>
                                    </div>
                                }

                            </div>
                        </div>
                        <table style={{ border: "1px solid", borderBottom: "none", borderCollapse: "collapse", fontSize: '13px', lineHeight: '20px', width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td rowSpan={5} colSpan={6} style={{ border: '1px solid', padding: '6px 10px 50px 10px', borderBottom: "none", width: "50%", height: '100%' }}>
                                        <div><b>{data.storeName && data.storeName}</b></div>
                                        <div> {data.address && data.address.street} {data.address && data.address.city} {data.address && data.address.district} {data.address && data.address.pincode}</div>
                                        <div className='text-uppercase'>GSTUIN: <b>{data.gstuin && data.gstuin}</b></div>
                                        <div>State: {data.state && data.state}</div>
                                        <div>Contact: {data.mobile && data.mobile}</div>
                                        <div>Email: {data.email && data.email}</div>
                                    </td>
                                    <td style={{ border: '1px solid', fontSize: '11px', padding: '2px 5px' }}>
                                        <div>Order Id</div>
                                        <div><b>{data.orderId}</b></div>
                                    </td>
                                    <td style={{ border: '1px solid', fontSize: '11px', padding: '2px 5px' }}>
                                        <div>Dated</div>
                                        <div><b>{data.orderDate}</b></div>
                                    </td>
                                </tr>
                                <tr style={{ height: '', fontSize: '11px' }}>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div>Delivery Method:</div>
                                        <div><b>{data.courierName}</b></div>
                                    </td>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div>Delivery Partner:</div>
                                        <div><b>{data.partnerName}</b></div>
                                    </td>
                                </tr>
                                <tr style={{ height: '  ', fontSize: '11px' }}>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div><b>Total Amount:</b></div>
                                    </td>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div><b>{data.totalAmount}</b></div>
                                    </td>
                                </tr>
                                {(paidAmountSum > 0) && <tr style={{ height: '  ', fontSize: '11px' }}>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div>Payment Method:</div>
                                        <div>{(cleanArray && cleanArray.length !== 0) && cleanArray.map((val, i) => <b key={i}>{`${val} ${cleanArray.length - 1 === i ? '' : '+ '}`}</b>)}</div>
                                    </td>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div>Paid Amount:</div>
                                        <div><b>{paidAmountSum}</b></div>
                                    </td>
                                </tr>}
                                {data.paymentMethod && (data.paymentMethod === "POD") && <tr style={{ height: '  ', fontSize: '11px' }}>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div><b>{data.paymentMethod}</b></div>
                                        {/* <div><b>{dat    a.paymentAmount}</b></div> */}
                                    </td>
                                    <td style={{ border: '1px solid', borderBottom: "none", padding: '2px 5px' }}>
                                        <div>Pending Amount:</div>
                                        <div><b>{data.paymentAmount}</b></div>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>

                        <table style={{ borderCollapse: 'collapse', width: "100%", height: "340px", }}>
                            <thead>
                                <tr style={{ fontSize: "14px", textAlign: "center", }}>
                                    <td style={{ border: '1px solid', width: "4%" }}>Sl.No.</td>
                                    <td style={{ border: '1px solid', width: "55%" }}>Description of Items</td>
                                    <td style={{ border: '1px solid', width: "11%" }}>Quantity</td>
                                </tr>
                            </thead>
                            <tbody>
                                {filterItem && filterItem.slice(idx * 14, 14 * obj).map((item, index) =>
                                    <tr key={index} style={{ fontSize: "14px", fontWeight: "600", color: "#000000", height: '6px', borderBottom: 'none' }}>
                                        <td style={{ textAlign: 'center', borderLeft: '1px solid', borderBottom: 'none' }}>{(idx * 14) + (1 + index)}</td>
                                        <td style={{ textAlign: 'left', borderLeft: '1px solid', borderBottom: 'none', fontWeight: "700" }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <span >{item.itemNo}</span>
                                                <span >{item.desc}</span>
                                                {/* <div style={{ margin: "0 20px", minWidth: "40%" }}>{item.product_name}</div>
                                                <span >{item.batch}</span> */}
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center', borderLeft: '1px solid', borderRight: '1px solid', borderBottom: 'none', fontWeight: "700" }}>{(item.qty === 0 && item.unit) ? '-' : <>{item.qty} {item.unit}</>}</td>
                                    </tr>
                                )}
                                {idx === countLenth - 1 &&
                                    <tr style={{ borderBottom: 'none', borderLeft: '1px solid', borderRight: '1px solid', }}>
                                        <th style={{ paddingTop: '' }}></th>
                                        <th style={{ borderBottom: 'none', borderLeft: '1px solid', borderRight: '1px solid' }}></th>
                                        <th style={{ borderBottom: 'none', borderLeft: '1px solid', borderRight: '1px solid' }}></th>
                                    </tr>
                                }
                                {idx === countLenth - 1 &&
                                    <tr style={{ fontSize: "10px", fontWeight: "500", color: "#000000", margin: "0px", height: '6px' }}>
                                        <td style={{ border: '1px solid' }}></td>
                                        <td style={{ border: '1px solid', textAlign: 'right', fontSize: "14px", fontWeight: "600" }}><b>Total&nbsp;</b></td>
                                        <td style={{ border: '1px solid', fontSize: "14px", textAlign: 'center', }}>{itemQty && itemQty}</td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                        <table style={{ lineHeight: '13px', border: "1px solid", borderTop: "none", borderCollapse: "collapse", fontSize: '12px', width: "100%" }}>
                            {idx !== countLenth - 1 ?
                                <thead>
                                    <tr style={{ borderTop: "1px solid", height: '160px', textAlign: "right", verticalAlign: "text-top", }}>
                                        <td style={{ paddingTop: "20px", paddingRight: "10px" }}>
                                            continued...
                                        </td>
                                    </tr>
                                </thead>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan="12" rowSpan="3" className={styles.slipmain_div}>
                                            <div className={styles.slipflexdiv}>
                                                <p className={styles.slipheading_div}>Total Number of Packages:</p>
                                                <input
                                                    type="sliptext"
                                                    className={styles.slip_input}
                                                />
                                            </div>
                                            <div className={styles.slipflexdiv}>
                                                <p className={styles.slipheading_div}>Packed By:</p>
                                                <input
                                                    type="sliptext"
                                                    className={styles.slip_input}
                                                />
                                                <p className={styles.slipheading_div}>Checked By:</p>
                                                <input
                                                    type="sliptext"
                                                    className={styles.slip_input}
                                                />
                                            </div>
                                            <div className={styles.slipflexdiv}>
                                                <p className={styles.slipheading_div}>Comment (if any)</p><input
                                                    className={styles.slip_inputsecond}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                        {/* <div style={{ fontSize: '12px', textAlign: "center" }}>THIS IS A COMPUTER GENERATED SALE ORDER.</div> */}
                    </div>
                )}
            </div>
        )
    }
}