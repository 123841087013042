import React, { useState } from 'react'
import { GreenOutButton, RedOutButton } from '../../components/Buttons';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';
import styles from './DishpatchManager.module.css'
const CreatePackers = () => {
    // const navigate = useNavigate();
    // 
    const [selectedProducts, setSelectedProducts] = useState([]);
    const { switchDisplay } = useAuth();
    const data = [
        {
            product_name: "Wallpaper"
        },
        {
            product_name: "Pvc"
        },
        {
            product_name: "Grass"
        },
        {
            product_name: "Flooring"
        },
        {
            product_name: "Ceiling"
        },
        {
            product_name: "Grass Film"
        },
        {
            product_name: "Blind"
        }
    ]

    return (
        <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark}`}>
            <h1 className={styles.mainHeading}>Packers</h1>
            <div className={styles.createPacker_container}>
                <h2 className={styles.packersheading}> Create New Packers</h2>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Name</div>
                            <input
                                type="text"
                                placeholder='Enter your Name'
                                className={`${switchDisplay ? styles.createinput : styles.createinputdark}`} />
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Mobile Number</div>
                            <input
                                type="text"
                                placeholder='Enter Your Mobile Number'
                                className={`${switchDisplay ? styles.createinput : styles.createinputdark}`} />
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Login Id</div>
                            <input
                                type="text"
                                placeholder='Enter Your Login ID'
                                className={`${switchDisplay ? styles.createinput : styles.createinputdark}`}
                            />
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Password</div>
                            <input
                                type="text"
                                placeholder='Enter Your Password'
                                className={`${switchDisplay ? styles.createinput : styles.createinputdark}`} />
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}> Confirm Password</div>
                            <input
                                type="text"
                                placeholder='Enter Your Confirm Password'
                                className={`${switchDisplay ? styles.createinput : styles.createinputdark}`} />
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Status</div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtn}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Enable</label>
                            </div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtnred}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Disable</label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>Cart</div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtn}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Enable</label>
                            </div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtnred}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Disable</label>
                            </div>
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.packertext}>P-Cart</div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtn}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Enable</label>
                            </div>
                            <div className={styles.col +" "+ styles.radiobtn_div}>
                                <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="CSS"
                                    className={styles.radiobtnred}
                                />
                                &nbsp;&nbsp;
                                <label className={styles.radioBtn_heading}>Disable</label>
                            </div>
                        </div>
                        <div className={styles.margin}>
                            <p className={styles.chooseProduct_heading}>Choose Products they want to pack</p>
                            <div className={styles.box}>
                                {
                                    data.map((item, index) =>
                                        <span
                                            key={index}
                                            className={selectedProducts.find(val => val.product_name === item.product_name) ? styles.itemInputs_selected : styles.itemInputs}
                                            onClick={() => {
                                                if (selectedProducts.filter((val) => val.product_name === item.product_name).length === 0) {
                                                    setSelectedProducts([...selectedProducts, { product_name: item.product_name, product_id: item.product_id }])
                                                } else {
                                                    let arr = selectedProducts;
                                                    let arr1 = arr.filter((val) => val.product_name !== item.product_name);
                                                    setSelectedProducts(arr1);
                                                }
                                            }}
                                        >
                                            {item.product_name}
                                        </span>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.createbtn}>
                    <RedOutButton title={"CANCEL"} />
                    <GreenOutButton title={"CREATE"} />
                </div>

            </div>
        </div>
    )
}
export default CreatePackers;