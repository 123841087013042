import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth'
import { DeleteIcon, DownArrowCircle, DownloadIcon, ReloadIcon, Search, Spinner, ThreeDots, UpArrowCircle } from '../../components/icons/Icon'
import styles from "../../pages/dashboard/Admin.module.css"
import Paginantion from '../../components/Paginantion'
import Modal from '../../components/Modal'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { FileHandler } from '../../components/DragAndDrop'
// import AvatarEditor from 'react-avatar-editor'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Recipts = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
    const { managerId, designation } = useAuth();
    const [pages, setPages] = useState(1)
    const [data, setData] = useState([]);
    const [receiptState, setReceiptState] = useState([]);
    const [daysTab, setDaysTab] = useState('today')
    const [shipingTab, setShipingTab] = useState('all')
    const [cartTab, setCartTab] = useState('all')
    const [keyword, setKeyword] = useState('')
    const [sortKey, setSortKey] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingReceipt, setLoadingReceipt] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [showReciptModal, setShowReciptModal] = useState(false)
    const [showRevertModal, setShowRevertModal] = useState(false)
    const [cancelUpdateRefund, setCancelUpdateRefund] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [managers, setManagers] = useState([])
    const [managerName, setManagerName] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const [isImage, setisImage] = useState();
    const [accounts, setAccounts] = useState([])
    const [reload, setReload] = useState(false)
    const [reciptData, setReciptData] = useState({
        paymentMethod: '',
        paymentType: '',
        paymentAmount: '',
        transactionId: '',
        accountNumber: '',
    })
    const [refundData, setRefundData] = useState({
        refundAmt: '',
        refundMethod: '',
        reason: '',
    })
    // console.log(reciptData);
    // ---------------------------------modal----------------------------------
    // const [showModal, setShowModal] = useState(false)
    // const [inputImg, setInputImg] = useState('')
    // const [ImgData, setImgData] = useState()
    const [receiptVerify, setReceiptVerify] = useState(false)
    const [error, setError] = useState({
        receiptImage: '',
    })

    const [revertbackReason, setRevertBackReason] = useState('')
    // const [progressPercent, setProgressPercent] = useState(false)

    // const onInputChange = (e) => {
    //     let url = URL.createObjectURL(e.target.files[0]);
    //     setInputImg(url)
    //     setShowModal(true)
    // }
    // useEffect(() => {
    //     if (isImage) {
    //         setInputImg(isImage[0])
    //         setShowModal(true)
    //     }
    // }, [isImage])

    // var editor = "";
    // const [picture, setPicture] = useState({
    //     cropperOpen: false,
    //     img: null,
    //     zoom: 1,
    //     croppedImg: "",
    //     position: {
    //         x: 0,
    //         y: 0,
    //     },
    // });

    // const handleScale = e => {
    //     const scale = parseFloat(e.target.value)
    //     setPicture({
    //         ...picture,
    //         zoom: scale
    //     });
    // }
    // const handlePositionChange = position => {
    //     setPicture({
    //         ...picture,
    //         position
    //     });
    // }

    // const setEditorRef = (ed) => {
    //     editor = ed;
    // };

    // const handleSave = (e) => {
    //     if (setEditorRef) {
    //         const canvasScaled = editor.getImageScaledToCanvas();
    //         canvasScaled.toBlob((blob) => {
    //             setImgData(blob)
    //             uploadProfile(blob)
    //         });
    //     }
    // };
    // const [msg, setMsg] = useState("")



    // const uploadProfile = async (blob) => {

    //     // setProgressPercent(true);

    //     // setWebsiteData(prevState => ({ ...prevState, profileImage: "" }))

    //     var myHeaders = new Headers();
    //     myHeaders.append("wd-platform", process.env.REACT_APP_ADMIN_SECRET);
    //     myHeaders.append("x-access-token",);

    //     var formdata = new FormData();
    //     formdata.append("profileImage", blob);

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: formdata,
    //         redirect: 'follow'
    //     };

    //     fetch(process.env.REACT_APP_URL + "uploadprofile/" + requestOptions)
    //         // .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "success") {
    //                 // setWebsiteData(prevState => ({ ...prevState, profileImage: result.profileImage }))
    //                 setInputImg("");
    //                 // setProgressPercent(false);
    //                 setShowModal(false)
    //             } else {

    //                 setMsg(result.msg);
    //             }
    //         })
    //         .catch(error => console.log('error', error));
    // }

    // -----------------------------------------------------------------------

    useEffect(() => {
        if (showOverlay) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'

        }
    }, [showOverlay])
    // useEffect(()=>{
    //     if(showOverlay === false) {
    //         setRefundData("")
    //     }
    // }, [showOverlay])

    useEffect(() => {
        if (showReciptModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
            setShowReciptModal(false);
            setShowWarningModal(false);
            setCancelUpdateRefund('')
        }
    }, [showReciptModal])

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                // "managerId": 6,
                "keyword": keyword,
                "day": daysTab,
                "cartType": cartTab,
                "endDate": endDate,
                "orderType": shipingTab,
                "ordermanagerId": managerName,
                "sortKey": sortKey,
                "startDate": startDate,
                "status": orderStatus,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "receipt/receipts?page=" + page + '&limit=8', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setLoading(false)
                        setData(result.data)
                        setReceiptState(result.receipts)
                        setPages(result.pages)
                        if (result.managers.orderManagers
                        ) {
                            setManagers(result.managers.orderManagers
                            )
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [page, managerId, keyword, cartTab, daysTab, startDate, endDate, shipingTab, managerName, orderStatus, sortKey, receiptVerify, reload])

    const handleReceipt = (receiptId, orderId) => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "receiptId": receiptId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/receipt", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowReciptModal(true)
                    setReciptData(result.data)
                    setAccounts(result.accounts)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleSubmitRecipt = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (((reciptData.paymentMethod === "Photo") || (reciptData.paymentType === "Photo")) && (!reciptData.transactionScreenshot && !isImage)) {
            setError({ ...error, receiptImage: "Please upload Receipt image" })
            alert("Please upload Receipt image")
        }
        else if (form.checkValidity() === false) {
            // if(!isImage){
            //     setError({ ...error, receiptImage: "Please upload Receipt image" })
            // }
            event.preventDefault();
            event.stopPropagation();
        }

        else {
            handleVerifyReceipt()
            setError({ ...error, receiptImage: "" })
        }
    };

    const handleVerifyReceipt = (receiptId, orderId) => {
        setReceiptVerify(false)
        setLoadingReceipt(true)
        // var myHeaders = new Headers();
        // myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        // myHeaders.append("Content-Type", "application/json");

        // var raw = JSON.stringify({
        //     "managerId": managerId,
        //     "receiptId": receiptId,
        //     "orderId": orderId
        // });

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: raw,
        //     redirect: 'follow'
        // };

        // fetch(process.env.REACT_APP_URL + "receipt/verifyreceipt", requestOptions)
        //     .then(response => response.json())
        //     .then(result => {
        //         if (result.status === 'success') {
        //             setShowReciptModal(false)
        //             // alert(result.msg)
        //             setReceiptVerify(true)
        //         } else {
        //             alert(result.msg)
        //         }
        //     })
        //     .catch(error => console.log('error', error));
        setReload(false)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("managerId", managerId);
        formdata.append("receiptId", reciptData.receiptId);
        formdata.append("orderId", reciptData.orderId);
        formdata.append("paymentMethod", reciptData.paymentMethod);
        formdata.append("paymentType", reciptData.paymentType);
        formdata.append("accountNumber", reciptData.accountNumber ? reciptData.accountNumber : '');
        formdata.append("transactionScreenshot", isImage ? [...isImage][0] : '');
        formdata.append("transactionId", reciptData.transactionId);
        formdata.append("paymentAmount", reciptData.paymentAmount);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/verifyreceipt", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowReciptModal(false)
                    setReload(true)
                }
                else {
                    alert(result.msg)
                    setShowReciptModal(false)
                }

            })
            .catch(error => console.log('error', error))
            .finally(() => setLoadingReceipt(false))
    }

    const handleRefundReceipt = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        var formdata = new FormData();
        formdata.append("managerId", managerId);
        formdata.append("receiptId", refundData.receiptId);
        formdata.append("orderId", refundData.orderId);
        formdata.append("refundAmt", refundData.refundAmt);
        formdata.append("refundMethod", refundData.refundMethod);
        // formdata.append("refundByAccount", "");
        // formdata.append("refundToAccount", "");
        // formdata.append("refundTransactionId", "");
        // formdata.append("transactionScreenshot", "");
        formdata.append("reason", refundData.reason);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/refundreceipt", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowWarningModal(false)
                    // alert(result.msg)
                }
                else {
                    alert(result.msg)
                    setShowWarningModal(false)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleCancelReceipt = () => {
        setReload(false)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "receiptId": refundData.receiptId,
            "orderId": refundData.orderId,
            "reason": refundData.reason,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/cancelreceipt", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowWarningModal(false)
                    setReload(true)
                    // alert(result.msg)
                }
                else {
                    alert(result.msg)
                    setShowWarningModal(false)
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitRevertback = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            handleRevertback()
        }
    };


    const handleRevertback = () => {

        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": parseInt(managerId),
            "orderId": reciptData.orderId,
            "receiptId": reciptData.receiptId,
            "description": revertbackReason
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/revertReceipt", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    alert(result.msg)
                    setShowRevertModal(false)
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>

            <Modal
                show={showRevertModal}
                close={setShowRevertModal}
                closeBtn={true}
                heading="Warning"
                content={
                    <form onSubmit={submitRevertback}>
                        <div className={styles.text_center}>
                            <div className={`${styles.reciptHeader_div} pe-4 me-2`} >
                                <h2 className={styles.reciptHeading}>{''}</h2>
                                <div className='d-flex flex-column w-100'>
                                    <input
                                        type='text'
                                        placeholder='Write Revert back Reason'
                                        className={styles.warningInput + ' mx-auto mb-4'}
                                        value={revertbackReason}
                                        onChange={(e) => {
                                            setRevertBackReason(e.target.value)
                                        }}
                                        required
                                    />

                                    <div className={styles.method + " mx-auto"}>
                                        Are You Sure Want To Revert back This Receipt
                                    </div>

                                    <div className={styles.warnigModal_btn}>
                                        <GreenOutButton btnType='button' title="NO" handleSubmit={() => setShowRevertModal(false)} />
                                        <GreenButton btnType='submit' title="YES" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            />



            <Modal
                show={showWarningModal}
                close={setShowWarningModal}
                closeBtn={true}
                heading="Warning"
                content={
                    <div className={styles.text_center}>
                        <div className={`${styles.reciptHeader_div} pe-4 me-2`} >
                            <h2 className={styles.reciptHeading}>{''}</h2>

                            <div className={styles.recipt_tableDiv}>
                                <div className={styles.recipt_table_cell}>
                                    <span>Date</span>
                                    <span><b>{refundData.receiptDate}</b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Recipt Id</span>
                                    <span><b>{refundData.receiptId}</b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Reciver</span>
                                    <span><b></b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Order Id</span>
                                    <span><b>{refundData.orderId}</b></span>
                                </div>
                            </div>

                        </div>
                        {cancelUpdateRefund === 'Cancel' &&
                            <input
                                type='text'
                                placeholder='Write Cancel Reason'
                                className={styles.warningInput}
                                value={refundData.reason}
                                onChange={(e) => {
                                    setRefundData(prevState => ({
                                        ...prevState, reason: e.target.value
                                    }))
                                }}
                            />
                        }
                        {cancelUpdateRefund === 'Refund' &&
                            <div>

                                <div className={`${'d-flex'} ${styles.refund_width}`}>
                                    <div className='w-100 me-1'>
                                        <span className={styles.method}>Method</span>
                                        <select
                                            value={refundData.refundMethod}
                                            onChange={(e) => {
                                                setRefundData(prevState => ({
                                                    ...prevState, refundMethod: e.target.value
                                                }))
                                            }}
                                            className={styles.methodSelect}

                                        >
                                            <option value='' hidden>Select</option>
                                            <option value='Photo'>Photo</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Online'>Online</option>
                                            <option value='POD'>Pay On Delivery</option>
                                        </select>
                                    </div>

                                    <div className='w-100 ms-1'>
                                        <span className={styles.method}>Payment Amount</span>
                                        <input
                                            type='number'
                                            placeholder='Enter Amount'
                                            className={styles.amount_input}
                                            value={refundData.paymentAmount}
                                            disabled
                                            readOnly
                                        //    onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentAmount: e.target.value }))} 
                                        />
                                    </div>
                                </div>



                                <input
                                    type='text'
                                    placeholder='Write Refund Reason'
                                    className={styles.warningInput}
                                    value={refundData.reason}
                                    onChange={(e) => {
                                        setRefundData(prevState => ({
                                            ...prevState, reason: e.target.value
                                        }))
                                    }}
                                />
                                <input
                                    type='number'
                                    placeholder='Enter Full Amount (in Rs)'
                                    className={styles.warningInput}
                                    value={refundData.refundAmt}
                                    onChange={(e) => {
                                        setRefundData(prevState => ({
                                            ...prevState, refundAmt: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                        }
                        <p className={styles.message}>Are You Sure Want To&nbsp;
                            {cancelUpdateRefund === 'Cancel' ? 'Cancel Recipt' :
                                cancelUpdateRefund === 'Update' ? 'Update Recipt' :
                                    cancelUpdateRefund === 'Refund' ? 'Refund Amount' :
                                        ''
                            }
                        </p>
                        <div className={styles.warnigModal_btn}>
                            {
                                cancelUpdateRefund === 'Cancel' ? <GreenButton title="YES" handleSubmit={() => { handleCancelReceipt() }} /> :
                                    cancelUpdateRefund === 'Update' ? <GreenButton title="YES" handleSubmit={() => { setShowReciptModal(true); setShowWarningModal(false) }} /> :
                                        cancelUpdateRefund === 'Refund' ? <GreenButton title="YES" handleSubmit={() => { handleRefundReceipt() }} /> :
                                            ''
                            }
                            <GreenOutButton title="NO" handleSubmit={() => setShowWarningModal(false)} />
                        </div>
                    </div>
                }
            />

            {/* <Modal
                show={showModal}
                close={setShowModal}
                // closeBtn={true}
                heading=" "
                content={
                    <div className={styles.text_center}>
                        <AvatarEditor
                            ref={setEditorRef}
                            image={inputImg}
                            width={500}
                            height={800}
                            rotate={0}
                            scale={picture.zoom}
                            onPositionChange={handlePositionChange}
                            style={{ width: "100%", height: "100%"}}
                        />
                        <div className={styles.inputRange}>
                        
                        </div>
                        <div className={styles.inputRange}>
                            <input type="range" min="1" max="10" step="0.1" value={picture.zoom} onChange={handleScale} />
                        </div>
                        <div className={styles.recipt_btn}>


                            <GreenOutButton title="CANCEL" handleSubmit={() => setShowModal(false)} />
                            <GreenButton title="SUBMIT" btnType="submit" handleSubmit={() => { handleSave(); setShowModal(false) }} />
        
                        </div>
                    </div>
                }
            /> */}

            {showReciptModal &&
                <div className={styles.reciptModal} onClick={() => { setShowReciptModal(); }}>
                    <div className={styles.reciptModal_content} onClick={e => { e.stopPropagation(); }}>

                        <form onSubmit={handleSubmitRecipt}>
                            <div className={styles.reciptHeader_div} >
                                <h2 className={styles.reciptHeading}>Receipt</h2>
                                {reciptData.paymentMethod === 'POD' &&
                                    <div className={styles.recipt_tableDiv}>
                                        <div className={styles.recipt_table_cell}><span>Date</span><span><b>{reciptData.receiptDate}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Recipt Id</span><span><b>{reciptData.receiptId}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Reciver</span><span><b></b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Order Id</span><span><b>{reciptData.orderId}</b></span></div>
                                    </div>
                                }
                            </div>
                            <div className={styles.reciptContentDiv}>

                                <div className='d-flex'>
                                    <div className='w-100 me-1'>
                                        <span className={styles.method}>Method</span>
                                        <select
                                            value={reciptData.paymentMethod}
                                            onChange={(e) => {
                                                setReciptData('');
                                                setisImage('');
                                                setReciptData(prevState => ({
                                                    ...prevState, paymentMethod: e.target.value
                                                }))
                                            }}
                                            className={styles.methodSelect}
                                            required
                                        >
                                            <option value='' hidden>Select</option>
                                            {reciptData.paymentMethod === 'Online' ?
                                                <option value='Online'>Online</option> :
                                                <>
                                                    <option value='Photo'>Photo</option>
                                                    <option value='Cash'>Cash</option>
                                                    <option value='POD'>Pay On Delivery</option>
                                                </>}
                                        </select>
                                    </div>

                                    <div className='w-100 ms-1'>
                                        <span className={styles.method}>Total Amount</span>
                                        <input
                                            type='number'
                                            placeholder='Enter Amount'
                                            className={styles.amount_input}
                                            value={reciptData.totalAmount}
                                            disabled
                                            readOnly
                                        //    onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentAmount: e.target.value }))} 
                                        />
                                    </div>
                                </div>

                                <div className='d-flex mt-3'>
                                    {
                                        reciptData.paymentMethod === 'POD' &&
                                        <div className='w-100 me-1'>
                                            <span className={styles.submethod_txt}>Sub Method</span>
                                            <select
                                                value={reciptData.paymentType}
                                                onChange={(e) => setReciptData(prevState => ({
                                                    ...prevState, paymentType: e.target.value
                                                }))}
                                                className={styles.reciptMethod_select}
                                                required
                                            >
                                                <option value='' >Select</option>
                                                <option value='Cash'>Cash</option>
                                                {/* <option value='PayNow'>Pay Now</option> */}
                                                <option value='Photo'>Photo</option>
                                            </select>
                                        </div>
                                    }

                                    {reciptData.paymentMethod === 'Cash' || reciptData.paymentType === 'Cash' ? '' :
                                        <div className='w-100 ms-1'>
                                            <span className={styles.submethod_txt}>Account</span>
                                            <select
                                                value={reciptData.accountNumber}
                                                onChange={(e) => setReciptData(prevState => ({
                                                    ...prevState, accountNumber: e.target.value
                                                }))}
                                                className={styles.reciptMethod_select}
                                                required
                                            >
                                                <option value='' hidden>Select</option>
                                                {accounts && accounts.map((item, index) =>
                                                    <option key={index} value={item.accountNumber}>
                                                        {item.bankName + " " + item.accountNumber}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    }
                                </div>

                                {reciptData.paymentType !== 'Cash' && (reciptData.paymentMethod === 'Photo' || reciptData.paymentMethod === 'POD' || reciptData.paymentMethod === 'Online') &&
                                    <>
                                        {(isImage || reciptData.transactionScreenshot) &&
                                            <>
                                                <div className={styles.reciptImage_div}>
                                                    <div className={styles.Image_div}>
                                                        <img
                                                            src={isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot}
                                                            alt='Recipt'
                                                            onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}
                                                            className={styles.reciptImage}
                                                        />
                                                    </div>
                                                    {cancelUpdateRefund === 'Update' && <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}><DeleteIcon /></div>}
                                                    <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}><DeleteIcon />
                                                        {/* <span>Delete</span> */}
                                                    </div>
                                                </div>
                                                <p role='button' className={styles.viewRecipt} onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}>*Click To View Screenshot</p>
                                            </>
                                        }
                                        {(!isImage && !reciptData.transactionScreenshot) &&
                                            <>
                                                <div className={styles.dragImage_div}>
                                                    <FileHandler
                                                        setisImage={setisImage}
                                                        styles={styles}
                                                        error={error}
                                                    />
                                                </div>
                                            </>
                                        }
                                        <input
                                            type='text'
                                            placeholder='Ref / UTR Number / Transaction Id'
                                            className={styles.recipt_inputField_ref}
                                            value={reciptData.transactionId}
                                            onChange={(e) => setReciptData(prevState => ({
                                                ...prevState, transactionId: e.target.value
                                            }))}
                                            required
                                        />
                                    </>
                                }
                                <input

                                    type='number'
                                    placeholder='Enter Transcation Amount (in Rs)'
                                    className={styles.recipt_inputField}
                                    value={reciptData.paymentAmount}
                                    onChange={(e) => setReciptData(prevState => ({
                                        ...prevState,
                                        paymentAmount: e.target.value
                                    }))}
                                    required
                                />

                                <div className={styles.recipt_btn}>
                                    <GreenOutButton title="CANCEL" handleSubmit={() => { setShowReciptModal(false); setShowWarningModal(false); setCancelUpdateRefund('') }} />
                                    {((reciptData.status !== "Received") && (reciptData.status !== "Cancelled") && (reciptData.paymentMethod === 'Online')) ? <RedOutButton title='REVERT BACK' css={styles.cancel_receipt_btn} handleSubmit={() => { setShowRevertModal(true); setShowReciptModal(false) }} /> : ''}
                                    {cancelUpdateRefund === 'Update' ?
                                        <GreenButton title="UPDATE & VERIFY" handleSubmit={() => setShowReciptModal(false)} /> :
                                        <GreenButton
                                            title={reciptData.status === "Received" ? "VERIFIED" : loadingReceipt ? <Spinner size="15" /> : "VERIFY"} btnType='submit'
                                            disabled={(reciptData.status === "Pending" || reciptData.status === "POD Pending" || reciptData.status === "Pending For Refund" || reciptData.status === "Pending For Verification"|| reciptData.status==='Cash Pending at Partner') ? false : true}
                                        />
                                    }
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            }







            <div className={styles.right_main} >
                <div className={styles.dispatchDashboard_header}>
                    <h1 className={styles.dispatchHeading}>Recipts</h1>

                    <div className={styles.celender_div}>

                        <div className={styles.reload} onClick={() => window.location.reload()}>
                            <span><ReloadIcon /></span>
                            <span className={styles.reload_txt}>Reload</span>
                        </div>

                        <div className={styles.dashboard_header_select_week}>
                            <div className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => setDaysTab('today')}>Today</div>
                            <div className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => setDaysTab('yesterday')}>Yesterday</div>
                            <div className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => setDaysTab('month')}>This Month</div>
                        </div>

                        <div className={styles.startEndDate_div}>
                            <span>Start Date&nbsp;&nbsp;</span>
                            <input
                                className={styles.startEndDate}
                                type="date"
                                value={startDate}
                                onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
                            />
                        </div>
                        <div className={styles.startEndDate_div}>
                            <span>End Date&nbsp;&nbsp;</span>
                            <input
                                className={styles.startEndDate}
                                type="date"
                                value={endDate}
                                onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
                            />
                        </div>
                    </div>

                </div>

                <div className={styles.dashboard_main_container}>
                    <div className={styles.dashboard_main_div}>

                        <div className={styles.statusCard_div}>
                            {loading ?
                                <>
                                    {
                                        Array.from({ length: 8 }).map((_, idx) =>
                                            <div key={idx} className='skeleton'>
                                                <span className={styles.status_count_loading}>0</span>
                                                <span className={styles.statusTxt}>status</span>
                                            </div>
                                        )
                                    }
                                </>
                                :
                                <>
                                    {receiptState && receiptState.map((item, index) =>
                                        <div key={index} className={`${styles.statusCard} 
                                    ${orderStatus === item.status && item.status === 'Received' ? styles.Dispatches_active :
                                                orderStatus === item.status && item.status === 'Cancelled' ? styles.danger_active :
                                                    orderStatus === item.status && item.status.includes('Refund') ? styles.orange_active :
                                                        orderStatus === item.status && item.status === 'Failed' ? styles.Pending_active :
                                                            orderStatus === item.status && item.status.includes('Pending') ? styles.inProgress_active :
                                                                orderStatus === item.status && item.status === 'All' ? styles.packed_active :
                                                                    ''}`} onClick={() => {
                                                                        setOrderStatus(item.status);
                                                                        if (searchParams.has("page")) {
                                                                            const token = searchParams.get("page");
                                                                            if (token) {
                                                                                searchParams.delete("page");
                                                                                setSearchParams(searchParams);
                                                                            }
                                                                        }
                                                                    }}>
                                            <span className={
                                                item.status === 'Received' ? styles.statusGreen :
                                                    item.status.includes('Refund') ? styles.statusOrange :
                                                        item.status.includes('Pending') ? styles.statusYellow :
                                                            item.status === 'All' ? styles.statusBlue :
                                                                item.status === 'Cancelled' ? styles.statusRed :
                                                                    ''}>
                                                {item.count}
                                            </span>
                                            <span className={styles.statusTxt}>{item.status}</span>
                                        </div>
                                    )}
                                </>}
                        </div>

                        <div className={styles.dispatchDashboard_header}>
                            <div className={styles.dashboard_search_div}>
                                <input type="search"
                                    placeholder='Receipt / Order Id'
                                    className={styles.dashboard_search}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <Search color="#ffffff" css={styles.dashboard_search_icon} />
                            </div>

                            <div className={styles.recipt_tabs_div}>
                                <div className={`${styles.dashboard_days_select} ${cartTab === 'all' ? styles.days_active : ''}`} onClick={() => setCartTab('all')}>All</div>
                                <div className={`${styles.dashboard_days_select} ${cartTab === 'cart' ? styles.days_active : ''}`} onClick={() => setCartTab('cart')}>Cart</div>
                                <div className={`${styles.dashboard_days_select} ${cartTab === 'partnercart' ? styles.days_active : ''}`} onClick={() => setCartTab('partnercart')}>Partner Cart</div>
                            </div>

                            <div className={styles.recipt_tabs_div}>
                                <div className={`${styles.dashboard_days_select} ${shipingTab === 'all' ? styles.days_active : ''}`} onClick={() => setShipingTab('all')}>All</div>
                                <div className={`${styles.dashboard_days_select} ${shipingTab === 'paynow' ? styles.days_active : ''}`} onClick={() => setShipingTab('paynow')}>Pay Now</div>
                                <div className={`${styles.dashboard_days_select} ${shipingTab === 'photo' ? styles.days_active : ''}`} onClick={() => setShipingTab('photo')}>Photo</div>
                                <div className={`${styles.dashboard_days_select} ${shipingTab === 'cash' ? styles.days_active : ''}`} onClick={() => setShipingTab('cash')}>Cash</div>
                                <div className={`${styles.dashboard_days_select} ${shipingTab === 'cod' ? styles.days_active : ''}`} onClick={() => setShipingTab('cod')}>COD</div>
                            </div>

                            <div className={styles.dashboard_Sort_By}>
                                <label className={styles.dashboard_sortBy_txt}>Account</label>
                                <select
                                    className={styles.dashboard_sortBy_select}
                                    value={sortKey}
                                    onChange={(e) => setSortKey(e.target.value)}
                                >
                                    <option value="">View All</option>

                                </select>
                            </div>

                            {designation === 'superadmin' &&
                                <div className={styles.dashboard_Sort_By}>
                                    <label className={styles.dashboard_sortBy_txt}>Manager</label>

                                    <select
                                        className={styles.dashboard_sortBy_select}
                                        value={managerName}
                                        onChange={(e) => setManagerName(e.target.value)}
                                    >
                                        <option value='All'>View All</option>
                                        {
                                            managers && managers.map((item, index) =>
                                                <option key={index} value={item.managerId}>{item.status === 'active' ? <>&#128994;</> : item.status === 'disable' ? <>&#128308;</> : ''}&nbsp;{item.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            }

                            <div className={styles.dashboard_Sort_By}>
                                <label className={styles.dashboard_sortBy_txt}>Sort By </label>
                                <select
                                    className={styles.dashboard_sortBy_select}
                                    value={sortKey || orderStatus}
                                    onChange={(e) => {
                                        if (e.target.value === 'Ascending' || e.target.value === 'Descending' || e.target.value === 'lastAction') {
                                            setSortKey(e.target.value)
                                        } else {
                                            setOrderStatus(e.target.value)
                                            setSortKey("")
                                        }
                                    }}
                                >
                                    <option value="All">View All</option>
                                    <option value="Failed">Failed</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Received">Received</option>
                                    <option value="Ascending">Ascending</option>
                                    <option value="Descending">Descending</option>
                                    <option value="lastAction">Last Action</option>
                                </select>
                            </div>

                            <div className={styles.dashboard_Sort_By} >
                                <div role='button' className={styles.dashboard_sortBy_txt}>Download</div>
                                <div role='button'><DownloadIcon /></div>
                            </div>

                        </div>


                        {loading ? <div className={'order_loadingMain'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :
                            <>
                                {data.length !== 0 ?
                                    <div>
                                        <div className={styles.table_heading_div}>
                                            <span className={styles.table_heading_shot}>Recipt</span>
                                            <span className={styles.table_heading_shot}>Order Id</span>
                                            <span className={styles.table_heading_mid}>Date</span>
                                            <span className={styles.table_heading_shot}>Time</span>
                                            <span className={styles.table_heading}>Name & City</span>
                                            <span className={styles.table_heading_mid}>Dealer Id</span>
                                            <span className={styles.table_heading}>Order Manager</span>
                                            <span className={styles.table_heading}>Dispatch Status</span>
                                            <span className={styles.table_heading}>Delivery Partner</span>
                                            <span className={styles.table_heading_shot}>Amount</span>
                                            <span className={styles.table_heading_shot}>Method</span>
                                            <span className={styles.table_heading_mid}>Status</span>
                                            <span className={styles.table_heading}>Receiver</span>
                                            <span className={styles.table_heading_shot}>Time</span>
                                            <span className={styles.table_heading_shot}></span>
                                        </div>

                                        <div className={styles.recipt_scrollTable}>
                                            {
                                                data && data.map((val, index) =>
                                                    <div key={index} className={styles.table_content_div} onClick={() => {
                                                        if (val.status === 'Pending For Refund') {
                                                            setShowOverlay(false);
                                                            setCancelUpdateRefund('Refund');
                                                            setShowWarningModal(true);
                                                            // setRefundData(prevState => ({
                                                            //     ...prevState,
                                                            //     receiptId: val.receiptId,
                                                            //     orderId: val.orderId,
                                                            //     paymentAmount: val.paymentAmount,
                                                            //     totalAmount: val.totalAmount,
                                                            // }))
                                                            setRefundData(val)
                                                            // console.log(val)
                                                        } else { handleReceipt(val.receiptId, val.orderId) }
                                                    }}>
                                                        <div className={styles.table_content_subdiv}>
                                                            <span className={styles.table_txt_shot} ><b>{val.receiptId}</b></span>
                                                            <span className={styles.table_txt_shot} ><b><u onClick={() => { navigate('/orders/saleorder?orderId=' + val.orderId + '&method=' + val.paymentMethod) }}>{val.orderId}</u></b></span>
                                                            <span className={styles.table_txt_mid}>{val.receiptDate}</span>
                                                            <span className={styles.table_txt_shot}>{val.receiptTime}</span>
                                                            <span className={styles.table_txt + " " + styles.ordersName_truncate}>{val.storeName + ", " + val.city}</span>
                                                            <span className={styles.table_txt_mid}>{val.dealerId}</span>
                                                            <span className={styles.table_txt + " " + styles.ordersName_truncate}>{val.ordermanager}</span>
                                                            <span className={styles.table_txt + " " + styles.ordersName_truncate + ' text-success'}><b>{val.status === 'POD Pending' && val.dispatchStatus}</b></span>
                                                            <span className={styles.table_txt + " " + styles.ordersName_truncate}><b>{val.partnerName}</b></span>
                                                            <span className={styles.table_txt_shot}><b>{val.paymentAmount}</b></span>
                                                            <span className={styles.table_txt_shot}><b>{val.paymentMethod}</b></span>
                                                            <span className={styles.table_txt_mid}>
                                                                <div
                                                                    title={val.status}
                                                                    className={val.status === 'Received' ? styles.table_status_green :
                                                                    (val.status&&val.status.includes('Pending')) ? styles.table_status_pending :
                                                                            val.status === 'Cancelled' ? styles.table_status_red :
                                                                                ''}>
                                                                    {val.status}
                                                                </div>
                                                            </span>
                                                            <span className={styles.table_txt + " " + styles.ordersName_truncate}>{val.verifyBy}</span>
                                                            <span className={styles.table_txt_shot}>{val.verifyTime}</span>
                                                            <span className={styles.table_txt_shot}>
                                                                <span className={styles.reciptPositionAbsolute}>
                                                                    {showDropdown === index ?
                                                                        <span onClick={(e) => { e.stopPropagation(); setShowDropdown(!index) }}>
                                                                            <UpArrowCircle color='#FFFFFF' />
                                                                        </span>
                                                                        :
                                                                        <span onClick={(e) => { e.stopPropagation(); setShowDropdown(index) }}>
                                                                            <DownArrowCircle color='#FFFFFF' />
                                                                        </span>
                                                                    }
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={styles.reciptPositionAbsolute}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); setShowOverlay(index);

                                                                        }}
                                                                    >
                                                                        <ThreeDots color='#FFFFFF' />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            {showOverlay === index &&
                                                                <div className={styles.modal_backdrop} onClick={(e) => { e.stopPropagation(); setShowOverlay(); }}>
                                                                    <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}  >
                                                                        {
                                                                            <>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        setShowOverlay(false);
                                                                                        setCancelUpdateRefund('Cancel');
                                                                                        setShowWarningModal(true)
                                                                                        setRefundData(val)
                                                                                    }}>Cancel</span>
                                                                                <span onClick={() => { setShowOverlay(false); setCancelUpdateRefund('Update'); setShowWarningModal(true) }}>Update</span>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        setShowOverlay(false);
                                                                                        setCancelUpdateRefund('Refund');
                                                                                        setShowWarningModal(true);
                                                                                        // setRefundData(prevState => ({
                                                                                        //     ...prevState,
                                                                                        //     receiptId: val.receiptId,
                                                                                        //     orderId: val.orderId,
                                                                                        //     paymentAmount: val.paymentAmount,
                                                                                        //     totalAmount: val.totalAmount,
                                                                                        // }))
                                                                                        setRefundData(val)
                                                                                        // console.log(val)
                                                                                    }}>Refund</span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>

                                                        {
                                                            showDropdown === index ?
                                                                <div className={styles.dropdown_content_div} onClick={() => { setShowReciptModal(true) }}>
                                                                    {val.reason &&
                                                                        <>
                                                                            {/* <div className={styles.dropdown_details_div}>
                                                                                <span><b>Bank : ICICI BANK</b></span>
                                                                                <span><b>Account No : 777705005807</b></span>
                                                                                <span><b>UPI Id : demo@upi</b></span>
                                                                                <span><b>Ref / UTR No : 777705005807</b></span>
                                                                            </div>
                                                                             */}
                                                                            <div className={styles.dropdown_reason}>
                                                                                {val.reason}
                                                                            </div>

                                                                        </>
                                                                    }
                                                                </div>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.noData}>
                                        No Data Found !
                                    </div>
                                }
                            </>
                        }

                        {data.length !== 0 ?
                            <Paginantion
                                total={pages}
                                current={page}
                            />
                            :
                            ''
                        }
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default Recipts