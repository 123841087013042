import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useAuth } from '../../components/context/Auth';
import { DeleteFile, DeleteIcon, DownArrowCircle, Printbtn, Spinner, UpArrowCircle } from '../../components/icons/Icon';
import styles from './DishpatchManager.module.css'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { Link, useNavigate, useParams, } from 'react-router-dom';
import { PackingSlip } from './PackingSlip';
import { PrintLabel } from './PrintLabel';
import Modal from '../../components/Modal';
import { FileHandler } from '../../components/DragAndDrop';
// import modalcss from '../../components/Modal.module.css'

const PackingVoucher = () => {

    let componentRef = React.useRef();
    const navigate = useNavigate();
    const { switchDisplay, managerId, designation } = useAuth();
    const params = useParams()
    const orderId = params.orderId
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [packer, setPacker] = useState(managerId);
    // const [couriers, setCouriers] = useState([])
    const [partners, setPartners] = useState("")
    const [data, setData] = useState({})
    const [isReloaded, setIsReloaded] = useState(false)
    const [lableBtnLoading, setLableBtnLoading] = useState(false)
    const [paymentLink, setPaymentLink] = useState('')
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showDenyModal, setShowDenyModal] = useState(false)
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
    const [cancelReason, setCancelReason] = useState('')

    const [isImage, setisImage] = React.useState("");
    const [delivery, setDelivery] = useState({
        courierName: "",
        partnerName: "",
    })
    const [printLabel, setPrintLabel] = useState({
        qtyPacks: "",
        delPerName: "",
        delPerMobile: "",
        delTrackingNo: "",
        delTrackingUrl: "",
        courierSlip: "",
    })
    const [courierSlip, setCourierSlip] = useState()

    const [showPackedModal, setShowPackedModal] = useState(false);
    const [check, setCheck] = useState(false);
    const [images, setImages] = useState([]);
    const [packs, setPacks] = useState()
    const [point, setPoint] = useState()


    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "courierName": delivery.courierName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                        setPacker(result.data.order.pmId)
                        // setCouriers(result.data.couriers)
                        setPartners(result.data.courierPartners)
                        setPrintLabel(prevState => ({
                            ...prevState,
                            qtyPacks: result.data.order.qtyPacks,
                            delPerName: result.data.order.delPerName,
                            delPerMobile: result.data.order.delPerMobile,
                            delTrackingNo: result.data.order.delTrackingNo,
                            delTrackingUrl: result.data.order.delTrackingUrl,
                            courierSlip: result.data.order.courierSlip,
                        }))
                        if (!delivery.courierName) {
                            setDelivery(prevState => ({
                                ...prevState,
                                courierName: result.data.order.courierName,
                                methodName: result.data.order.methodName,
                                partnerName: result.data.order.partnerName,
                                shippingType: result.data.order.shippingType,
                            }))
                        }
                        // setCourierSlip(result.data.order.courierSlip)

                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [orderId, managerId, delivery.courierName, isReloaded])


    // -----------------Courier---------

    const handleSendLink = async () => {
        setPaymentLink('')
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "orderId": orderId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setPaymentLink(result.shortUrl)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleAcceptOrder = (e) => {
        e.preventDefault()

        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "pmId": packer ? packer : managerId,
            "courierName": delivery.courierName,
            "partnerName": delivery.partnerName,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    handleSendLink()
                }
                setMsgShow(result.msg)
                setShowAcceptModal(true)
            })
            .catch(error => console.log('error', error));
    }

    const orderPlacedNotification = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": data.order.whatsapp,
            "templateName": "online_order_placed",
            "headerData": "https://dealer.wallicon.in/assets/order_placed.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                console.log(result);
            })
            .catch((error) => console.error(error));
    }

    const orderPlacedPod = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": data.order.whatsapp,
            "templateName": "order_placed_pod",
            "headerData": "https://dealer.wallicon.in/assets/order_placed.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                console.log(result);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        if (packs) {
            setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
        }
    }, [packs])



    const addFormFields = () => {
        point.forEach(element => {
            setImages(prevState => ([...prevState, {}]))
        });
    }

    useEffect(() => {
        if (check && (images.length < parseInt(packs))) {
            point.forEach(element => {

                setImages(prevState => [
                    ...prevState.slice(0, images.length),
                    {},
                    // ...images.slice(index + 1)
                ])
            });
        }
    }, [check, images.length, packs, point])


    const handlePrintLabel = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "qtyPacks": printLabel.qtyPacks,
            "delPerName": printLabel.delPerName,
            "delPerMobile": printLabel.delPerMobile,
            "delTrackingNo": printLabel.delTrackingNo,
            "delTrackingUrl": printLabel.delTrackingUrl,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    handlePrint()
                    setShowLabelModal(false)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    // console.log('images', images);

    const handlePacked = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("packingQty", packs);
        images && images.map((image) =>
            formdata.append("images", image)
        )
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "packing/orderPacked/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    window.location.reload()

                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    const labelRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => labelRef.current
    });

    // useEffect(() => {
    //     if (data && data.order && data.order.dispatchStatus!=='New Order') {

    const generatePaymentLink = () => {
        setPaymentLink('')
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "orderId": orderId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setPaymentLink(result.shortUrl)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleDispatched = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("shippingType", delivery.shippingType);
        formdata.append("courierName", delivery.courierName);
        formdata.append("partnerName", delivery.partnerName);

        formdata.append("delPerName", printLabel.delPerName);
        formdata.append("delPerMobile", printLabel.delPerMobile);
        formdata.append("delTrackingNo", printLabel.delTrackingNo);
        formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
        // if (courierSlip && courierSlip[0]) {
        formdata.append("image", courierSlip[0]);
        // }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/deliveryDetails/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    if (result.data.dac === null) {
                        orderDispatchedNotification()
                    } else {
                        orderDispatchedNotificationAuth(result.data.shippingInfo.name, result.data.orderId, result.data.dac)
                    }
                    setShowDeliveryModal(false)
                    setIsReloaded(Math.random())
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }


    const orderDispatchedNotification = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": data.order.whatsapp,
            "templateName": "online_order_dispatched",
            "headerData": "https://dealer.wallicon.in/assets/dispatched.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                console.log(result);
            })
            .catch((error) => console.error(error));
    }

    const orderDispatchedNotificationAuth = (name, id, dac) => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": data.order.whatsapp,
            "templateName": "delivery_auths_code",
            "headerData": "https://dealer.wallicon.in/assets/dispatched.jpg",
            "buttonDataPayload": paymentLink,
            "dealerName": name,
            "orderId": id,
            "deliveryCode": dac
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                console.log(result);
            })
            .catch((error) => console.error(error));
    }


    useEffect(() => {
        if (isImage) {
            setImages(prevState => ([...prevState, ...isImage]))
        }
    }, [isImage])

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            // if (printLabel.courierSlip || printLabel.delTrackingNo || courierSlip) {
            handleDispatched()
            // }
            // else {
            //     alert('Please Upload Packing Slip OR Tracking Number')
            // }
        }
    };

    useEffect(() => {
        if (data.order && data.order.shippingInfo && data.order.shippingInfo.pincode) {
            setLableBtnLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": data.order.shippingInfo.pincode,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(prevState => ({
                            ...prevState,
                            SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
                            districts: result.districts[0] ? result.districts[0] : "",
                        }))
                    }
                    setLableBtnLoading(false)
                })
                .catch(error => console.log('error', error));
        }
    }, [data.order])

    const handleApproveEdit = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "dispatch/editOrderApproved", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setShowApproveModal(false)
                    navigate('/dispatchdashboard')
                }
            })
            .catch((error) => console.error(error));
    }

    const handleApproveCancel = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "dispatch/cancelOrderApproved", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setShowApproveModal(false)
                    navigate('/dispatchdashboard')
                } else {
                    alert(result.msg)
                    setShowApproveModal(false)
                }
            })
            .catch((error) => console.error(error));
    }

    const handleDenyEdit = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "dispatch/editOrderDeny", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setShowDenyModal(false)
                    window.location.reload()
                }
            })
            .catch((error) => console.error(error));
    }
    const handleDenyCancel = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "dispatch/canceleOrderDeny", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setShowDenyModal(false)
                    window.location.reload()
                }
            })
            .catch((error) => console.error(error));
    }

    const handleCancelOrder = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "cancelReason": cancelReason
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    setShowCancelOrderModal(false)
                    window.location.reload()
                } else {
                    alert(result.msg)
                }
            })
            .catch((error) => console.error(error));
    }

    // console.log('data.order', data.order );
    const filterItem = (data.order && data.order.items && data.order.items.length > 0) && data.order.items.filter(item => (item.unit || item.qty > 0 || item.desc))

    return (
        <React.Fragment>
            {/* {data.order &&
                <PrintLabel
                    data={data.order}
                    qtyPacks={1}
                // ref={labelRef}
                // handlePrint={handlePrint}
                />} */}
            <Modal
                show={showLabelModal}
                close={setShowLabelModal}
                closeBtn={true}
                heading="PRINT LABEL"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.sliptext}>Are you sure you want to Print Label & Marked as Packed?</p>
                        <div className={styles.modal_div}>
                            <p className={styles.sliptext}>Enter Number Of Packs</p>
                            <input
                                name="name"
                                type="number"
                                className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack}`}
                                value={printLabel.qtyPacks}
                                onChange={(e) => {
                                    setPrintLabel(prevState => ({
                                        ...prevState,
                                        qtyPacks: e.target.value
                                    }))
                                }}
                            />
                        </div>
                        {show ?
                            <div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Name'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerName}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerName: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="mobile"
                                        type="number"
                                        placeholder='Mobile Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerMobile}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerMobile: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingNo}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingNo: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking URL'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingUrl}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingUrl: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                            </div> : ""
                        }
                        <div className={styles.modalBtn_div}>
                            <RedOutButton title={"CANCEL"} handleSubmit={() => { setShowLabelModal(false) }} />
                            <div className={styles.printicon}>
                                <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : ''} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
                            </div>
                            <div style={{ display: "none" }}>
                                <PrintLabel
                                    data={data.order}
                                    SubDistName={data.SubDistName}
                                    districts={data.districts}
                                    qtyPacks={printLabel.qtyPacks}
                                    ref={labelRef}
                                    handlePrint={handlePrint}
                                />
                            </div>
                        </div>
                        <div className={styles.modal_div} onClick={() => setShow(!show)}>
                            {show ? <p className={styles.sliptext}>Add Delivery Details</p> : <p className={styles.sliptext}>Hide Delivery Details</p>}

                            <div>
                                {show ? <DownArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} /> : <UpArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} />}
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDeliveryModal}
                close={setShowDeliveryModal}
                closeBtn={true}
                heading={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DELIVERY DETAILS" : "DELIVERY DETAILS"}
                content={
                    <div className={styles.text_center}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.modal_div}>
                                <div className='w-100 me-1'>
                                    <label>&nbsp;Method</label>
                                    <div className={`${styles.input} w-100`}>{delivery.courierName}</div>
                                </div>
                                <div className='w-100 mx-1'>
                                    <label>&nbsp;Sub-Method</label>
                                    <div className={`${styles.input} w-100`}>{delivery.methodName}</div>
                                </div>

                                <div className='d-flex flex-column w-100 ms-1'>
                                    <label>&nbsp;Partner</label>
                                    <select
                                        className={`${switchDisplay ? styles.inputcolor : styles.input} w-100`}
                                        value={delivery.partnerName}
                                        onChange={(e) => {
                                            setDelivery(prevState => ({
                                                ...prevState,
                                                partnerName: e.target.value
                                            }));
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerName: partners && partners.find(x => x.partnerName === e.target.value)?.partnerName,
                                                delPerMobile: partners && partners.find(x => x.partnerName === e.target.value)?.contactNumber,
                                                delTrackingNo: partners && partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
                                                delTrackingUrl: partners && partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
                                            }));
                                        }}
                                        required

                                    >
                                        <option value='' hidden>Select Partner</option>
                                        {partners && partners.map((obj, index) =>
                                            <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                                        )}

                                    </select>
                                </div>
                            </div>
                            <div className={styles.modal_div}>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Name'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delPerName}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerName: e.target.value
                                        }))
                                    }}
                                />
                                <input
                                    name="mobile"
                                    type="number"
                                    placeholder='Mobile Number'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delPerMobile}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerMobile: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <div className={styles.modal_div}>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Tracking Number'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delTrackingNo}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingNo: e.target.value
                                        }))
                                    }}
                                />
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Tracking URL'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delTrackingUrl}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingUrl: e.target.value
                                        }))
                                    }}
                                />
                            </div>

                            <div className={styles.dragImage_div}>
                                <FileHandler
                                    setisImage={setCourierSlip}
                                    styles={styles}
                                />
                            </div>

                            <div className={styles.uploadImage_div}>

                                <div className={styles.demoImage_div}>

                                    {(courierSlip || printLabel.courierSlip) ?
                                        <img
                                            src={courierSlip ? URL.createObjectURL(courierSlip[0]) : process.env.REACT_APP_S3URL + printLabel.courierSlip}
                                            className={styles.modalItem_Image}
                                            alt="courierSlip"
                                        />
                                        :
                                        <img
                                            src='/assets/packingImage.png'
                                            className={styles.modalItem_Image}
                                            alt="courier"
                                        />
                                    }


                                </div>
                                <label className={styles.uploadImage_btn}>View</label>
                                <div className={styles.delete_div} onClick={() => { setCourierSlip(); setPrintLabel(prevState => ({ ...prevState, courierSlip: '' })) }}>
                                    <span><DeleteIcon /></span>
                                    <span>Delete</span>
                                </div>
                            </div>

                            <div className={styles.modalBtn_div}>
                                <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
                                <GreenOutButton btnType="submit" title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : "DISPATCHED"} />
                            </div>
                        </form>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="DISPATCH ORDER STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => {
                                data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
                                setTimeout(() => {
                                    setShowAcceptModal(false); window.location.reload()
                                }, 1000);
                            }} />
                            <GreenButton title="OK" handleSubmit={() => {
                                data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
                                setTimeout(() => {
                                    setShowAcceptModal(false); window.location.reload()
                                }, 1000);
                            }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showApproveModal}
                close={setShowApproveModal}
                closeBtn={true}
                heading={data.order && (data.order.reqType === 'Request For Cancel' ? "CANCEL ORDER REQUEST" : "EDIT ORDER REQUEST")}
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are you sure you want to Approve this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="NO" handleSubmit={() => setShowApproveModal(false)} />
                            <GreenButton title="YES" handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleApproveCancel() } else { handleApproveEdit() } }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDenyModal}
                close={setShowDenyModal}
                closeBtn={true}
                heading={data.order && (data.order.reqType === 'Request For Cancel' ? "DENY CANCEL ORDER REQUEST" : "DENY EDIT ORDER REQUEST")}
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are you sure you want to Deny this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="NO" handleSubmit={() => setShowDenyModal(false)} />
                            <GreenButton title="YES" handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleDenyCancel() } else { handleDenyEdit() } }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showCancelOrderModal}
                close={setShowCancelOrderModal}
                closeBtn={true}
                heading="CANCEL ORDER"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are You Sure Want To Cancel This Order</p>
                        <textarea type="text" placeholder='Enter Cancel Reason' className={styles.reqIssue} value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
                            <GreenButton title="YES" handleSubmit={() => { handleCancelOrder(); }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showPackedModal}
                close={setShowPackedModal}
                closeBtn={true}
                heading="PACKING STATUS"
                content={
                    <form onSubmit={handlePacked} className={styles.text_center}>
                        <div className={styles.main_div}>
                            <div>
                                <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                                <input type="number"
                                    name="packs"
                                    value={packs}
                                    // setPacks
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                    onChange={(e) => {
                                        setPacks(e.target.value)

                                        if (check && e.target.value && (images.length > parseInt(e.target.value))) {
                                            const values = [...images];
                                            values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
                                            setImages(values);
                                        }

                                    }}
                                    className={styles.enterPackets}
                                    required
                                    autoFocus={true}
                                />
                            </div>

                            <div className={styles.checkBox_addImage}>
                                <input
                                    type="checkbox"
                                    name="vehicle1"
                                    value="check"
                                    className={styles.checkbox}
                                    checked={check ? true : false}
                                    onChange={(e) => {
                                        if (e.target.checked && packs) {
                                            addFormFields()
                                            setCheck(true)
                                        } else {
                                            const values = [...images];
                                            values.splice(point, point.length);
                                            setImages(values);
                                            setCheck(false)
                                        }
                                    }}
                                />
                                <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
                            </div>

                            <div className={styles.dragImage_div}>
                                <FileHandler
                                    setisImage={setisImage}
                                    styles={styles}
                                />
                            </div>

                            {/* <div className={styles.dragImage_div}>
                                <span> <img src='/assets/packing_image.png' alt="item_image" className={styles.packing_image} /></span>
                                <p className={styles.drag_txt}>Drag photos and documents</p>
                                <hr className={styles.border_OR_div} />
                                <span className={styles.OR_css}>OR</span>
                                <button type='button' className={styles.browseFiles_btn}>Browse Photos</button>
                            </div> */}

                            <div>
                                {images.map((item, index) =>
                                    <div key={index} className={styles.flexdiv}>
                                        <span>Pack {index + 1}</span>
                                        {/* <label className={styles.label}>
                                            <input
                                                name=""
                                                type="file"
                                                hidden
                                                onChange={(e) => {
                                                    let data = [...images];
                                                    data[index] = e.target.files[0];
                                                    setImages(data);
                                                }}
                                            />
                                            <UpLoadFile />&nbsp;Upload Your Photos Here
                                        </label> */}

                                        {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}

                                            onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
                                        /> :
                                            <img
                                                src='/assets/packingImage.png'
                                                alt='packing_order_image'
                                                className={styles.packingImage}
                                            />
                                        }

                                        <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>

                                        <div className={styles.deletediv}
                                            onClick={e => {
                                                let data = [...images];
                                                data[index] = {};
                                                setImages(data);
                                            }}
                                        >
                                            <DeleteFile color={"red"} />
                                            <p className={styles.deleteicon}>Delete</p>
                                        </div>
                                        {images.length !== 1 &&
                                            <div className={styles.deletediv}
                                                onClick={e => {
                                                    setPacks(parseInt(packs) - 1)
                                                    const list = [...images];
                                                    list.splice(index, 1);
                                                    setImages(list);
                                                }}
                                            >
                                                <span className={styles.crossIcon}> &#10006;</span>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.packingModal_btn}>
                            <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
                            <GreenButton btnType="submit" title="OK" />
                        </div>
                    </form>
                }
            />

            {/* <PackingImage
                show={showPackingImage}
                close={setShowPackingImage}
                closeBtn={true}
                content={
                    <>
                        <div className={styles.modal_close_button_div}>
                            <button
                                type="button"
                                className={styles.modal_close_btn}
                                onClick={() => { setShowPackingImage(false) }}
                            >
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className={styles.packingStatus_main}>

                           

                            <img src={packingImage} alt='item_image' className={styles.packingImagefull} />
                        </div>
                    </>
                }
            /> */}

            {loading ? "Loading" :
                <form onSubmit={handleAcceptOrder} className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.ff}`}>
                    <div className={`${switchDisplay ? styles.voucher_maindiv : styles.voucher_maindiv}`}>

                        <div className={styles.packingVoucherDiv}>
                            <div className={styles.packingHeading}>
                                <div className={styles.backArrow} onClick={() => navigate(-1)}>&lt;&nbsp;Go Back</div>
                                <h2>Packing Voucher</h2>
                                <div className={`${(data.order.dispatchStatus === "New Order") ? styles.status_blue :
                                    (data.order.dispatchStatus === "Packed" || data.order.dispatchStatus === "Dispatched") ? styles.statuscolor3 :
                                        (data.order.dispatchStatus === "Not Packed") ? styles.statuscolor1 :
                                            (data.order.dispatchStatus === 'In Progress' || data.order.dispatchStatus === 'Pending') ?
                                                styles.status : ''
                                    }`}>
                                    &#9679; {data.order.dispatchStatus}
                                </div>
                            </div>

                            <div className={styles.voucher_flex}>
                                <div className={styles.voucherFlexDirection}>
                                    <h2 className={styles.slipheading_div}>{data.order && data.order.storeName}</h2>
                                    <span className={styles.sliptext}>Ship To:&nbsp;<b>{data.order.shippingInfo && data.order.shippingInfo.name}</b></span>
                                    <span className={styles.sliptext}>Address:&nbsp;<b>{data.order.shippingInfo &&
                                        (data.order.shippingInfo.street + ", " + data.order.shippingInfo.city + ", " + data.order.shippingInfo.district + ", " +
                                            data.order.shippingInfo.state + ", " + data.order.shippingInfo.pincode
                                        )}</b></span>
                                    <span className={styles.sliptextGustin}>GSTUIN:&nbsp;<b>{data.order && data.order.gstuin}</b></span>
                                    {/* <span className={styles.sliptext}>State:&nbsp;{data.order && data.order.state}</span> */}
                                    <span className={styles.sliptext}>Contact:&nbsp;{data.order.shippingInfo && data.order.shippingInfo.mobile}</span>
                                    {/* <span className={styles.sliptext}>Email:&nbsp;{data.order && data.order.email}</span> */}
                                    <span className={styles.sliptext}>Order Manager:&nbsp;{data.order && data.order.ordermanager}</span>
                                    <span className={styles.sliptext}>Packing Manager:&nbsp;{data.order && data.order.packermanager}</span>
                                </div>
                                <div className={styles.packingVoucher_left}>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Voucher No.</span>
                                        <span className={styles.slipDetailsData}><b>{data.order && data.order.orderId}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Voucher Date</span>
                                        <span className={styles.slipDetailsData}><b>{data.order && data.order.orderDate}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Delivery Type</span>
                                        <span className={styles.slipDetailsData}><b>{data.order && data.order.shippingType}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Delivery Method</span>
                                        <div className={`${styles.slipDetailsDataSelect} py-2 px-1`}>
                                            {delivery.courierName ? delivery.courierName : data.order.courierName}
                                        </div>
                                        {/* <select className={styles.slipDetailsDataSelect}
                                            value={delivery.courierName ? delivery.courierName : data.order.courierName}
                                            onChange={(e) => setDelivery(prevState => ({
                                                ...prevState,
                                                courierName: e.target.value
                                            }))}
                                        required
                                        >
                                            <option value={""}>Select</option>
                                            {couriers && couriers.map((obj, index) =>
                                                <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                            )}
                                        </select> */}
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Sub-Method</span>
                                        <div className={`${styles.slipDetailsDataSelect} py-2 px-1`}>
                                            {delivery.partnerName ? delivery.partnerName : data.order.partnerName}
                                        </div>
                                        {/* <select className={styles.slipDetailsDataSelect}
                                            value={delivery.partnerName ? delivery.partnerName : data.order.partnerName}
                                            onChange={(e) => setDelivery(prevState => ({
                                                ...prevState,
                                                partnerName: e.target.value
                                            }))}
                                        required
                                        >
                                            <option value={""}>Select</option>
                                            {partners && partners.partners.map((obj, index) =>
                                                <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                                            )}
                                        </select> */}
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Order Manager :</span>
                                        <a href={'tel:+91' + data.order.managercontact} className={styles.slipDetailsData}><b>{data.order && data.order.managercontact}</b></a>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Packing Manager :</span>
                                        <a href={'tel:+91' + data.order.pmcontact} className={styles.slipDetailsData}><b>{data.order && data.order.pmcontact}</b></a>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.fixTableHead_packing}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Item Description</th>
                                            <th>Qty</th>
                                            <th>Per</th>
                                        </tr>
                                    </thead>
                                    {filterItem && filterItem.map((val, key) => {
                                        return (
                                            <tbody key={key}>
                                                <tr className={styles.h10}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        <div className={styles.itemDescription_div}>
                                                            <span>{val.itemNo ? val.itemNo : val.accessoriesName}</span>
                                                            <span>{val.product_name}</span>
                                                            <span>{val.warehouse}</span>
                                                            <span>{val.batch}</span>
                                                        </div>
                                                    </td>
                                                    <td>{val.qty}</td>
                                                    <td>{val.unit ? val.unit : 'Pcs'}</td>
                                                </tr>
                                                {(key === val.descKey || val.desc) && <tr style={{ border: '1px solid' }}>
                                                    <td colSpan={6} className={styles.itemDescription_div}>
                                                        {val.desc}
                                                    </td>
                                                </tr>
                                                }

                                                {/* <tr className={styles.brderTr}>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr> */}
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                            <div className={styles.packingHeading}>
                                <div>
                                    <div className={styles.selectheading}>Select Packer</div>

                                    <select
                                        className={`${switchDisplay ? styles.bg_white :
                                            styles.bg_dark} ${styles.select}`}
                                        onChange={(e) => setPacker(e.target.value)}
                                        value={packer}
                                        // defaultValue={managerId}
                                        required
                                    >
                                        <option value={managerId} >Self</option>
                                        {data.pakingManagers && data.pakingManagers.map((item, index) =>
                                            <option
                                                key={index}
                                                className={styles.selecthover}
                                                value={item.managerId}
                                            >
                                                {item.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div>Total Items: {data.order.items.length}</div>
                            </div>

                            <div className={styles.btn_div}>
                                <RedOutButton btnType="button" title={"BACK"} handleSubmit={() => { window.history.back() }} />
                                {data.order.reqStatus === '' && data.order.dispatchStatus !== 'New Order' && data.order.dispatchStatus !== 'Cancelled' &&
                                    <RedOutButton
                                        disabled={false}
                                        btnType="button"
                                        title='CANCEL ORDER'
                                        css='px-2'
                                        handleSubmit={() => { setShowCancelOrderModal(true) }}
                                    />
                                }
                                {data.order.dispatchStatus === 'New Order' ?
                                    <GreenOutButton btnType="submit" title={"ACCEPT"} />
                                    :
                                    (data.order.pmId === parseInt(managerId) || (designation === 'superadmin')) && data.order.packingStatus !== "Packed" ?
                                        <>
                                            <>
                                                {
                                                    data.order.reqStatus === "Pending" ?
                                                        <>
                                                            {
                                                                data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
                                                                <GreenButton
                                                                    btnType="button"
                                                                    title={"APPROVE EDIT REQUEST"}
                                                                    handleSubmit={() => { setShowApproveModal(true) }}
                                                                    css={styles.editRequestButton}
                                                                />
                                                            }
                                                            {
                                                                data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
                                                                <RedOutButton
                                                                    btnType="button"
                                                                    title={"DENY EDIT REQUEST"}
                                                                    handleSubmit={() => { setShowDenyModal(true) }}
                                                                    css='px-3 py-2'
                                                                />
                                                            }
                                                            {
                                                                data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
                                                                <GreenButton
                                                                    btnType="button"
                                                                    title={"APPROVE CANCEL REQUEST"}
                                                                    handleSubmit={() => { setShowApproveModal(true) }}
                                                                    css={styles.editRequestButton}
                                                                />
                                                            }
                                                            {
                                                                data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
                                                                <RedOutButton
                                                                    btnType="button"
                                                                    title={"DENY CANCEL REQUEST"}
                                                                    handleSubmit={() => { setShowDenyModal(true) }}
                                                                    css='px-3 py-2'
                                                                />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {data.order.dispatchStatus !== "Cancelled" &&
                                                                <>
                                                                    <ReactToPrint
                                                                        trigger={() => <div>
                                                                            <GreenOutButton
                                                                                btnType="button"
                                                                                title={<div className={styles.btn}><Printbtn />&nbsp;&nbsp;PACKING SLIP</div>} />
                                                                        </div>}
                                                                        content={() => componentRef}
                                                                    />
                                                                    <div style={{ display: "none" }}>
                                                                        <PackingSlip
                                                                            orderId={orderId}
                                                                            managerId={managerId}
                                                                            delivery={delivery}
                                                                            ref={(el) => (componentRef = el)}
                                                                        />
                                                                    </div>
                                                                    <GreenButton
                                                                        btnType="button"
                                                                        title={"PACKED"}
                                                                        handleSubmit={() => { setShowPackedModal(true) }}
                                                                    />
                                                                </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        </>
                                        :
                                        <>
                                            {data.order.dispatchStatus === "Dispatched" ?
                                                <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link>
                                                :
                                                data.order.reqStatus === "Pending" ?
                                                    <>
                                                        {
                                                            data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
                                                            <GreenButton
                                                                btnType="button"
                                                                title={"APPROVE EDIT REQUEST"}
                                                                handleSubmit={() => { setShowApproveModal(true) }}
                                                                css={styles.editRequestButton}
                                                            />
                                                        }
                                                        {
                                                            data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
                                                            <RedOutButton
                                                                btnType="button"
                                                                title={"DENY EDIT REQUEST"}
                                                                handleSubmit={() => { setShowDenyModal(true) }}
                                                                css='px-3 py-2'
                                                            />
                                                        }
                                                        {
                                                            data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
                                                            <GreenButton
                                                                btnType="button"
                                                                title={"APPROVE CANCEL REQUEST"}
                                                                handleSubmit={() => { setShowApproveModal(true) }}
                                                                css={styles.editRequestButton}
                                                            />
                                                        }
                                                        {
                                                            data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
                                                            <RedOutButton
                                                                btnType="button"
                                                                title={"DENY CANCEL REQUEST"}
                                                                handleSubmit={() => { setShowDenyModal(true) }}
                                                                css='px-3 py-2'
                                                            />
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {data.order.dispatchStatus !== "Cancelled" &&
                                                            <GreenOutButton btnType="button" title="DELIVERY DETAILS"
                                                                disabled={data.order.dispatchStatus === "Cancelled" ? true : false}
                                                                handleSubmit={() => {
                                                                    setPrintLabel(prevState => ({
                                                                        ...prevState,
                                                                        delPerName: partners && partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
                                                                        delPerMobile: partners && partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
                                                                        delTrackingNo: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
                                                                        delTrackingUrl: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
                                                                    }));
                                                                    setShowDeliveryModal(true);
                                                                    generatePaymentLink()
                                                                }}
                                                            />
                                                        }
                                                    </>
                                            }
                                            {data.order.reqStatus === "Pending" ? '' :
                                                <GreenOutButton
                                                    disabled={((data.order.dispatchStatus === "Cancelled") || lableBtnLoading) ? true : false}
                                                    btnType="button"
                                                    title={<>{lableBtnLoading ? <Spinner size="15" /> : <div className={styles.btn}><Printbtn />&nbsp;&nbsp;LABEL</div>}</>}
                                                    handleSubmit={() => { setShowLabelModal(true) }}
                                                />
                                            }
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </form>
            }
            {/* <PackingSlip
                orderId={orderId}
                managerId={managerId}
                delivery={delivery}
                ref={(el) => (componentRef = el)}
            /> */}

        </React.Fragment>
    )
}
export default PackingVoucher;



// export const PackingImage = ({ show, close, content, heading, closeBtn }) => {
//     useEffect(() => {
//         if (show) {
//             document.body.style.overflow = 'hidden'
//         } else {
//             document.body.style.overflow = 'auto'
//         }
//     }, [show,])
//     return show && (
//         <div className={modalcss.modal_backdrop} onClick={() => { close(); }}>
//             <div className={modalcss.packingImage_content} onClick={e => { e.stopPropagation(); }}  >
//                 {content}
//             </div>
//         </div >
//     )
// }