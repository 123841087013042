import React, { useEffect } from 'react'
import AdminMenu from '../leftMeuns/AdminMenu'
import ContentMenu from '../leftMeuns/ContentMenu'
import MarketingMenu from '../leftMeuns/MarketingMenu'
import { useAuth } from './context/Auth'
import styles from '../leftMeuns/Menus.module.css'
import { useNavigate } from 'react-router-dom'
import OrderMenu from '../leftMeuns/OrderMenu'
import InventoryMenu from '../leftMeuns/InventoryMenu'
import DispatchMenu from '../leftMeuns/DispatchMenu'
import PackingMenu from '../leftMeuns/PackingMenu'
import PaymentMenu from '../leftMeuns/paymentMenu'

const LeftMenu = () => {
    const { designation, managerId } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (!managerId) {
            navigate("/")
        }
    }, [managerId, navigate])

    return (
        <React.Fragment>
            <div className={styles.leftMenu}>
                {designation === "superadmin" && <AdminMenu />}
                {designation === "marketing" && <MarketingMenu />}
                {designation === "contentmanager" && <ContentMenu />}
                {designation === "ordermanager" && <OrderMenu />}
                {designation === "inventorymanager" && <InventoryMenu />}
                {designation === "dispatchmanager" && <DispatchMenu />}
                {designation === "packingmanager" && <PackingMenu />}
                {designation === "paymentmanager" && <PaymentMenu />}
            </div>
        </React.Fragment>
    )
}

export default LeftMenu