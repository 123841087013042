import React, { useState } from 'react'
import { GreenOutButton, RedOutButton } from '../../components/Buttons';

const AdvancedSettings = (props) => {
    const [styleFilter, setStyleFilter] = useState(0)
    const [typeFilter, setTypeFilter] = useState(0)
    const [collectionFilter, setCollectionFilter] = useState(0)
    const divStyle = {
        display: props.displayModal ? 'block' : 'none'
    };
    function closeModal(e) {
        e.stopPropagation()
        props.closeModal()
    }

    return (
        <React.Fragment>
            <div
                className="custom_modal"
                onClick={closeModal}
                style={divStyle} >
                <div className="modal_content center moldal_height p_20 cp" onClick={e => e.stopPropagation()} >
                    <div className="fs_30 text_center">Advanced Settings</div>
                    <div className="row mt_30 mb_10">
                        <div className="col-4 offset-4 fs_16 text_center">
                            Visibility
                        </div>
                        <div className="col-4 fs_16 text_center">
                            Order
                        </div>
                    </div>
                    <div className="row align_items_center">
                        <div className="col-3 offset_1_AS fs_20">
                            STYLE
                        </div>
                        <div className="col-4 border_SH">
                            <button className={`${styleFilter === 0 ? "light_Green" : "gray_btn"} btnMKD`} onClick={() => setStyleFilter(0)}>Show</button>
                            <button className={`${styleFilter === 1 ? "red_btn" : "gray_btn"} btnMKD`} onClick={() => setStyleFilter(1)}>Hide</button>
                        </div>
                        <div className="col-3 offset_1_AS text_center">
                            {styleFilter === 0 ?
                                <select className="w_89 bg_gray_dark border text_center fs_18 br_5" >
                                    <option className="bg_gray_dark">Select</option>
                                    <option className="bg_gray_dark" value="1">1</option>
                                    <option className="bg_gray_dark" value="2">2</option>
                                    <option className="bg_gray_dark" value="3">3</option>
                                </select>
                                : <input type="text" value="N/A" className="w_25 bg_gray_dark border text_center fs_18 br_5" disabled />}
                        </div>
                    </div>
                    <div className="row align_items_center mt_20">
                        <div className="col-3 offset_1_AS fs_20">
                            TYPE
                        </div>
                        <div className="col-4 border_SH">
                            <button className={`${typeFilter === 0 ? "light_Green" : "gray_btn"} btnMKD`} onClick={() => setTypeFilter(0)}>Show</button>
                            <button className={`${typeFilter === 1 ? "red_btn" : "gray_btn"} btnMKD`} onClick={() => setTypeFilter(1)}>Hide</button>

                        </div>
                        <div className="col-3 offset_1_AS text_center">
                            {typeFilter === 0 ?
                                <select className="w_89 bg_gray_dark border text_center fs_18 br_5" >
                                    <option className="bg_gray_dark">Select</option>
                                    <option className="bg_gray_dark" value="1">1</option>
                                    <option className="bg_gray_dark" value="2">2</option>
                                    <option className="bg_gray_dark" value="3">3</option>
                                </select>
                                : <input type="text" value="N/A" className="w_25 bg_gray_dark border text_center fs_18 br_5" disabled />}
                        </div>
                    </div>
                    <div className="row align_items_center mt_20">
                        <div className="col-3 offset_1_AS fs_20">
                            COLLECTION
                        </div>
                        <div className="col-4 border_SH">
                            <button className={`${collectionFilter === 0 ? "light_Green" : "gray_btn"} btnMKD`} onClick={() => setCollectionFilter(0)}>Show</button>
                            <button className={`${collectionFilter === 1 ? "red_btn" : "gray_btn"} btnMKD`} onClick={() => setCollectionFilter(1)}>Hide</button>

                        </div>
                        <div className="col-3 offset_1_AS text_center">
                            {collectionFilter === 0 ?
                                <select className="w_89 bg_gray_dark border text_center fs_18 br_5" >
                                    <option className="bg_gray_dark">Select</option>
                                    <option className="bg_gray_dark" value="1">1</option>
                                    <option className="bg_gray_dark" value="2">2</option>
                                    <option className="bg_gray_dark" value="3">3</option>
                                </select>
                                : <input type="text" value="N/A" className="w_25 bg_gray_dark border text_center fs_18 br_5" disabled />}
                        </div>
                    </div>

                    <div className="text_center mt_41">
                        <RedOutButton title="CANCEL" css="me_20p" handleSubmit={closeModal} />
                        <GreenOutButton title="SAVE" css="" />
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default AdvancedSettings