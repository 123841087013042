import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AddDealerModal from '../../components/AddDealerModal'
import { BlackOutButton, GreenButton, GreenOutButton, RedOutButton, } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { AddDealerIcon, Ban, Call, CheckGreenIcon, Checked, Clock, CrossRedIcon, DoubleCheckIcon, Messanger, PackingWhatsappIcon, Search, Spinner, UpdateIcon, WhatsApp } from '../../components/icons/Icon'
import Pagination from '../../components/Paginantion'
// import { AddDealerModal } from '../ordermanager/SaleOrders'
import styles from "./Dealer.module.css"
import Modal from '../../components/Modal'

const Dealers = () => {

  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

  const [showAddDealer, setShowAddDealer] = useState(false)
  const [dealerData, setDealerData] = useState(false)
  const [dealerNumber, setDealerNumber] = useState([])
  const { managerId } = useAuth()
  const [dealersData, setDealersData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  const [showMessengerModal, setShowMessengerModal] = useState(false)
  const [sendTemplateModal, setSendTemplateModal] = useState(false)
  const [sendTemplateText, setSendTemplateText] = useState('')
  const [allTemplate, setAllTemplate] = useState([])
  const [singleTemplate, setSingleTemplate] = useState({})
  const [messengerTemplate, setMessengerTemplate] = useState()
  const [dealerNames, setDealerNames] = useState([]);
  const [showDealerNames, setShowDealerNames] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState([]);
  // const [headerType, setHeaderType] = useState('document');
  const [templateData, setTemplateData] = useState();
  const [templateError, setTemplateError] = useState();
  const [templateDataLoading, setTemplateDataLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState({});
  const [templatePage, setTemplatePage] = useState(1)
  const [totalPage, setTotalPage] = useState(3)
  const sliceData = (data, page) => {
    const pageSize = 1000;
    
    if (page === 1) {
      return data.slice(0, pageSize);
    } else if (page === 2) {
      return data.slice(pageSize, pageSize * 2);
    } else if (page === 3) {
      return data.slice(pageSize * 2, pageSize * 3);
    } else if (page === 4) {
      return data.slice(pageSize * 3);
    } else {
      return [];
    }
  };
  
  // Example usage in a React component
    const slicedData = sliceData(dealerNumber, templatePage);
  // const dealerNumber = [
  //   {
  //     name: 'ranbeer',
  //     whatsapp: 8960418478,
  //   },
  //   {
  //     name: 'rakesh',
  //     whatsapp: 7007236955,
  //   },
  //   {
  //     name: 'akhand',
  //     whatsapp: 9984920907,
  //   },
  //   {
  //     name: 'Shakib',
  //     whatsapp: 6386151531,
  //   },
  // ];

  useEffect(() => {
    if (managerId) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(process.env.REACT_APP_URL + "wallikonChat/templateList", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 'success') {
            setAllTemplate(result.data)
          }
        })
        .catch((error) => console.error(error));
    }
  }, [managerId])

  // console.log(allTemplate);

  const handleSelectAllNumber = () => {
    setSelectedNumber(dealerNumber.map(number => number.whatsapp));
  };

  // console.log('dealerNames' , dealerNames);

  useEffect(() => {
    window.addEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
    window.removeEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
  }, [])

  const [searchDealerData, setSearchDealerData] = useState({
    searchDealerName: '',
    sortByStatus: '',
  })
  const [pages, setPages] = useState("");

  useEffect(() => {
    if (managerId) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "keyword": searchDealerData.searchDealerName,
        "sortKey": searchDealerData.sortByStatus
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + `kyc/dealers?page=${page}&limit=${width > 1600 ? '18' : '15'}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (result.data) {
              setLoading(false)
              setDealersData(result.data)
              // setDealerNumber(result.dealerWhatsapp)
            }
            if (result.pages) {
              setPages(result.pages ? result.pages : "")
            }
          }
        })
        .catch(error => console.log('error', error));

    }
  }, [managerId, searchDealerData.sortByStatus, searchDealerData.searchDealerName, page, width])

  const dealerNumberData = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "kyc/dealerWhatsapp", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDealerNumber(result.dealerWhatsapp)
        }
      })
      .catch((error) => console.error(error));
  }

  // useEffect(() => {
  //   if(managerId){
  //     const myHeaders = new Headers();
  //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //     myHeaders.append("Content-Type", "application/json");

  //     const raw = JSON.stringify({
  //       "managerId": managerId
  //     });

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow"
  //     };

  //     fetch(process.env.REACT_APP_URL + "kyc/dealerWhatsapp", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.status === "success") {
  //           setDealerNumber(result.dealerWhatsapp)
  //         }
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [managerId])

  // console.log('selectedNumber', selectedNumber);
  // console.log('dealerNumber', dealerNumber);
  useEffect(() => {
    if (dealerData.dealerId) {
      navigate("/dealers/" + dealerData.dealerId)
    }
  }, [dealerData.dealerId, navigate])

  const sendMessage = async () => {
    if (singleTemplate.headerType === 'document') {
      setTemplateDataLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerData": singleTemplate.header,
        "documentFilename": singleTemplate.documentName
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, requestOptions)
        .then((response) => response.json())
        .then(result => {
          if (result.status === 'failed') {
            alert(result.msg)
            setTemplateError(result.msg)
          }
          setTemplateDataLoading(false)
          processResult(result.results)
          setSendStatus(result.response)
          if(templatePage<totalPage)
          setTemplatePage(prevPage => prevPage + 1);
          setTotalPage(result.response.totalPages)
        })
        .catch((error) => console.error(error));
    }

    else if (singleTemplate.headerType === 'video') {
      setTemplateDataLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerData": singleTemplate.header,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, requestOptions)
        .then((response) => response.json())
        .then(result => {
          if (result.status === 'failed') {
            alert(result.msg)
            setTemplateError(result.msg)
          }
          setTemplateDataLoading(false)
          processResult(result.results)
          setSendStatus(result.response)
          if(templatePage<totalPage)
          setTemplatePage(prevPage => prevPage + 1);
          setTotalPage(result.response.totalPages)
        })
        .catch((error) => console.error(error));
    }
    else if (singleTemplate.headerType === 'image') {
      setTemplateDataLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerData": singleTemplate.header,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, requestOptions)
        .then((response) => response.json())
        .then(result => {
          if (result.status === 'failed') {
            alert(result.msg)
            setTemplateError(result.msg)
          }
          setTemplateDataLoading(false)
          processResult(result.results)
          setSendStatus(result.response)
          if(templatePage<totalPage)
          setTemplatePage(prevPage => prevPage + 1);
          setTotalPage(result.response.totalPages)
        })
        .catch((error) => console.error(error));
    }
    else if (singleTemplate.headerType === 'location') {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "latitude": "26.8428008",
        "longitude": "80.9359821",
        "locationName": "Wallicon Private Limited",
        "locationAddress": "Wallicon, Burlington, Aminabad Road, Lucknow",
        "buttonIndex": "1",
        "buttonDataPayload": "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14"
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", requestOptions)
        .then((response) => response.json())
        .then(result => {
          setTemplateDataLoading(false)
          processResult(result.results)
        })
        .catch((error) => console.error(error));
    }
    else {
      alert('Please select file type')
    }
  };

  const processResult = (results) => {
    const newStatusArray = slicedData.map(dealer => {
      const foundResult = results.find(result => {
        const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
        return input && (input.toString() === dealer.whatsapp.toString());
      });

      if (foundResult) {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: foundResult.status
        };
      }
       else {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: 'failed'
        };
      }
    });

    // You can now use this array as needed
    setDealerNames(newStatusArray)
    handleAddTemplateRecord(newStatusArray)
    console.log(newStatusArray);
  };

  const handleAddTemplateRecord = (statusInfo) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId,
      "templateName": messengerTemplate,
      "statusInfo": statusInfo
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "wallikonChat/addUpTempstatus", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          setTemplateData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  // console.log(dealerNames);


  // const sendMessage = async () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", "Bearer EABljmRig1VcBO8EokWI5QUZBu8tsUfEsZADtERQitatMhA3WOn7ajgrcRuQ0fQbZA5uAiP5RXamzt1BZCaWJkGGBZBobhZAa91WB8BCORGvJwvZBuDSBccs3ZAWSDPH5STTYyThu0OYbGKE6979SuzCreXjRpZBR5pFZBT6sFeLzUE4yKQZA8ZC5jLay14ZBkH9PGSkA80HJSZC4bVJTS3OsgB");
  //   myHeaders.append("Cookie", "ps_l=1; ps_n=1");
  //   const newWaIds = []
  //   const templatePayload = {
  //     "messaging_product": "whatsapp",
  //     "type": "template",
  //     "template": {
  //       "name": messengerTemplate,
  //       "language": {
  //         "code": "en"
  //       },
  //       "components": [
  //         {
  //           "type": "header",
  //           "parameters": [
  //             {
  //               "type": "document",
  //               "document": {
  //                 "link": "https://dealer.wallicon.in/assets/fauna_doc.pdf",
  //                 "filename": "FAUNA WALLPAPER CATALOG WALLIKON 2024"
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   };
  //   const newDealerNames = [];
  //   for (const number of selectedNumber) {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: JSON.stringify({
  //         ...templatePayload,
  //         "to": number.whatsapp
  //       }),
  //       redirect: 'follow'
  //     };

  //     try {
  //       const response = await fetch("https://graph.facebook.com/v19.0/161149970425453/messages", requestOptions);
  //       const result = await response.json();

  //       if (result.messages && result.messages.length > 0) {
  //         const waId = result.contacts[0].wa_id;
  //         newWaIds.push(waId);

  //         // Find the corresponding dealerNumber object
  //         const matchingDealerNumber = dealerNumber.find(dealer => dealer.whatsapp === number.whatsapp);

  //         // If a match is found, store the name in newDealerNames array
  //         if (matchingDealerNumber) {
  //           newDealerNames.push(matchingDealerNumber.name);

  //           // Update the state with the newDealerNames array
  //           setDealerNames(prevDealerNames => [...prevDealerNames, matchingDealerNumber.name]);
  //         }
  //       }
  //     } catch (error) {
  //       console.log('error', error);
  //     }
  //   }

  //   // Here, you can still set the state with the final array if needed
  //   // setDealerNames(newDealerNames);
  // };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setMessengerTemplate(selectedValue);
    const selectedTemplate = allTemplate.find(item => item.templateName === selectedValue);
    if (selectedTemplate) {
      setSingleTemplate(selectedTemplate);
    } else if (selectedValue === 'custom_value') {
      setSingleTemplate({ templateName: 'custom_value' });
    }
  };
  // console.log('templateDataLoading', templateDataLoading);

  return (
    <React.Fragment>

      <Modal
        show={showMessengerModal}
        close={() => { setSelectedNumber([]); setShowMessengerModal(); setMessengerTemplate() }}
        closeBtn={true}
        heading="Send Message"
        content={
          <div className={styles.text_center}>
            <div className={styles.choose_template}>
              <select value={messengerTemplate} onChange={handleChange}>
                <option value='' hidden>Choose Template</option>
                {allTemplate && allTemplate.map((item, index) =>
                  <option key={index} value={item.templateName}>{item.templateName && item.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</option>
                )}
              </select>
            </div>

            <div className={styles.all_contacts_div}>
              <div className='d-flex'>
                All Contacts
                <div
                  role='button'
                  className='border rounded p-1 d-flex justify-content-center align-items-center ms-4'
                  onClick={() => {
                    if (selectedNumber.length === dealerNumber.length) {
                      setSelectedNumber([]);
                    } else {
                      handleSelectAllNumber();
                    }
                  }}
                >
                  {selectedNumber.length !== 0 && (selectedNumber.length === dealerNumber.length) ? <CheckGreenIcon /> : <span className='p-2'></span>}
                </div>
              </div>
              <div>Total - {selectedNumber.length}</div>
            </div>

            <div className={styles.massageModal_btn}>
              <RedOutButton title="CANCEL" handleSubmit={() => { setSelectedNumber([]); setShowMessengerModal(false); setMessengerTemplate() }} />
              {selectedNumber && selectedNumber.length !== 0 && messengerTemplate
                ?
                // <GreenButton title="SEND" handleSubmit={() => { sendMessage(); setShowMessengerModal(false); setShowDealerNames(true) }} />
                <GreenButton title="SEND" handleSubmit={() => { setShowMessengerModal(false); setSendTemplateModal(true) }} />
                :
                <BlackOutButton title="SEND" handleSubmit={() => { }} />
              }
            </div>
          </div>
        }
      />
      <Modal
        show={sendTemplateModal}
        close={() => { setSendTemplateModal(); setSelectedNumber([]); setMessengerTemplate() }}
        closeBtn={true}
        heading={messengerTemplate && (messengerTemplate.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))}
        content={
          <div className={styles.text_center}>
            <div className='d-flex flex-column'>
              <p className={`${styles.message} mt-1 mb-3`}>Price of Template sending is <span className='text-danger'>{selectedNumber.length * (singleTemplate.category === 'MARKETING' ? 0.73 : singleTemplate.category === 'UTILITY' ? 0.30 : 0)}</span></p>
              <p className={`${styles.message} mt-1 mb-3`}>To send! type <span className='text-danger'>send</span> then click the send button.</p>
              <input type='text' maxLength={6} className={styles.input_delete} value={sendTemplateText} placeholder='Enter' onChange={(e) => setSendTemplateText(e.target.value)} />
            </div>

            <div className={styles.massageModal_btn}>
              <RedOutButton title="CANCEL" handleSubmit={() => { setSendTemplateModal(false); setSelectedNumber([]); setMessengerTemplate() }} />
              <GreenButton disabled={sendTemplateText.toUpperCase() === 'SEND' ? (templatePage<totalPage&&false) : true} title="SEND" handleSubmit={() => { sendMessage(); setShowMessengerModal(false); setSendTemplateModal(false); setShowDealerNames(true) }} />
            </div>
          </div>
        }
      />

      <Modal
        show={showDealerNames}
        close=''
        closeBtn={false}
        heading=''
        content={
          <div className={styles.text_center}>
            <div className='d-flex justify-content-between'>
              <h2 className={styles.template_name}>{messengerTemplate + ' Template Sent'}</h2>
              <div className={styles.modal_close_button} onClick={() => { setShowDealerNames(false); setSelectedNumber([]); setMessengerTemplate(); setSendTemplateText('') }}>&times;</div>
            </div>
            {/* <div className='d-flex justify-content-between'>
              <div>Total - {dealerNames.length}</div>
              <div className={styles.success_color}>Success - {templateData && templateData.successCount}</div>
              <div className='text-danger fw-bold'>Failed - {templateData && templateData.failedCount}</div>
            </div> */}
            <div className={styles.dealerNames_div}>
              {dealerNames && dealerNames.map((item, index) => <div key={index} className={styles.dealerNames}><span>{index + 1}&nbsp;</span><span className='me-1'>{item.name}-{item.whatsapp}</span><span>{item.status === 'success' ? <DoubleCheckIcon /> : item.status === 'not send' ? 'not send' :<CrossRedIcon />}</span></div>)}
            </div>
            {/* {templateDataLoading && <SpinnerWhite size="15" />} */}
            {templateError ?
              <p className='text-center'>{templateError}</p>
              :
              <>
                {templateDataLoading ? <Spinner css={styles.spinnerWhite} size="24" /> : <div className='d-flex justify-content-between'>
                  <div className='text-primary fw-bold'>Total - {sendStatus.total && sendStatus.total}</div>
                  <div className={styles.success_color}>Success - {sendStatus.successful && sendStatus.successful}</div>
                  <div className='text-danger fw-bold'>Failed - {sendStatus.failed && sendStatus.failed}</div>
                </div>}
              </>
            }
            <div className={styles.dealerNameModal_btn}>
              <GreenOutButton title="CLOSE" handleSubmit={() => { setShowDealerNames(false); setSelectedNumber([]); setMessengerTemplate(); setSendTemplateText('') }} />
            </div>
          </div>
        }
      />

      <AddDealerModal
        show={showAddDealer}
        close={setShowAddDealer}
        setData={setDealerData}
      />

      <div className={styles.right_main}>
        <div className={styles.addDealers}>
          <h1 className={styles.dealers}>
            Dealers
          </h1>
          <GreenOutButton btnType="button" title={<><AddDealerIcon />&nbsp;&nbsp;ADD DEALER</>} css={styles.addDealerButton} handleSubmit={() => { setShowAddDealer(!showAddDealer) }} />
        </div>

        <div className={styles.Dealer_main_container}>
          <div className={styles.dealer_header}>
            <div
              className={styles.dealer_search_div}>
              <input type="search"
                placeholder='Search'
                className={styles.dealer_search}
                value={searchDealerData.searchDealerName}
                onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })}
              />
              <Search color="#ffffff" css={styles.dealer_search_icon} />
            </div>
            <div className={styles.dealer_header_right}>

              <div className={styles.whatsappMessenger} onClick={() => { setShowMessengerModal(true); dealerNumberData() }}>
                <PackingWhatsappIcon />&nbsp; Messenger
              </div>

              <div className={styles.dealer_Sort_By}>
                <label className={styles.dealer_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dealer_sortBy_select}
                  value={searchDealerData.sortByStatus}
                  onChange={(e) => setSearchDealerData({ ...searchDealerData, sortByStatus: e.target.value })}
                >
                  <option className={styles.dealer_sortBy_color} value="">View All</option>
                  <option className={styles.dealer_sortBy_color} value="Referral">Referral</option>
                  <option className={styles.dealer_sortBy_color} value="Widthout OTP">Widthout OTP</option>
                  <option className={styles.dealer_sortBy_color} value="Managers Created">Managers Created</option>
                  <option className={styles.dealer_sortBy_color} value="Self Created">Self Created</option>
                  <option className={styles.dealer_sortBy_color} value="New">New</option>
                  <option className={styles.dealer_sortBy_color} value="newestfirst">Newest First</option>
                  <option className={styles.dealer_sortBy_color} value="Pending">Not Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Verified">Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Ascending">Ascending</option>
                  <option className={styles.dealer_sortBy_color} value="Descending">Descending</option>
                </select>
              </div>
              <div className={styles.dealer_header_select_days}>
                <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
                <div className={styles.dealer_days_select}>15 Days</div>
                <div className={styles.dealer_days_select}>15-30 Days</div>
                <div className={styles.dealer_days_select}>30 Days</div>
              </div>
            </div>
          </div>
          <div className={styles.dealer_main_div}>
            {
              loading ? <div className={'loadingMainDealer'}>
                <img src='/wallicon.gif' alt='walliconGIF' />
              </div> :
                dealersData && dealersData.map((item, index) =>
                  <div className={styles.dealer_card} key={index}>
                    <div className={styles.dealer_card_div}>
                      <div onClick={() => { navigate("/dealers/" + item.dealerId) }} className={styles.cursor_pointer} >
                        <div className={styles.dealer_status} >
                          {item.status === "Rejected" ?
                            <Ban color="#F93E3E" css={styles.dealer_status_icon} />
                            : item.status === "Verified" ? <Checked color="#1FFC33" css={styles.dealer_status_icon} />
                              : item.status === "Pending" ? <Clock color="yellow" css={styles.dealer_status_icon} />
                                : item.status === "Update" ? <div className={styles.newIcon_div}> <UpdateIcon color="#f8f9fa" css={styles.newIcon} /></div>
                                  : <div className={styles.newIcon_div}>
                                    <div className={styles.newIcon}>New</div>
                                  </div>
                          }
                        </div>


                        {!item.profileImage ?
                          <div className={styles.name_char}><span className={styles.name_char_mb}>{item && item.name.charAt(0).toUpperCase()}</span></div>
                          :
                          <img src={process.env.REACT_APP_S3URL + item.profileImage} className={styles.dealer_image} alt='dealer_image' />
                        }

                        <h4 className={styles.dealer_name}>{item.name}</h4>
                        <div className={styles.dealeStore_owner}>{item.designation}</div>
                        <div className={item.storeName ? "" : styles.storeName_hide}>
                          <div className={styles.dealeStore_owner}>at</div>
                          <div className={styles.dealeStore_owner}>{item.storeName}</div>
                        </div>
                      </div>
                      <div className={styles.dealer_btn_div}>
                        <button className={styles.dealer_Btn}>
                          <Messanger color="#ffffff" width={16} />
                        </button>
                        <a href={'tel:+91' + item.mobile} className={styles.dealer_Btn}>
                          <Call color="#ffffff" width={16} />
                        </a>
                        <a target="_blank" rel="noreferrer" href={"https://wa.me/+91" + item.whatsapp} className={styles.dealer_Btn}>
                          <WhatsApp width={16} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
          </div>
          <Pagination
            total={pages}
            current={page}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dealers