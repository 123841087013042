import React, { useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { FileHandler } from '../../components/DragAndDrop'
import { AddDealerIcon, Close, DeleteIcon, Search, Spinner, ThreeDots } from '../../components/icons/Icon'
import styles from './Accounts.module.css'

const Accounts = () => {
    const navigate = useNavigate()
    const { managerId } = useAuth();
    const [show, setShow] = useState(false);
    const [steps, setSteps] = useState(0);
    const [msg, setMsg] = useState('')
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false)
    const [activeDisable, setActiveDisable] = useState('')
    const [securityPinModal, setSecurityPinModal] = useState(true)
    const [iPInfo, setIPInfo] = useState('')
    const [securityPin, setSecurityPin] = useState('')
    const [acToken, setAcToken] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    let isPinTrue = sessionStorage.getItem("security")
    let actoken = sessionStorage.getItem("actoken")
    const [buttonLoading, setButtonLoading] = useState(false)

    useEffect(() => {

        if (actoken) {
            setAcToken(actoken)
        }
    }, [actoken])

    useEffect(() => {

        if (isPinTrue) {
            setSecurityPinModal(false)
        }
    }, [isPinTrue])

    // Ip info

    navigator.browserDetection = (function () {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();

    // console.log(navigator.browserDetection); // outputs: `Chrome 92`
    const browserName = navigator.browserDetection.replace(/[0-9 ]/g, '')

    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    const operatinySystem = getOS()

    useEffect(() => {
        getIPInfo()
    }, [])

    const getIPInfo = () => {
        fetch('https://ipapi.co/json/')
            .then(res => res.json())
            .then(result => {
                setIPInfo(result)
            })
    }
    // -------------over Lay
    useEffect(() => {
        if (showOverlay) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [showOverlay])

    // console.log(isPinTrue)

    useEffect(() => {
        if (isPinTrue && steps === 0) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("actoken", acToken);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "admin/accounts", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.accounts)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [isPinTrue, steps, acToken, managerId])

    // Pin Security
    const handleVerifySecurityPin = () => {
        setLoading(true)
        setData('')
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "securityPin": securityPin,
            "ip": iPInfo.ip,
            "city": iPInfo.city,
            "country": iPInfo.country_name,
            "state": iPInfo.region,
            'browser': browserName,
            'os': operatinySystem,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + 'admin/accounts', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    sessionStorage.setItem("security", true)
                    sessionStorage.setItem("actoken", result.actoken)
                    setAcToken(result.actoken)
                    setSecurityPinModal(false)
                } else {
                    setMsg(result.msg)
                }
                setLoading(false)
            })
            .catch(error => console.log('error', error));
    }

    const [isImage, setisImage] = useState();

    //---------------- image upload--------------------------
    const [ImgData, setImgData] = useState()
    const [showModal, setShowModal] = useState(false)
    const [inputImg, setInputImg] = useState('')

    useEffect(() => {
        if (isImage) {
            setInputImg(isImage[0])
            setShowModal(true)
        }
    }, [isImage])

    var editor = "";
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 2,
        croppedImg: "",
        position: {
            x: 0,
            y: 0,
        },
    });

    const handleScale = e => {
        const scale = parseFloat(e.target.value)
        setPicture({
            ...picture,
            zoom: scale
        });
    }
    const handlePositionChange = position => {
        setPicture({
            ...picture,
            position
        });
    }

    const setEditorRef = (ed) => {
        editor = ed;
    };

    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            canvasScaled.toBlob((blob) => {
                setImgData(blob)
                // uploadProfile(blob)
            });
        }
    };
    // ---------------------------------------------------------------------------------------

    const [validated, setValidated] = useState(false);
    const [accountInfo, setAccountInfo] = useState({
        cart: "Disable",
        pcart: "Disable",
        webCollection: "Disable",
    })
    const [validated1, setValidated1] = useState(false);
    const [userPassword, setUserPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const [otpToken, setOtpToken] = useState("");
    const [validated2, setValidated2] = useState(false);
    const [otps, setOtps] = useState({ phone: "", email: "" })

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setSteps(1)
        }

        setValidated(true);
    };

    const handleSubmitStep1 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handlePassword();
        }

        setValidated1(true);
    };


    const handlePassword = () => {
        if (userPassword.password !== userPassword.confirmPassword) {
            alert("Password not same!")
        } else {
            setButtonLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "password": userPassword.password
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "admin/addUpdateAc/" + managerId, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setOtpToken(result.token)
                        setSteps(2)
                    }
                    setButtonLoading(false)
                })
                .catch(error => console.log('error', error));
        }
    }

    const handleSubmitStep2 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleOTPS()
        }

        setValidated2(true);
    };

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + 'admin/checklogin', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        if (isPinTrue) {
                            setSecurityPinModal(false)
                        } else {
                            setSecurityPinModal(true)
                        }

                    } else {
                        alert(result.msg)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, isPinTrue])

    const handleOTPS = () => {
        if (!otps.phone && !otps.email) {
            alert("Password not same!")
        } else {
            // var myHeaders = new Headers();
            // myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            // myHeaders.append("Content-Type", "application/json");
            // myHeaders.append("token", optToken);

            // var raw = JSON.stringify({
            //     "smsOtp": otps.phone,
            //     "emailOtp": otps.email,
            // });

            // var requestOptions = {
            //     method: 'POST',
            //     headers: myHeaders,
            //     body: raw,
            //     redirect: 'follow'
            // };

            // fetch(process.env.REACT_APP_URL + "admin/addUpdateAc/" + managerId, requestOptions)
            //     .then(response => response.json())
            //     .then(result => {
            //         alert(result.msg)
            //         if (result.status === 'success') {
            //             setShow(false)
            //             setSteps(0)
            //         }
            //     })
            //     .catch(error => console.log('error', error));
            setButtonLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("token", otpToken);

            var formdata = new FormData();
            formdata.append("managerId", managerId);
            formdata.append("emailOtp", otps.email);
            formdata.append("smsOtp", otps.phone);
            formdata.append("acHolderName", accountInfo.acHolderName);
            formdata.append("bankName", accountInfo.bankName);
            formdata.append("accountNumber", accountInfo.accountNumber);
            formdata.append("ifscCode", accountInfo.ifscCode);
            formdata.append("branchName", accountInfo.branchName);
            formdata.append("cart", accountInfo.cart);
            formdata.append("pcart", accountInfo.pcart);
            formdata.append("webCollection", accountInfo.webCollection);
            formdata.append("upi", accountInfo.upi ? accountInfo.upi : "");
            formdata.append("qrcode", ImgData);
            formdata.append("status", accountInfo.status);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "admin/addUpdateAc/" + managerId + "?acId=" + (accountInfo._id && accountInfo._id), requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert(result.msg)
                    if (result.status === 'success') {
                        setShow(false)
                        setSteps(0)
                        setAccountInfo("")
                        setUserPassword('')
                        setOtps('')
                    }
                    setButtonLoading(false)
                })
                .catch(error => console.log('error', error));
        }
    }


    // const pinRef = useRef(null)

    // console.log(pinRef)
    // useEffect(() => {
    //     if (securityPin.length === 4) {
    //         pinRef.current && pinRef.current.click()
    //     }
    // }, [securityPin.length, pinRef])

    let type = ((accountInfo.cart === "Active") || (accountInfo.pcart === "Active")) ? false : true;

    return (
        <React.Fragment>
            <div className={styles.right_main}>
                <div className={styles.heading_div}>
                    <h1 className={styles.heading}>Accounts</h1>
                    <div>
                        <GreenOutButton
                            title={<><AddDealerIcon />&nbsp;&nbsp;ADD ACCOUNT</>}
                            css={styles.addAccount_btn}
                            handleSubmit={() => {
                                setShow(true);
                                setSteps(0);
                                setAccountInfo(prevState => ({
                                    ...prevState,
                                    cart: "Disable",
                                    pcart: "Disable",
                                    webCollection: "Disable",
                                }))
                            }}
                        />
                        <RedOutButton title={<><AddDealerIcon />&nbsp;&nbsp;LOG OUT</>} css={styles.addAccount_btn + " ms-3"}
                            handleSubmit={() => {
                                navigate("/dashboard");
                                sessionStorage.removeItem("security");
                                sessionStorage.removeItem("actoken");
                            }}
                        />
                    </div>
                </div>

                <div className={styles.main_container}>
                    <div className={styles.account_header}>
                        <div
                            className={styles.account_search_div}>
                            <input type="search"
                                placeholder='Search'
                                className={styles.account_search}
                            // value=''
                            />
                            <Search color="#ffffff" css={styles.account_search_icon} />
                        </div>
                    </div>

                    <div className={styles.account_main_div}>
                        <div className={styles.accountCard_div}>
                            {data && data.length !== 0 && data.map((item, index) =>
                                <div key={index} className={styles.accountCard} onClick={() => { setShow(true); setAccountInfo(item) }}>
                                    <div className={styles.ThreeDots} onClick={(e) => { if (showOverlay === index) { setShowOverlay(false) } else { setShowOverlay(index) }; e.stopPropagation() }}>
                                        <ThreeDots color='#FFFFFF' />
                                    </div>
                                    {showOverlay === index && <div className={styles.modal_backdrop} onClick={() => { setShowOverlay(); }}>
                                        <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}>
                                            {item.status === "Disable" ? <span className='text-success' onClick={() => { setShowOverlay(false); setActiveDisable('Active'); setShowWarningModal(true) }}>Active</span> :
                                                <span className='text-danger' onClick={() => { setShowOverlay(false); setActiveDisable('Disable'); setShowWarningModal(true) }}>Disable</span>}
                                        </div>
                                    </div>}
                                    <img src={process.env.REACT_APP_S3URL + item.qrcode} alt={item.accountNumber} className={styles.accountCard_img} />
                                    <h6>{item.bankName}</h6>
                                    <p>{item.ifscCode + " & " + item.branchName}</p>
                                    <p>{item.accountNumber}</p>
                                    <h5 className={(item.cart === "Active" || item.pcart === "Active") ?
                                        styles.statusActive : styles.statusDisable}>{item.status}
                                    </h5>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                <Modal show={securityPinModal} centered className={'acmodal'} size={steps === 0 && "md"}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between'>
                            <h2 className={styles.securityPin_header}>Security Pin <span className='text-danger'>Warning !</span></h2>
                            <span role={"button"} onClick={() => { window.history.back() }}><Close /></span>
                        </div>

                        <div className='d-flex flex-column justify-content-center align-items-center mb-4 mt-3'>
                            {/* <label className={styles.securityPin_heading}>Security Pin</label> */}
                            <input
                                type='number'
                                placeholder='Enter Your Security Pin'
                                className={styles.input_securitypin}
                                value={securityPin}
                                onChange={(e) => setSecurityPin(e.target.value)}
                                required
                            />
                            {msg && <label className={styles.alert_label}>{msg}</label>}
                        </div>
                        <div className={styles.warningPin_div}>
                            <GreenButton
                                disabled={securityPin.length === 4 ? false : true}
                                title={loading ? <Spinner size="15" /> : "VERIFY"}
                                // ref={pinRef}
                                handleSubmit={() => { handleVerifySecurityPin() }}
                            />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)} centered className={'acmodal'} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger'>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={styles.warningModal_subHeading}>Are You Sure Want To {activeDisable === 'Active' ? 'Active' : 'Disable'} Account</p>
                        {activeDisable === 'Disable' &&
                            <input type='text' placeholder='Write Disableing Reason' className={styles.inputField} />
                        }
                        <div className={styles.warningBtn_div}>
                            <GreenButton title="Yes" handleSubmit={() => setShowWarningModal(false)} />
                            <GreenOutButton title="No" handleSubmit={() => setShowWarningModal(false)} />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={show} centered className={'acmodal'} size={steps === 0 && "lg"}>
                    <Modal.Header>
                        <Modal.Title className='d-flex justify-content-between w-100'>
                            <div> {steps === 0 ? 'Add Bank Account' : steps === 1 ? 'Confirm Password' : ''} </div>

                            <span role='button' className='border border-2 rounded px-1' onClick={() => setShow(false)}>&#x2716;</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {steps === 0 &&
                            <>
                                {showModal ?
                                    <div className={styles.text_center}>
                                        <AvatarEditor
                                            ref={setEditorRef}
                                            image={inputImg}
                                            width={200}
                                            height={200}
                                            rotate={0}
                                            scale={picture.zoom}
                                            onPositionChange={handlePositionChange}
                                        // style={{ width: "100%", height: "100%", }}
                                        />
                                        <div className={styles.inputRange}>
                                            <input type="range" min="1" max="10" step="0.1" value={picture.zoom} onChange={handleScale} />
                                        </div>
                                        <div className={styles.recipt_btn}>
                                            <GreenOutButton title="CANCEL" handleSubmit={() => setShowModal(false)} />
                                            <GreenButton title="SUBMIT" btnType="submit" handleSubmit={() => { handleSave(); setShowModal(false) }} />
                                        </div>
                                    </div>

                                    :
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01" className='mb-3'>
                                                <Form.Label>A/C Holder Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter A/C Holder Name"
                                                    value={accountInfo.acHolderName}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        acHolderName: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01" className='mb-3'>
                                                <Form.Label>Bank Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Bank Name"
                                                    value={accountInfo.bankName}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        bankName: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02" className='mb-3'>
                                                <Form.Label>Account Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    placeholder="Enter Account Number"
                                                    value={accountInfo.accountNumber}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        accountNumber: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom03" className='mb-3'>
                                                <Form.Label>IFSC Code</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter IFSC Code"
                                                    value={accountInfo.ifscCode}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        ifscCode: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom04" className='mb-3'>
                                                <Form.Label>Branch Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Branch Name"
                                                    value={accountInfo.branchName}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        branchName: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                            {/* <Form.Group controlId="validationCustom05" className='mb-3'>
                                                    <Form.Label>UPI Id</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Enter UPI Id"
                                                    />
                                                </Form.Group> 
                                        */}
                                            <Form.Group as={Col} controlId="validationCustom05" className='mb-3'>
                                                <Form.Label>UPI Id</Form.Label>
                                                <Form.Control
                                                    // required
                                                    type="text"
                                                    placeholder="Enter UPI Id"
                                                    value={accountInfo.upi}
                                                    onChange={(e) => setAccountInfo(prevState => ({
                                                        ...prevState,
                                                        upi: e.target.value
                                                    }))}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} xs="1" className="mb-3">
                                                <Form.Label>Type </Form.Label>
                                                <Form.Check
                                                    required={type}
                                                    label="Cart"
                                                    className={styles.typeCheck}
                                                    name="type"
                                                    value={accountInfo.cart}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                cart: "Active"
                                                            }))
                                                        } else {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                cart: "Disable"
                                                            }))
                                                        }
                                                    }}
                                                    checked={accountInfo.cart === "Active" ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} xs="2" className="mb-3 pe-0">
                                                <Form.Label>&nbsp; </Form.Label>
                                                <Form.Check
                                                    required={type}
                                                    label="Partner Cart"
                                                    className={styles.typeCheck}
                                                    name="type"
                                                    value={accountInfo.pcart}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                pcart: "Active"
                                                            }))
                                                        } else {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                pcart: "Disable"
                                                            }))
                                                        }
                                                    }}
                                                    checked={accountInfo.pcart === "Active" ? true : false}
                                                />
                                            </Form.Group>



                                            <Form.Group as={Col} xs="3" className="mb-3 pe-0">
                                                <Form.Label>&nbsp; </Form.Label>
                                                <Form.Check
                                                    required={type}
                                                    label="Web Collection"
                                                    className={styles.typeCheck}
                                                    name="type"
                                                    value={accountInfo.webCollection}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                webCollection: "Active"
                                                            }))
                                                        } else {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                webCollection: "Disable"
                                                            }))
                                                        }
                                                    }}
                                                    checked={accountInfo.webCollection === "Active" ? true : false}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} className="mb-3">
                                                <Form.Label>Status </Form.Label>
                                                <Form.Check
                                                    type='radio'
                                                    required
                                                    label="Active"
                                                    feedbackType="invalid"
                                                    className={styles.typeCheck}
                                                    name="status"
                                                    value={accountInfo.status}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                status: "Active"
                                                            }))
                                                        } else {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                status: ""
                                                            }))
                                                        }
                                                    }}
                                                    checked={accountInfo.status === "Active" ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3 pe-0">
                                                <Form.Label>&nbsp; </Form.Label>
                                                <Form.Check
                                                    type='radio'
                                                    required
                                                    label="Disable"
                                                    feedbackType="invalid"
                                                    className={styles.typeCheck}
                                                    name="status"
                                                    value={accountInfo.status}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                status: "Disable"
                                                            }))
                                                        } else {
                                                            setAccountInfo(prevState => ({
                                                                ...prevState,
                                                                status: ""
                                                            }))
                                                        }
                                                    }}
                                                    checked={accountInfo.status === "Disable" ? true : false}

                                                />
                                            </Form.Group>


                                            {(ImgData || accountInfo.qrcode) ?
                                                <>
                                                    <div className={styles.reciptImage_div}>
                                                        <div className={styles.Image_div}>
                                                            <img
                                                                src={ImgData ? URL.createObjectURL(ImgData) : process.env.REACT_APP_S3URL + accountInfo.qrcode}
                                                                alt='Recipt'
                                                                onClick={() => window.open(ImgData ? URL.createObjectURL(ImgData) : process.env.REACT_APP_S3URL + accountInfo.qrcode, "mozillaWindow", "popup")}
                                                                className={styles.reciptImage}
                                                            />
                                                        </div>
                                                        <div className={styles.reciptImage_delete} onClick={() => setImgData()}><DeleteIcon /><span>Delete</span></div>
                                                    </div>
                                                    <p className={styles.viewRecipt}>*Click To View Screenshot</p>
                                                </>
                                                :
                                                <>
                                                    <div className={styles.dragImage_div}>
                                                        <FileHandler
                                                            setisImage={setisImage}
                                                            styles={styles}
                                                        />
                                                    </div>
                                                </>
                                            }


                                        </Row>

                                        <Row className='text-center mt-3'>
                                            <Col>
                                                <RedOutButton btnType="button" title="Cancel" handleSubmit={() => setShow(false)} />
                                            </Col>
                                            <Col>
                                                <GreenButton title={accountInfo._id ? "Update & Next" : "Next"} />
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                            </>}

                        {steps === 1 &&
                            <Form noValidate validated={validated1} onSubmit={handleSubmitStep1}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={userPassword.password}
                                        onChange={(e) => setUserPassword(prevState => ({ ...prevState, password: e.target.value }))}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm Password"
                                        value={userPassword.confirmPassword}
                                        onChange={(e) => setUserPassword(prevState => ({ ...prevState, confirmPassword: e.target.value }))}
                                        required={((userPassword.password !== userPassword.confirmPassword)) ? true : false}
                                    />
                                </Form.Group>
                                <Row className='text-center mt-5'>
                                    <Col>
                                        <RedOutButton btnType="button" title="Cancel" handleSubmit={() => setShow(false)} />
                                    </Col>
                                    <Col>
                                        <GreenButton title={buttonLoading ? <Spinner size="15" /> : "Next"} />
                                    </Col>
                                </Row>
                            </Form>
                        }

                        {steps === 2 &&
                            <Form noValidate validated={validated2} onSubmit={handleSubmitStep2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone OTP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone OTP"
                                        required
                                        value={otps.phone}
                                        onChange={(e) => { setOtps(prevState => ({ ...prevState, phone: e.target.value })) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email OTP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email OTP"
                                        required
                                        value={otps.email}
                                        onChange={(e) => { setOtps(prevState => ({ ...prevState, email: e.target.value })) }}
                                    />
                                </Form.Group>
                                <Row className='text-center mt-5'>
                                    <Col>
                                        <RedOutButton btnType="button" title="Cancel" handleSubmit={() => setShow(false)} />
                                    </Col>
                                    <Col>
                                        <GreenButton title={buttonLoading ? <Spinner size="15" /> : "Submit"} />
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Accounts