import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from './AddItemModalMob.module.css'
import { DeleteIcon, DemoImageIcon, UpLoadFile } from '../../components/icons/Icon';
import ModalMob from './ModalMob';

const AddItemModalMob = ({ setShowAddItem, showAddItem, setItemsdetails, index }) => {

    const { managerId } = useAuth()
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [file, setFile] = useState();
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    // const [products, setProducts] = useState([])
    // const [collections, setCollections] = useState([])
    const [addNewItemData, setAddNewItemData] = useState({
        product_name: '',
        product_Id: '',
        collection_name: '',
        collection_id: '',
        itemNo: '',
        unit: '',
        description: '',
        products: [],
        collections: [],
    })

    const handleSubmitAddItem = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleAddItemApi()
        }
    };

    useEffect(() => {
        if (showAddItem) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [showAddItem])


    const handleAddItemApi = () => {
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("image", file ? file : "");
        formdata.append("product_Id", addNewItemData.product_Id);
        formdata.append("collection_id", addNewItemData.collection_id);
        formdata.append("product_name", addNewItemData.product_name);
        formdata.append("collection_name", addNewItemData.collection_name);
        formdata.append("unit", addNewItemData.unit);
        formdata.append("price", addNewItemData.price);
        formdata.append("description", addNewItemData.description);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + `inventory/addUpdateOutsideItem/${addNewItemData.itemNo}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                    setShowAddItem(false)
                    setItemsdetails(prevState => ([
                        ...prevState.slice(0, index),
                        {
                            itemNo: result.data.itemNo,
                            product_name: result.data.product_name,
                            unit: result.data.unit,
                            price: result.data.price,
                            productimg: result.data.productimg,
                        },
                        ...prevState.slice(index + 1)
                    ]))
                }
                else {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_name": addNewItemData.product_name
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/productsColl", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setAddNewItemData(prevState => ({
                            ...prevState,
                            products: result.data.products ? result.data.products : [],
                            collections: result.data.collections ? result.data.collections : [],
                        }))
                    }
                    // setproducts(result.data.products)
                }
                )
                .catch(error => console.log('error', error));
        }
    }, [managerId, addNewItemData.product_name])

    return showAddItem && (
        <div className={styles.modal_backdrop} onClick={() => { setShowAddItem(false) }}>
            <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}  >
                <h3 className={styles.addDealer_heading}>Add New Item</h3>
                <form onSubmit={handleSubmitAddItem}>

                    <div>
                        <label className={styles.order_lebel}>Product Name</label>
                        <input type="text" list="prodcuts"
                            className={styles.AddDealer_input}
                            value={addNewItemData.product_name}
                            onChange={(e) => setAddNewItemData({
                                ...addNewItemData,
                                product_name: e.target.value,
                            })}
                            autoFocus
                        />
                        <datalist id="prodcuts">
                            {addNewItemData.products && addNewItemData.products.map((item, index) =>
                                <option key={index} >{item.product_name}</option>
                            )}
                        </datalist>
                    </div>


                    <div>
                        <label className={styles.order_lebel}>Collection Name</label>
                        <input type="text" list="collections"
                            className={styles.AddDealer_input}
                            value={addNewItemData.collection_name}
                            onChange={(e) => setAddNewItemData({
                                ...addNewItemData,
                                collection_name: e.target.value,
                            })}
                        />
                        <datalist id="collections">
                            {addNewItemData.collections && addNewItemData.collections.map((item, index) =>
                                <option key={index} >{item.collection_name}</option>
                            )}
                        </datalist>
                    </div>

                    <div>
                        <label className={styles.order_lebel}>Item Number</label>
                        <input
                            type="text"
                            className={styles.AddDealer_input}
                            placeholder="Enter Item Number"
                            value={addNewItemData.itemNo}
                            onChange={(e) => setAddNewItemData({ ...addNewItemData, itemNo: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <label className={styles.order_lebel}>Unit</label>

                        <input type="text" list="units"
                            className={styles.AddDealer_input}
                            value={addNewItemData.unit}
                            onChange={(e) => setAddNewItemData({
                                ...addNewItemData,
                                unit: e.target.value,
                            })}
                        />
                        <datalist id="units">
                            <option value="Roll" >Roll</option>
                            <option value="Pcs">Pcs</option>
                            <option value="Box">Box</option>
                            <option value="Sq Meter">Sq Meter</option>
                            <option value="Meter">Meter</option>
                            <option value="Sq Feet">Sq Feet</option>
                            <option value="Feet">Feet</option>
                            <option value="Kg">Kg</option>
                        </datalist>
                    </div>

                    <div>
                        <label className={styles.order_lebel}>Price</label>
                        <input
                            type="number"
                            className={styles.AddDealer_input}
                            placeholder="Enter Price"
                            value={addNewItemData.price}
                            onChange={(e) => setAddNewItemData({ ...addNewItemData, price: e.target.value })}
                            required
                        />
                    </div>

                    <div>
                        <label className={styles.order_lebel}>Description <span className={styles.unbold_text}>(optional)</span></label>
                        <input
                            type="text"
                            className={styles.AddDealer_input}
                            placeholder="Enter Description"
                            value={addNewItemData.description}
                            onChange={(e) => setAddNewItemData({ ...addNewItemData, description: e.target.value })}
                        />
                    </div>

                    <div className={styles.uploadImage_div}>
                        <label onChange={handleChange} className={styles.uploadImage_btn}>
                            <input name="" type="file" id="formId" hidden />
                            <UpLoadFile />&nbsp;&nbsp;Upload Image
                        </label>
                        {file ?
                            <div className={styles.demoImage_div}> <img src={file} alt="" className={styles.modalItem_Image} /></div> :
                            <div className={styles.demoImage_div}>
                                <div className={styles.demoImage_bgc}>
                                    <DemoImageIcon />
                                </div>
                            </div>
                        }
                        <div className={styles.delete_div} onClick={() => setFile()}>
                            <span><DeleteIcon /></span>
                            <span>Delete</span>
                        </div>
                    </div>

                    <div className={styles.orders_flex}>
                        <button className={styles.order_btn} onClick={() => setShowAddItem(false)}>CANCEL</button>
                        <button btnType="submit" className={styles.order_btn1}>ADD</button>
                    </div>
                </form>

                <ModalMob
                    show={showAcceptModal}
                    close={setShowAcceptModal}
                    css={styles.date_margin_top}
                    content={
                        <div className={styles.text_center} >
                            <p className={styles.massageModal_btn}>{msgShow}</p>
                            <div className={styles.orders_flex}>
                                <button className={styles.order_btn} onClick={() => setShowAcceptModal(false)}>CANCEL</button>
                                <button onClick={() => setShowAcceptModal(false)} className={styles.order_btn1}>OK</button>
                            </div>
                        </div>
                    }
                />

            </div>
        </div>
    )
}

export default AddItemModalMob