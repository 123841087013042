import React from 'react'

const Alert = ({ msg, showElement, setShowElement }) => {
    React.useEffect(() => {
        if (showElement) {
            setTimeout(function () {
                setShowElement(false)
            }, 2000);
        }
    }, [showElement, setShowElement])
    return showElement && (
        <div className='wishAlert'>
            {msg}
        </div>
    )
}

export default Alert