import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GreenOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import Pagination from '../../components/Paginantion';
import styles from './DishpatchManager.module.css'
const Packers = () => {

    const navigate = useNavigate();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

    const { switchDisplay, managerId } = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const [state, setState] = React.useState({
        totalPages: "",
        currentPage: 1
    });

    const { totalPages, } = state;

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "dispatch/packers?page=" + page, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setLoading(false)
                        setData(result.data)
                    }
                    if (result.pages) {
                        setState(prevState => ({
                            ...prevState,
                            totalPages: result.pages ? result.pages : "",
                            currentPage: result.page
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page])

    return (
        <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.ff}`}>
            <div className={styles.flexdiv}>
                <h1 className={styles.heading}>Packers</h1>
                <div className={styles.flexdiv}>
                    <div className={styles.Sort_By}>
                        <label className={styles.sortBy_txt}>Sort By </label>
                        <select
                            className={styles.sortBy_select}
                        >
                            <option className={styles.sortBy_color} value="">View All</option>
                            <option className={styles.sortBy_color} value="New">New</option>
                            <option className={styles.sortBy_color} value="newestfirst">Newest First</option>
                            <option className={styles.sortBy_color} value="Pending">Not Verified</option>
                            <option className={styles.sortBy_color} value="Verified">Verified</option>
                            <option className={styles.sortBy_color} value="Ascending">Ascending</option>
                            <option className={styles.sortBy_color} value="Descending">Descending</option>
                        </select>
                    </div>
                    <GreenOutButton title="CREATE PACKER" css={styles.createPackerBtn} handleSubmit={() => navigate("/createpacker")} />
                </div>
            </div>

            {
                loading ? <div className={'loadingMain'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    <>

                        <div className={styles.heightFix_div}>
                            <table className={styles.itemTable}>
                                <thead>
                                    <tr className={styles.item}>
                                        <th>Name</th>
                                        <th>Orders</th>
                                        <th>New Orders</th>
                                        <th>In Progress</th>
                                        <th>Packed</th>
                                        <th>Last Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((val, index) =>
                                        <tr key={index} className={styles.item} onClick={() => navigate('/packer/' + val.pmId)} >
                                            <td>{val.name} {val.city}</td>
                                            <td>{val.orders}</td>
                                            <td>{val.newOrders}</td>
                                            <td>{val.inprogress}</td>
                                            <td>{val.packed}</td>
                                            <td>{val.lastAction}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <Pagination
                            total={totalPages}
                            current={page}
                        />
                    </>
            }
            {/* <div className={styles.packers_float}>
                <p className={styles.selectheading}>Sort By</p>
                <select className={`${switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.select}`}>
                    <option >Today</option>
                    <option >Tommorow</option>
                    <option >This Week</option>
                    <option >This Month</option>
                    <option >From Date to Date</option>
                    <option >02-10-2021<span><Edit color="#000" /></span></option>
                </select>
            </div>
            <table className={styles.customers}>
                <tr className={`${switchDisplay ? styles.bg : styles.bg2}`}>
                    <th>Name</th>
                    <th>Orders</th>
                    <th>In Progress</th>
                    <th>Packed</th>
                    <th>Last Action</th>
                </tr>
                {data && data.map((val, key) =>
                    <>
                        <br />
                        <tr key={key} className={`${switchDisplay ? styles.bg : styles.bg2}`} onClick={() => navigate("/packers/:")}>
                            <td>{val.name} {val.city}</td>
                            <td>{val.orders}</td>
                            <td>{val.progress}</td>
                            <td>{val.packed}</td>
                            <td>{val.action}</td>
                        </tr>
                    </>
                )}
            </table> */}
        </div>
    )
}

export default Packers;