import React, { useState, useEffect, useRef } from 'react'
import Pagination from '../../components/Paginantion'
import styles from './Templates.module.css'
import { Search, Spinner, ThreeDots } from '../../components/icons/Icon'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import Modal from '../../components/Modal'
import io from 'socket.io-client';

const SOCKET_SERVER_URL = process.env.REACT_APP_URL;

const Templates = () => {
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const [data, setData] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    // const [showOptions, setShowOptions] = useState()
    const [showOverlay, setShowOverlay] = useState(false)
    const [showViewDeleteStatus, setShowViewDeleteStatus] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [reload, setReload] = useState(false)
    const [deleteText, setDeleteText] = useState('')
    const [mobileText, setMobileText] = useState()
    const [singleTemplate, setSingleTemplate] = useState({})
    const [state, setState] = React.useState({
        totalPages: "",
        currentPage: 1
    });

    const { totalPages, currentPage } = state;

    const handlePagination = (current) => {
        setState({ ...state, currentPage: current });
    };



    // useEffect(() => {
    //     if (managerId) {
    //         const myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         const raw = JSON.stringify({
    //             "managerId": managerId
    //         });

    //         const requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: "follow"
    //         };

    //         fetch(process.env.REACT_APP_URL + "wallikonChat/templateList", requestOptions)
    //             .then((response) => response.json())
    //             .then((result) => {
    //                 if (result.status === 'success') {
    //                     setData(result.data)
    //                 }
    //             })
    //             .catch((error) => console.error(error));
    //     }
    // }, [managerId, reload])

    // useEffect(() => {
    //     const socket = io('http://192.168.0.103:5200'); // Connect to the Socket.io server

    //     socket.on('dataUpdated', (updatedData) => {
    //         setData(updatedData); // Update the state with the new data
    //     });

    //     // Cleanup the socket connection when the component unmounts
    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);


    // useEffect(() => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //     myHeaders.append("Content-Type", "application/json");

    //     const raw = JSON.stringify({
    //         "managerId": managerId
    //     });

    //     const requestOptions = {
    //         method: "POST",
    //         // headers: myHeaders,
    //         // body: raw,
    //         redirect: "follow"
    //     };

    //     fetch("http://192.168.0.103:5200/metadata/templateList", requestOptions)
    //         // fetch(process.env.REACT_APP_URL + "wallikonChat/templateList", requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             if (result.status === 'success') {
    //                 setData(result.data);
    //             }
    //         })
    //         .catch((error) => console.error(error));

    //     // Listen for real-time messages
    //     socket.on('message', templateList => {
    //         setData(prevMessages => [...prevMessages, { templateList }]);
    //     });

    //     return () => {
    //         socket.off('message');
    //     };
    // }, [managerId]);


    useEffect(() => {
        // Establish socket connection
        const socket = io(SOCKET_SERVER_URL);

        // Listen for templateList event
        socket.on('templateList', (updatedTemplates) => {
            // console.log('updatedTemplates', updatedTemplates);
            setData(updatedTemplates);
        });

        // Fetch initial template list
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

            fetch(process.env.REACT_APP_URL + "wallikonChat/templateList", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    setData(result.data);
                }
            })
            .catch((error) => console.error(error));
        // Clean up the socket connection on component unmount
        return () => {
            socket.disconnect();
        };
    }, [managerId, reload]);



    // console.log('data', data);

    // useEffect(() => {
    //     const socket = io('http://192.168.0.103:5200');

    //     socket.on('connect', () => {
    //         console.log('Connected to socket server');
    //     });


    //     if (managerId) {
    //         const fetchData = () => {
    //             const myHeaders = new Headers();
    //             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //             myHeaders.append("Content-Type", "application/json");

    //             const raw = JSON.stringify({
    //                 "managerId": managerId
    //             });

    //             const requestOptions = {
    //                 method: "POST",
    //                 // headers: myHeaders,
    //                 // body: raw,
    //                 redirect: "follow"
    //             };

    //             fetch("http://192.168.0.103:5200/metadata/templateList", requestOptions)
    //             // fetch(process.env.REACT_APP_URL + "wallikonChat/templateList", requestOptions)
    //                 .then((response) => response.json())
    //                 .then((result) => {
    //                     if (result.status === 'success') {
    //                         setData(result.data);
    //                     }
    //                 })
    //                 .catch((error) => console.error(error));
    //         };

    //         fetchData();

    //         socket.on('templateListUpdate', (updatedData) => {
    //             setData(updatedData);
    //         });
    //     }

    //     return () => {
    //         console.log('disconnecting...');
    //         socket.disconnect();
    //     };
    // }, [managerId, reload]);





    const markReadRef = useRef(null);
    const handleClickOutsideOptions = (event) => {
        if (markReadRef.current && !markReadRef.current.contains(event.target)) {
            // setShowOptions()
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutsideOptions);
        return () => {
            document.removeEventListener("click", handleClickOutsideOptions);
        };
    }, []);

    const handleChangeStatus = () => {
        setReload(true)
        setLoadingBtn(true)
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "templateName": templateName
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/changeTempStatus", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    alert(result.msg);
                    setShowViewDeleteStatus(false)
                    setReload(false)
                    setLoadingBtn(false)
                } else {
                    alert(result.msg)
                    setShowViewDeleteStatus(false)
                    setLoadingBtn(false)
                }
            })
            .catch((error) => console.error(error));
    }

    const handleConfirmDelete = () => {
        // const socket = io('http://192.168.0.103:5200');
        // socket.on('templateDeleted', (data) => {
        //     console.log('Template deleted:', data.templateName);
        //     // Update your UI accordingly
        // });
        // setReload(true)
        setLoadingBtn(true)
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        const raw = "";

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

            fetch(`${process.env.REACT_APP_URL}wallikonChat/deleteTemplate?managerId=${managerId}&templateName=${templateName}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    alert(result.msg);
                    setShowViewDeleteStatus(false)
                    setLoadingBtn(false)
                    setReload(!reload)
                } else {
                    alert(result.msg)
                    setShowViewDeleteStatus(false)
                    setLoadingBtn(false)
                }
            })
            .catch((error) => console.error(error));
        // return () => {
        //     socket.disconnect();
        // };
    }

    const handleViewTemplate = () => {
        if (singleTemplate.headerType === 'document') {
            setLoadingBtn(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "dealerPhoneNumber": [mobileText],
                "templateName": singleTemplate.templateName,
                "languageCode": singleTemplate.language,
                "headerType": singleTemplate.headerType,
                "headerData": singleTemplate.header,
                "documentFilename": singleTemplate.documentName
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

                fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", requestOptions)
                .then((response) => response.json())
                .then(result => {
                    if (result.status === 'Template Successfully Sent') {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    } else {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    }
                })
                .catch((error) => console.error(error));
        }

        else if (singleTemplate.headerType === 'video') {
            setLoadingBtn(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "dealerPhoneNumber": [mobileText],
                "templateName": singleTemplate.templateName,
                "languageCode": singleTemplate.language,
                "headerType": singleTemplate.headerType,
                "headerData": singleTemplate.header,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", requestOptions)
                .then((response) => response.json())
                .then(result => {
                    if (result.status === 'Template Successfully Sent') {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    } else {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    }
                })
                .catch((error) => console.error(error));
        }
        else if (singleTemplate.headerType === 'image') {
            setLoadingBtn(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "dealerPhoneNumber": [mobileText],
                "templateName": singleTemplate.templateName,
                "languageCode": singleTemplate.language,
                "headerType": singleTemplate.headerType,
                "headerData": singleTemplate.header,
                // "buttonIndex": "1",
                // "buttonDataPayload": "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14"
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", requestOptions)
                .then((response) => response.json())
                .then(result => {
                    if (result.status === 'Template Successfully Sent') {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    } else {
                        alert(result.status)
                        setShowViewDeleteStatus(false)
                        setLoadingBtn(false)
                    }
                })
                .catch((error) => console.error(error));
        }
        else if (singleTemplate.headerType === 'location') {
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "dealerPhoneNumber": [mobileText],
                "templateName": singleTemplate.templateName,
                "languageCode": singleTemplate.language,
                "headerType": singleTemplate.headerType,
                "latitude": "26.8428008",
                "longitude": "80.9359821",
                "locationName": "Wallicon Private Limited",
                "locationAddress": "Wallicon, Burlington, Aminabad Road, Lucknow",
                "buttonIndex": "1",
                "buttonDataPayload": "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14"
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", requestOptions)
                .then((response) => response.json())
                .then(result => {
                    if (result.status === 'Template Successfully Sent') {
                        alert(result.status)
                        setShowViewDeleteStatus(false)

                    }
                })
                .catch((error) => console.error(error));
        }
        else {
            alert('Please select file type')
        }
    };
    // console.log('singleTemplate', singleTemplate);

    return (
        <React.Fragment>
            <div className={styles.main_container}>

                <div className={styles.complain_header}>
                    <h2 className={styles.heading}>Whatsapp Templates</h2>
                </div>

                <div className={styles.complain_header_right}>
                    <div className='d-flex'>
                        <div className={styles.complain_search_div}>
                            <input type="search"
                                placeholder='Search'
                                className={styles.dealer_search}
                            />
                            <Search color="#ffffff" css={styles.dealer_search_icon} />
                        </div>
                        <div className={styles.complain_Sort_By}>
                            <label className={styles.complain_sortBy_txt}>Sort By </label>
                            <select
                                className={styles.complain_sortBy_select}
                            >
                                <option className={styles.dealer_sortBy_color} value="viewall">View All</option>
                                <option className={styles.dealer_sortBy_color} value="new">New</option>
                                <option className={styles.dealer_sortBy_color} value="newestfirst">Newest First</option>
                                <option className={styles.dealer_sortBy_color} value="notverified">Not Verified</option>
                                <option className={styles.dealer_sortBy_color} value="varified">Verified</option>
                                <option className={styles.dealer_sortBy_color} value="ascending">Ascending</option>
                                <option className={styles.dealer_sortBy_color} value="descending">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className=''>
                        <GreenOutButton type="button" title="+ ADD TEMPLATE" css="" handleSubmit={() => { navigate("/templates/template") }} />
                    </div>


                </div>

                <div className={styles.table_heading_div}>
                    <span className={styles.table_heading_name}>Name</span>
                    <span className={styles.table_heading}>Type</span>
                    <span className={styles.table_heading}>Hit</span>
                    <span className={styles.table_heading}>Category</span>
                    <span className={styles.table_heading}>Language</span>
                    <span className={styles.table_heading}>Status</span>
                    <span className={styles.table_heading_msg}>Message</span>
                    <span className={styles.table_heading}>Attachment Type</span>
                    <span className={`${styles.table_heading} text-center`}>Updated On</span>
                    {/* <span className={`${styles.table_heading} text-center`}>Updated On</span> */}
                    <span className={styles.table_heading_threeDot}></span>
                </div>

                <div className={styles.scrollTable}>
                    {
                        data && data.map((item, index) =>
                            <div key={index} className={styles.table_content_div} onClick={() => { navigate("/templates/template?templateName=" + item.templateName) }}>
                                <span className={styles.table_txt_name}>{item.templateName && (item.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))}</span>
                                <span className={styles.table_txt}>type</span>
                                <span className={styles.table_txt}>hit</span>
                                <span className={styles.table_txt}>{item.category}</span>
                                <span className={styles.table_txt}>{item.language}</span>
                                <span className={`${styles.table_txt} ${item.templateSent === false ? 'text-success fw-bold' : 'text-primary fw-bold'}`}>{item.templateSent === false ? 'Unsent' : item.templateSent === true ? 'Sent' : ''}</span>
                                <span className={styles.table_txt_msg}>{item.body}</span>
                                <span className={styles.table_txt}>{item.headerType}</span>
                                <span className={`${styles.table_txt} text-center`}>{item.updatedAt.slice(8, 10) + item.updatedAt.slice(4, 8) + item.updatedAt.slice(0, 4)}</span>
                                <span className={styles.table_heading_threeDot}
                                    onClick={(e) => {
                                        e.stopPropagation(); setShowOverlay(index);
                                    }}
                                >
                                    <ThreeDots color='#FFFFFF' />
                                </span>
                                {showOverlay === index &&
                                    <div className={styles.modal_backdrop} onClick={(e) => { e.stopPropagation(); setShowOverlay(); }}>
                                        <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}>
                                            {
                                                <>
                                                    <span
                                                        onClick={() => {
                                                            setShowOverlay(false);
                                                            setShowViewDeleteStatus('view');
                                                            setSingleTemplate(item)
                                                            setTemplateName(item.templateName)
                                                        }}>Testing View
                                                    </span>
                                                    <span onClick={() => {
                                                        setShowOverlay(false);
                                                        setShowViewDeleteStatus('status');
                                                        setTemplateName(item.templateName)
                                                    }}>Change Status</span>
                                                    <span
                                                        onClick={() => {
                                                            setShowOverlay(false);
                                                            setShowViewDeleteStatus('delete');
                                                            setTemplateName(item.templateName)
                                                        }}>Delete Template
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        )
                    }
                </div>
                <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePagination(crPage)}
                />

                <Modal
                    show={showViewDeleteStatus}
                    close={setShowViewDeleteStatus}
                    closeBtn={true}
                    heading={showViewDeleteStatus === 'view' ? 'View Template - ' + templateName : showViewDeleteStatus === 'delete' ? 'Delete Template - ' + templateName : 'Change Status - ' + templateName}
                    content={
                        <div className={styles.text_center}>
                            <div className='d-flex justify-conten-center flex-column'>
                                {showViewDeleteStatus === 'delete' ?
                                    <div className='d-flex flex-column'>
                                        <p className={styles.message}>To confirm! type <span className='text-danger'>delete</span> in the text input field.</p>
                                        <input type='text' maxLength={6} className={styles.input_delete} value={deleteText} placeholder='Enter' onChange={(e) => setDeleteText(e.target.value)} />
                                    </div>
                                    :
                                    showViewDeleteStatus === 'status' ?
                                        <p className={`${styles.message} mb-5`}>Are you sure want to change status</p>
                                        : showViewDeleteStatus === 'view' ?
                                            <div className='d-flex flex-column mb-4'>
                                                <p className={styles.message}>To confirm! <span className='text-danger'>send</span> this template to view.</p>
                                                <div className='d-flex justify-content-center align-items-center w-100 mx-auto'><span>Enter Mobile:</span><input type='number' value={mobileText} maxLength={10} className={styles.input_Mobile_number} placeholder='Enter Mobile No.' onChange={(e) => setMobileText(e.target.value)} onInput={(e) => e.target.value = e.target.value.slice(0, 10)} /></div>
                                            </div>
                                            : ''
                                }
                            </div>
                            <div className={styles.dealerNameModal_btn}>
                                <RedOutButton title="NO" handleSubmit={() => { setShowViewDeleteStatus(false) }} />
                                <GreenButton disabled={showViewDeleteStatus === 'delete' && (deleteText.toUpperCase() === 'DELETE' ? false : true)} title={showViewDeleteStatus === 'delete' ? (loadingBtn ? <Spinner size="15" /> : 'DELETE') : (loadingBtn ? <Spinner size="15" /> : "YES")} css='' handleSubmit={() => { if (showViewDeleteStatus === 'status') { handleChangeStatus() } else if (showViewDeleteStatus === 'delete') { handleConfirmDelete() } else if (showViewDeleteStatus === 'view') { handleViewTemplate() } }} />
                            </div>
                        </div>
                    }
                />

            </div>
        </React.Fragment>
    )
}

export default Templates
