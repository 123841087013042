// import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import styles from './SearchItem.module.css'
// import { AddToCart } from '../components/commons/Buttons'
import { useAuth } from '../../components/context/Auth'
import { SearchIcon } from '../../components/icons/Icon'
// import { Link } from 'react-router-dom'
const SearchItem = () => {

    const { setIsFocused, managerId } = useAuth()
    const [searchKey, setSearchKey] = useState("")
    const [data, setData] = useState([])

    // const ServicesRef = useRef(null);

    // const gotoServices = () =>
    //     window.scrollTo({
    //         top: ServicesRef.current.offsetTop,
    //         behavior: "smooth",
    //     });

    // useEffect(() => {
    //     if (!searchItem) {
    //         setSearchItemData([])
    //     }
    //     if(searchItem===''||searchItem===null||searchItem===undefined){
    //         setShowItemModal(false)
    //     }
    //     if (searchItem) {
    //         var myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "managerId": managerId,
    //             "keyword": searchItem,
              
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch(process.env.REACT_APP_URL + "order/searchitems?page=", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.status === "success") {
    //                     setShowItemModal(true)
    //                     setSearchItemData(result.data)
    //                 }
    //             })
    //             .catch(error => console.log('error', error));
    //     }
    // }, [searchItem, managerId])

    useEffect(() => {
        if (searchKey) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": searchKey,
                "managerId": managerId
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(process.env.REACT_APP_URL + "order/searchitems?page=", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [searchKey, managerId])

    useEffect(() => {
        const handleScroll = () => {
            const input = document.activeElement;
            if (input && ['INPUT', 'TEXTAREA'].includes(input.tagName)) {
                input.blur();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={styles.main_div}>
                <div  >
                    {/* {props.heading && <h2 className={styles.availability_heading}>{props.heading}</h2>} */}
                    <h2 className={styles.availability_heading}>Check Availability</h2>
                    <div className={styles.searchBox_main_div} >
                        {/* {props.sub_heading && <div className={styles.searchBox_text}> {props.sub_heading}</div>} */}
                        <div className={styles.searchBox_div} >
                            <input
                                className={styles.searchBox_input}
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                                type="text"
                                placeholder="Search Items"
                                // onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                            // onClick={gotoServices}
                            />
                            {/* <button className={styles.qr_image}><QRIcon /></button> */}
                            <button className={styles.search_icon}>
                                <SearchIcon />
                            </button>
                        </div>
                    </div>
                </div>
                {searchKey.length !== 0 ?
                    <div className={styles.search_item_main_div}>
                        {data && data.map((item, index) =>
                            <div className={(index !== 0 && styles.mt_1rem) + " " + styles.search_item_div} key={index}>
                                <div target={"_blank"} rel="noreferrer" className={styles.dc} >
                                    <div className={styles.stock_status_div}>
                                        {item.qty === 0 ?
                                            <div className={styles.stock_status + " " + styles.out_stock}>Out Of Stock</div>
                                            :
                                            <div className={styles.stock_status + " " + styles.In_stock}>In Stock</div>
                                        }
                                        <img src={process.env.REACT_APP_S3URL + item.thumbnail} className={styles.search_item_image} alt={item.itemNo} />
                                    </div>


                                    <div className={styles.search_item_availabel_div}>
                                        <div className={styles.itemTxt_div}>
                                            <span className={styles.item_txt}>Item No.</span>
                                            <span className={styles.item_txt_name}>{item.itemNo}</span>
                                        </div>
                                        <div className={styles.itemTxt_div}>
                                            <span className={styles.item_txt}>Product</span>
                                            <span className={styles.item_txt_name}>{item.product_name}</span>
                                        </div>
                                        <div className={styles.itemTxt_div}>
                                            <span className={styles.item_txt}>Qty</span>
                                            <span className={styles.item_txt_name}>{item.totalQty}</span>
                                        </div>
                                        <div className={styles.itemTxt_div}>
                                            <span className={styles.item_txt}> Price</span>
                                            <span className={styles.item_txt_name}>
                                                {item.price ? item.price : item.saleprice}/{item.unit}
                                            </span>
                                        </div>
                                    </div>



                                    {/* <div className={styles.search_item_availabel_Qty}>
                                        <div className={styles.item_No_txt}>Item No.</div>
                                        <div className={styles.item_No_txt}>Product</div>
                                        <div className={styles.item_No_txt}>Qty</div>
                                        <div className={styles.item_No_txt}>{props.priceName} Price</div>
                                    </div>
                                    <div className={styles.search_item_wallpaperNo}>
                                        <div className={styles.item_No_txt}>{item.itemNo}</div>
                                        <div className={styles.item_No_txt}>{item.product_name}</div>
                                        <div className={styles.item_No_txt}>{item.qty}</div>
                                        <div className={styles.item_No_txt}>{item.price ? item.price: item.saleprice}/{item.unit}</div>
                                    </div> */}
                                </div>
                                <div className={styles.cartBtn_div}>
                                    {/* <AddToCart itemNo={item.itemNo} qty={item.qty} /> */}
                                </div>
                            </div>
                        )}
                    </div>
                    : ""}
            </div>
        </React.Fragment>
    )
}

export default SearchItem