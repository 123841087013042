import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './context/Auth'

const VouchersTab = (props) => {

    const { styles } = props
    const { designation } = useAuth()
    const navigate = useNavigate()
    const location = useLocation().pathname
    // console.log('location', location);
    return (
        <div className={styles.dealer_header_select_days}>
            <div className={`${styles.dealer_days_select} ${location.includes('estimate') && styles.days_active}`}
                onClick={() => {
                    if (designation === "superadmin" || designation === "ordermanager" || designation === "inventorymanager")
                        navigate("/orders/estimate")
                }}
            >
                Estimate
            </div>
            <div className={`${styles.dealer_days_select} ${location.includes('saleorder') && styles.days_active}`}
                onClick={() => {
                    if (designation === "superadmin" || designation === "ordermanager" || designation === "inventorymanager")
                        navigate("/orders/saleorder")
                }}
            >
                Sale
            </div>
            <div className={`${styles.dealer_days_select} ${location.includes('purchase') && styles.days_active}`}
                onClick={() => {
                    if (designation === "superadmin" || designation === "inventorymanager")
                        navigate("/orders/purchase")
                }}
            >
                Purchase
            </div>
            <div className={`${styles.dealer_days_select} ${location.includes('adjustment') && styles.days_active}`}
                onClick={() => {
                    if (designation === "superadmin" || designation === "inventorymanager")
                        navigate("/orders/adjustment")
                }}
            >
                Adjustment
            </div>
            <div className={`${styles.dealer_days_select} ${location.includes('transfer') && styles.days_active}`}
                onClick={() => {
                    if (designation === "superadmin" || designation === "inventorymanager")
                        navigate("/orders/transfer")
                }}
            >
                Transfer
            </div>
        </div>
    )
}

export default VouchersTab