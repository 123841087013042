import React, { useEffect, useState } from 'react'
import styles from "./Invoices.module.css"
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import { Spinner } from '../../components/icons/Icon'
import { useAuth } from '../../components/context/Auth'

const SaleInvoice = () => {
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const [searchparams] = useSearchParams()
    const orderId = searchparams.get("orderId")
    const [data, setData] = useState({})
    const [reciptsInfo, setReciptsInfo] = useState([])
    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState([]);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles(droppedFiles);
    };

    useEffect(() => {
        if (orderId && managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "invoice/getInvoice", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data) {
                            setData(result.data)
                            setReciptsInfo(result.data.receipts)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [orderId, managerId])

    const filterItem = (data.order && data.order.items && data.order.items.length > 0) && data.order.items.filter(item => (item.unit || item.qty > 0 || item.desc))

    useEffect(() => {
        if (files && files.length !== 0 && orderId && managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var formdata = new FormData();
            formdata.append("pdf", files[0]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "invoice/uploadInvoice?managerId=" + managerId + "&orderId=" + orderId, requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert(result.msg)
                })
                .catch(error => console.log('error', error));
        }
    }, [files, orderId, managerId])


    return (
        <React.Fragment>
            <div
                className={dragging ? styles.invoice_dragDrop : styles.invoice_right_main}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            //   className={dragging?'draganddrop_active':'draganddrop'}
            >
                <div className='text-center'>
                    <h1 className={styles.Invoices_Heading}>Sale Invoice</h1>
                </div>

                <div className={styles.scroll_main_div}>
                    <div className='d-flex justify-content-between'>

                        <div className='w-50 me-5 pe-5'>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Dealer’s Name</label>
                                <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.name}</div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Billing Address</label>
                                <textarea
                                    type="text"
                                    rows="3"
                                    className='border rounded bg-transparent text-light w-75 ms-5 px-2 py-2'
                                    value={data.order && data.order.billingAddress}
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Shipping Address</label>
                                <textarea
                                    type="text"
                                    rows="3"
                                    className='border rounded bg-transparent text-light w-75 ms-5 px-2 py-2'
                                    value={data.order && (data.order.shippingInfo.street + ', ' + data.order.shippingInfo.district + ', ' + data.order.shippingInfo.city + ', ' + data.order.shippingInfo.state + ', INDIA, ' + data.order.shippingInfo.pincode)}
                                />
                            </div>
                        </div>







                        <div className='w-50 ms-5 ps-5'>
                            <div className='d-flex justify-content-between align-items-center mt-2 pt-2'>
                                <div className='w-50 d-flex justify-content-between me-5'>
                                    <label>Voucher No.</label>
                                    <b>{data.order && data.order.orderId}</b>
                                </div>
                                <div className='w-50 d-flex justify-content-between ms-5'>
                                    <label>Voucher Date</label>
                                    <b>{data.order && data.order.orderDate}</b>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <label className='w-25'>Warehouse</label>
                                <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.warehousename}</div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Order Manager</label>
                                <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.ordermanager}</div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Delivery Method</label>
                                <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.shippingInfo.courierName}</div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                <label className='w-25'>Delivery Partners</label>
                                <div className='border rounded w-75 ms-5 px-2 py-2'>{data.order && data.order.shippingInfo.methodName}</div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.fixTableHead_packing}>
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Item Description</th>
                                    <th>Qty</th>
                                    <th>Rate</th>
                                    <th>Per</th>
                                    <th className='text-center'>Amount</th>
                                </tr>
                            </thead>
                            {filterItem && filterItem.map((val, key) => {
                                return (
                                    <tbody key={key}>
                                        <tr className={styles.h10}>
                                            <td>{key + 1}</td>
                                            <td>
                                                <div className={styles.itemDescription_div}>
                                                    <span>{val.itemNo ? val.itemNo : val.accessoriesName}</span>
                                                    <span>{val.product_name}</span>
                                                    <span>{val.warehouse}</span>
                                                    <span>{val.batch}</span>
                                                </div>
                                            </td>
                                            <td>{val.qty}</td>
                                            <td>{val.price}</td>
                                            <td>{val.unit ? val.unit : 'Pcs'}</td>
                                            <td className='text-end'>{val.qty * val.price}</td>
                                        </tr>
                                        {(key === val.descKey || val.desc) && <tr style={{ border: '1px solid' }}>
                                            <td colSpan={6} className={styles.itemDescription_div}>
                                                {val.desc}
                                            </td>
                                        </tr>
                                        }

                                        {/* <tr className={styles.brderTr}>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr> */}
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>

                    <div className={styles.d_flex}>
                        <div className={styles.addRecipt_div}>

                            {/* <div className={styles.addRecipt_btn_div}>
                            {payStatus &&
                                <button
                                    type='button'
                                    className={styles.addRecipt_btn}
                                    onClick={() => {
                                        if (!orderId) {
                                            alert("Please Create Voucher Before")
                                        } else {
                                            setShowPaymentModal(true)
                                            setReciptData(prevState => ({
                                                ...prevState,
                                                paymentMethod: 'Cash',
                                                paymentType: '',
                                                paymentAmount: '',
                                                transactionId: '',
                                                accountNumber: '',
                                                transactionScreenshot: '',
                                                receiptId: '',
                                            }))
                                        }
                                    }}
                                >
                                    Add Receipts
                                </button>
                            }
                        </div> */}

                            <div className={styles.receipt_scroll}>
                                {
                                    reciptsInfo && reciptsInfo.map((item, index) =>
                                        <div key={index} className={styles.payment_detailsDiv} onClick={() => {
                                            // setReciptData(item); setShowPaymentModal(true)
                                        }}>
                                            <span>Receipt Id : <b>{item.receiptId}</b></span>
                                            <span>Method : <b>{item.paymentMethod}</b></span>
                                            <span>Amount : <b>{item.paymentAmount}</b></span>
                                            <span
                                                className={styles.recipt_statusWidth}>
                                                Status :
                                                <b role='button'
                                                    className={item.status.includes('Pending') ? styles.recipt_status_pending :
                                                        item.status === 'Cancelled' ? styles.recipt_status_red :
                                                            styles.recipt_status_green}>
                                                    <u>{item.status}</u>
                                                </b>
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className={styles.subTotal_flex_col}>
                            <div className={styles.subTotal_div}>
                                <span>Sub Total</span>
                                <span>{data.order && data.order.subtotal}</span>
                            </div>
                            <div className={styles.subTotal_div}>
                                <span>Shipping Charges</span>
                                <div className='w-50 d-flex justify-content-between align-items-center'>
                                    <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        className={styles.shipingCharges_input}
                                        value={data.order && data.order.shippingInfo.shippingCharge}
                                        // value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
                                        // onChange={(e) => setGeneralInfo(prevState => ({
                                        //     ...prevState,
                                        //     shippingCharge: e.target.value
                                        // }))}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}

                                    />
                                    <span className='d-flex justify-content-between align-items-center'>
                                        To Pay&nbsp;&nbsp;
                                        <input
                                            type='checkbox'
                                            className={styles.toPay_checkbox}
                                            // value={generalInfo.toPay}
                                            // onChange={(e) => setGeneralInfo(prevState => ({
                                            //     ...prevState,
                                            //     toPay: e.target.checked
                                            // }))}
                                            checked={data.order && data.order.toPay && data.order.toPay === true}
                                            disabled
                                        />
                                    </span>

                                </div>
                                <span className={styles.fixSize_input}>{data.order && data.order.shippingInfo.shippingCharge}</span>
                                {/* <span className={styles.fixSize_input}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span> */}
                            </div>
                            <div className={styles.subTotal_div}>
                                <input
                                    type="text"
                                    className={styles.adjustment}
                                    value={data.order && data.order.manageName}
                                    placeholder="Enter Manage"
                                    disabled
                                // onChange={(e) => setGeneralInfo(prevState => ({
                                //     ...prevState,
                                //     manageName: e.target.value,
                                // }))}
                                // required={generalInfo.manageValue ? true : false}
                                />
                                <div className='w-50'>
                                    <input
                                        type="number"
                                        className={styles.shipingCharges_input}
                                        value={data.order && data.order.manageValue ? data.order.manageValue : 0}
                                        name='manageValue'
                                        // onChange={(e) => {
                                        //     setGeneralInfo(prevState => ({
                                        //         ...prevState,
                                        //         manageValue: e.target.value
                                        //     }))
                                        // }}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        // required={generalInfo.manageName ? true : false}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <span className={styles.subTotal_txtRed}>
                                    {data.order && data.order.manageValue ? data.order.manageValue : 0}
                                </span>
                            </div>

                            {/* <div className={styles.subTotal_div}>
                            <span>Round Off</span>
                            <span className={styles.fixSize_input}>
                                {roundedAmt}</span>
                        </div> */}

                            <div className={styles.totalINR_div}>
                                <span>Total (INR)</span>
                                <span>{data.order && data.order.totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                            </div>
                        </div>

                    </div>

                    <div className={styles.orders_btn_div}>
                        <RedOutButton
                            btnType="button"
                            title='BACK'
                            handleSubmit={() => navigate(-1)}
                        />
                        <GreenOutButton
                            btnType="button"
                            title="VIEW ORDER"
                            handleSubmit={() => { navigate('/orders/saleorder?orderId=' + orderId) }}
                        />
                        <label className={styles.uploadData_btn}>
                            UPLOAD INVOICE
                            <input className='d-none' type="file" onChange={(e) => setFiles(e.target.files)} />
                        </label>
                        <GreenOutButton
                            btnType="button"
                            title="VIEW INVOICE"
                            handleSubmit={() => { navigate('/invoices/uploadedinvoice?orderId=' + orderId) }}
                        />
                        <GreenOutButton
                            btnType="button"
                            title=" UPLOAD DATA"
                            handleSubmit={() => { }}
                        />
                        <GreenButton
                            btnType="button"
                            title='DOWNLOAD DATA'
                            handleSubmit={() => { }}
                        />
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default SaleInvoice