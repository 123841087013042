import React from 'react'

const ProgressCircle = (props) => {
    const percent = props.percent || 10

    React.useEffect(() => {
        var totalProgress, progress;
        const circles = document.querySelectorAll('.progresscustom');
        for (var i = 0; i < circles.length; i++) {
            totalProgress = circles[i].querySelector('circle').getAttribute('strokeDasharray');
            progress = circles[i].parentElement.getAttribute('data-percent');
            circles[i].querySelector('.bar').style['strokeDashoffset'] = totalProgress * progress / 100;
        }
    }, [props.percent])
    return (
        <React.Fragment>
            <div className="progressdiv" data-percent={percent} data-symbol={" %"}>
                <svg className="progresscustom" width="178" height="178" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle r="80" cx="89" cy="89" fill="transparent" strokeDasharray="502.4" strokeDashoffset="0" ></circle>
                    <circle className="bar" r="80" cx="89" cy="89" fill="transparent" strokeDasharray="502.4" strokeDashoffset="0"></circle>
                </svg>
            </div>
        </React.Fragment>
    )
}

export default ProgressCircle