// import React from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
// import {  HomeIcon, MarketingIcon } from '../components/icons/Icon'

// const MarketingMenu = () => {
//     const navigate = useNavigate()
//     const { pathname } = useLocation();
//     return (
//         <React.Fragment>
//             <div className='main_container'>
//                 <div className='content'>
//                     <div className='leftMenu'>
//                         <div className='menuItem_div' onClick={() => navigate("/")}>
//                             <HomeIcon css='iconImage' color={pathname === "/" ? "#24ABF8" : "#ffffff"} />
//                             <span className={pathname === "/" ? 'active_leftMenu' : ""}>DASHBOARD</span>
//                         </div>

//                         <div className='menuItem_div' onClick={() => navigate("/marketing")} >
//                             <MarketingIcon css='iconImage' color={pathname === "/marketing" ? "#24ABF8" : "#ffffff"} />
//                             <span className={pathname === "/marketing" ? 'active_leftMenu' : ""}>MARKETING</span>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default MarketingMenu

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, MarketingIcon } from '../components/icons/Icon'
import styles from './Menus.module.css'
const MarketingMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <React.Fragment>
            <div className={styles.menuItem_div} onClick={() => navigate("/dashboard")}>
                <HomeIcon css={styles.iconImage} color={pathname === "/dashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dashboard" ? styles.active_leftMenu : ""}>DASHBOARD</span>
            </div>

            {/* <div className={styles.menuItem_div} onClick={() => navigate("/marketing")} >
                <MarketingIcon css={styles.iconImage} color={pathname === "/marketing" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/marketing" ? styles.active_leftMenu : ""}>MARKETING</span>
            </div>
         */}
            <div className={styles.menuItem_div} onClick={() => navigate("/marketing/contacts")} >
                <MarketingIcon css={styles.iconImage} color={pathname === "/marketing/contacts" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/marketing/contacts" ? styles.active_leftMenu : ""}>CONTACTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/marketing/leads")} >
                <MarketingIcon css={styles.iconImage} color={pathname === "/marketing/leads" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/marketing/leads" ? styles.active_leftMenu : ""}>LEADS</span>
            </div>
        </React.Fragment>
    )
}

export default MarketingMenu