import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { DashBoard, InventoryIcon, Order } from '../components/icons/Icon'
import styles from './Menus.module.css'
const InventoryMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <React.Fragment>
            <div className={styles.menuItem_div} onClick={() => navigate("/dashboard")}>
                <DashBoard css={styles.iconImage} color={pathname === "/dashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dashboard" ? styles.active_leftMenu : ""}>DASHBOARD</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/inventory")}>
                <InventoryIcon css={styles.iconImage} color={pathname === "/inventory" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/inventory" ? styles.active_leftMenu : ""}>INVENTORY</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/outsideinventory")}>
                <InventoryIcon css={styles.iconImage} color={pathname === "/outsideinventory" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/outsideinventory" ? styles.active_leftMenu : ""}>OUTSIDE INVENTORY</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/orders")}>
                <Order css={styles.iconImage} color={pathname === "/orders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/orders" ? styles.active_leftMenu : ""}>ORDERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/adjustmentorders")}>
                <Order css={styles.iconImage} color={pathname === "/adjustmentorders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/adjustmentorders" ? styles.active_leftMenu : ""}>ADJUSTMENT</span>
            </div>
            <div className={styles.menuItem_div} onClick={() => navigate("/purchaseorders")}>
                <Order css={styles.iconImage} color={pathname === "/purchaseorders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/purchaseorders" ? styles.active_leftMenu : ""}>PURCHASE ORDER</span>
            </div>
        </React.Fragment>
    )
}

export default InventoryMenu