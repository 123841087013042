import React, { useEffect, useState } from 'react'
import styles from './Warehouse.module.css'
import { DeleteIcon, Edit, EyeIcon } from '../../components/icons/Icon'
import Modal from '../../components/Modal'
import { GreenButton, RedOutButton } from '../../components/Buttons'

const Warehouse = () => {
  const [show, setShow] = useState(false);
  const [deleteModal, setDeletModal] = useState(false);
  const [viewEdit, setViewEdit] = useState(0);
  const [warehouseData, setWarehouseData] = useState([]);
  const [dataWare, setDataWare] = useState({
    warehousename: '',
    address: '',
    pincode: '',
    tempmsg: '',
    status: '',
  })
  const [fields, setFields] = useState([
    { workday: "Monday", workstatus: "" },
    { workday: "Tuesday", workstatus: "" },
    { workday: "Wednesday", workstatus: "" },
    { workday: "Thursday", workstatus: "" },
    { workday: "Friday", workstatus: "" },
    { workday: "Saturday", workstatus: "" },
    { workday: "Sunday", workstatus: "" }
  ]);

  function handleChange(i, event) {
    const values = [...fields];
    values[i]["workstatus"] = event.target.value;
    setFields(values);
  }
  const [tempClosed, setTempClosed] = useState([]);

  const postWarehouse = () => {
    if (dataWare.warehousename === '' || dataWare.address === '' || dataWare.pincode === '' || dataWare.status === '' || fields.workstatus === null) {
      alert('feilds cant be empty')
    }
    else {
      fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
        },
        body: JSON.stringify({
          'warehousename': dataWare.warehousename,
          'address': dataWare.address,
          'pincode': dataWare.pincode,
          'tempclosed': tempClosed,
          'tempmsg': dataWare.tempmsg,
          'workingdays': fields,
          'status': dataWare.status
        }),
      })
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setDataWare('');
            setTempClosed('');
            getAllWarehouse()
            setShow(false)
          }
        })
        .catch(error => console.log(error))
    }
  };

  const getAllWarehouse = async () => {
    await fetch(process.env.REACT_APP_URL + "warehouses/getAllWarehouse", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      }
    })
      .then(response => response.json())
      .then(result => setWarehouseData(result.data))
  }

  useEffect(() => {
    getAllWarehouse()
  }, [])

  const getWareHouse = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "warehouseId": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "warehouses/getWarehouse", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          setDataWare(result.data)
          setTempClosed(result.data.tempclosed)
          setFields(result.data.workingdays)
          setShow(true)
        }
      })
      .catch(error => console.log('error', error));
  }

  const updateWareHouse = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "warehouseId": id,
      'warehousename': dataWare.warehousename,
      'address': dataWare.address,
      'pincode': dataWare.pincode,
      'tempclosed': tempClosed,
      'tempmsg': dataWare.tempmsg,
      'workingdays': fields,
      'status': dataWare.status
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          getAllWarehouse()
          setShow(false)
          alert('updated successfully')
          setDataWare({})
          setTempClosed([])
          setFields([
            { workday: "Monday", workstatus: "" },
            { workday: "Tuesday", workstatus: "" },
            { workday: "Wednesday", workstatus: "" },
            { workday: "Thursday", workstatus: "" },
            { workday: "Friday", workstatus: "" },
            { workday: "Saturday", workstatus: "" },
            { workday: "Sunday", workstatus: "" }
          ])
          setViewEdit(0)
        }
      })
      .catch(error => console.log('error', error));
  }

  const deleteWareHouse = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "warehouseId": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "warehouses/deleteWarehouse", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          getAllWarehouse()
          alert('deleted successfully')
          setShow(false);
          setDeletModal(false)
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  // console.log('show', show);

  return (
    <React.Fragment>
      <div className={styles.main_div}>

        <div className='d-flex justify-content-between w-100'>
          <h1 className={styles.heading}>Warehouse</h1>
          <div className={styles.addwarehouse_btn} onClick={() => {
            setShow(true);
            setViewEdit(2);
            setDataWare({});
            setTempClosed([]);
            setFields([
              { workday: "Monday", workstatus: "" },
              { workday: "Tuesday", workstatus: "" },
              { workday: "Wednesday", workstatus: "" },
              { workday: "Thursday", workstatus: "" },
              { workday: "Friday", workstatus: "" },
              { workday: "Saturday", workstatus: "" },
              { workday: "Sunday", workstatus: "" }
            ]);
          }} >+ ADD WAREHOUSE</div>
        </div>

        <div>
          {warehouseData && warehouseData.map((item, index) =>
            <div key={index} className={styles.Warehouse_card}
              onClick={() => { setViewEdit(0); getWareHouse(item._id) }}
            >
              <div className='ms-4'>
                <span className='d-flex justify-content-start align-items-center'>
                  <input disabled={item.status === 'disable' ? true : false} type="radio" name="" className={styles.Warehouse_card_checkbox} defaultChecked={item.status === "active" ? true : false} />&nbsp;&nbsp;Active
                </span>
                <h2 className={styles.warehouse_name}>{item.warehousename}</h2>
              </div>
              <div className='d-flex flex-row'>

                <div className={styles.ViewEdit_div} onClick={() => { setViewEdit(0); getWareHouse(item._id) }} >
                  <EyeIcon color='#fff' />
                  <span><u>View</u></span>
                </div>
                <div className={styles.ViewEdit_div} onClick={(e) => { e.stopPropagation(); setViewEdit(1); getWareHouse(item._id) }}>
                  <Edit color='#fff' />
                  <span><u>Edit</u></span>
                </div>
                {/* <div className={styles.ViewEdit_div} onClick={(e) => { e.stopPropagation(); setDeletModal(true) }}>
                  <DeleteIcon />
                  <span><u>Delete</u></span>
                </div> */}
              </div>
            </div>
          )}
        </div>

      </div>

      <Modal
        show={show}
        close={setShow}
        heading=''
        closeBtn=''
        content={
          <form>
            <div className='d-flex justify-content-between align-items-center'>
              <h2>{viewEdit === 1 ? 'Update Warehouse': viewEdit === 0? dataWare.warehousename:'Add Warehouse'}</h2>
              {dataWare._id && viewEdit === 1 ?
                <h6 className="text-end mouse text-danger d-flex flex-column justify-content-center align-items-center border p-1 rounded" onClick={() => setDeletModal(true)}><DeleteIcon />
                  <span className={styles.delete_btn}>Delete</span></h6> : null}
            </div>
            <div className={styles.modal_div}>
              <div className='w-50'>
                <span>Name</span>
                <div>
                  <input type='text' value={dataWare.warehousename}
                    onChange={(e) => setDataWare({ ...dataWare, warehousename: e.target.value })} />
                </div>
                <span>Street Address</span>
                <div>
                  <textarea rows="2" type='text' value={dataWare.address}
                    onChange={(e) => setDataWare({ ...dataWare, address: e.target.value })} />
                </div>
                <span>Pin Code</span>
                <div>
                  <input type='number' value={dataWare.pincode}
                    onChange={(e) => setDataWare({ ...dataWare, pincode: e.target.value })} />
                </div>
                <span>Temporary Closed</span>
                <div className='d-flex justify-content-center align-items-center'>
                  <input
                    type='date'
                    className={'text-light'}
                    name="fromdate"
                    value={tempClosed.fromdate}
                    onChange={(e) => setTempClosed({ ...tempClosed, fromdate: e.target.value })} />&nbsp;&nbsp;To&nbsp;&nbsp;
                  <input
                    type='date'
                    name="fromdate"
                    className='text-light'
                    value={tempClosed.tilldate}
                    onChange={(e) => setTempClosed({ ...tempClosed, tilldate: e.target.value })} />
                </div>
                <span>Temporary Message</span>
                <div>
                  <textarea rows="2" type='text' value={dataWare.tempmsg}
                    onChange={(e) => setDataWare({ ...dataWare, tempmsg: e.target.value })} />
                </div>
              </div>
              <div className='w-50 ms-4'>
                <div className='mb-2'>Work Days :</div>
                {fields && fields.map((field, idx) =>
                  <div key={`${field}-${idx}`} className='d-flex justify-content-between'>
                    <div className=''>{field.workday}</div>
                    <div className={styles.status_radio}>
                      <div className={styles.workDays}>
                        <input
                          className={styles.days_radio}
                          type='radio'
                          label="Active"
                          name={idx}
                          value="active"
                          onChange={(e) => handleChange(idx, e)}
                          checked={field.workstatus === "active" ? true : false}
                        />
                        Active
                      </div>
                      <div className={styles.workDays}>
                        <input
                          className={styles.days_radio}
                          type='radio'
                          name={idx}
                          label="Disable"
                          value="disable"
                          onChange={(e) => handleChange(idx, e)}
                          checked={field.workstatus === "disable" ? true : false}
                        />
                        Disable
                      </div>
                    </div>
                  </div>
                )}

                <div className='d-flex justify-content-between mt-2'>
                  <div className='fw-600 text-danger'>Status</div>
                  <div className={styles.status_radio}>
                    <div className={styles.workDays}>
                      <input
                        className={styles.days_radio}
                        type="radio"
                        label="Active"
                        name="formHorizontalRadios"
                        value="active"
                        onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
                        checked={dataWare.status === "active" ? true : false}
                      />
                      Active
                    </div>
                    <div className={styles.workDays}>
                      <input
                        className={styles.days_radio}
                        type="radio"
                        label="Disable"
                        name="formHorizontalRadios"
                        value="disable"
                        onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
                        checked={dataWare.status === "disable" ? true : false}
                      />
                      Disable
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={viewEdit === 1 ? 'd-flex justify-content-between w-75 mx-auto mt-4' : 'd-flex justify-content-center w-75 mx-auto mt-4'}>
              <RedOutButton title="CANCEL" handleSubmit={() => {
                setShow(false);
                setDataWare({});
                setTempClosed([]);
                setFields([
                  { workday: "Monday", workstatus: "" },
                  { workday: "Tuesday", workstatus: "" },
                  { workday: "Wednesday", workstatus: "" },
                  { workday: "Thursday", workstatus: "" },
                  { workday: "Friday", workstatus: "" },
                  { workday: "Saturday", workstatus: "" },
                  { workday: "Sunday", workstatus: "" }
                ]);
                setViewEdit(1);
              }} />
              {viewEdit === 0 ? "" :
                <GreenButton btnType='button' title={viewEdit === 1 ? "UPDATE" : "CREATE"} css="ms-5  " handleSubmit={() => viewEdit === 1 ? updateWareHouse(dataWare._id) : postWarehouse()} />
              }
            </div>
          </form>
        }
      />

      <Modal
        show={deleteModal}
        close={setDeletModal}
        heading=''
        closeBtn=''
        content={
          <form>
            <h2>Delete</h2>
            <div className={styles.modal_div}>
              <h3 className='my-2'>Are you sure you want to delete this warehouse ?</h3>
            </div>
            <div className='d-flex justify-content-between w-75 mx-auto mt-4'>
              <RedOutButton title="CANCEL" handleSubmit={() => { setDeletModal(false); }} />
              <GreenButton btnType='button' title="DELETE" css="ms-5 bg-transparent mb-0 text-success" handleSubmit={() => deleteWareHouse(dataWare._id)} />
            </div>
          </form>
        }
      />
    </React.Fragment>
  )
}
export default Warehouse